import React, {useEffect, useState} from "react";
import {useLocation, useNavigate, useSearchParams} from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import {useDispatch, useSelector} from "react-redux";
import {PayPalScriptProvider} from "@paypal/react-paypal-js";
import {getUserDefaultAddress} from "../../store/checkout";
import {PaypalComponent} from "../GuestCheckout/GuestCheckout";
import {
    get_meta, getAddressDetails,
    getCountry,
    getHeaderData,
    getStatesList,
    getUserAddresses,
    homeActions,
    manageAddressField,
    saveUserAddress, updateAddressDetails, updateAddressFor,
} from "../../store/home";
import SiteLoader from "../../SiteLoader";
import {
    getCartData,
    getPayPalCredentials,
    getStripeCredentials,
    placeOrderAfterPayPalPayment,
    placeOrderAfterStripePayment,
} from "../../store/newCart";
import {Circles} from "react-loader-spinner";
import {toast} from "react-toastify";
import {Helmet} from "react-helmet";
import {Button, Modal} from "react-bootstrap";
import ThemeBreadcrumb from "../../components/common/ThemeBreadcrumb";
import StripePaymentContainer from "../../components/StripePaymentContainer";
import Separator from "../../components/Seprator";
import {handleFinalizeOrder} from "../../lib/stripe-helpers";
import GeneralModal from "../../components/modal/GeneralModal";
import {BsPencil, BsPlus} from "react-icons/bs";
import {mgtApi} from "../../store/axios";

const Checkout = () => {
    const {pathname} = useLocation();
    useEffect(() => {
        window.scrollTo({top: 0});
    }, [pathname]);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const myCartLists = useSelector((state) => state.checkoutp?.checkout_data);

    // Const Variables
    let userDetails = localStorage.getItem("userDetails");
    let data = JSON.parse(userDetails);
    let name = data?.full_name;
    let phone = data?.mobile;
    let mail = data?.email;
    let tokenId = "";

    // meta information
    const mainstate = useSelector((state) => state.home);
    const metaTags = mainstate.allmeta;
    const siteUrl = window.location.href;
    useEffect(() => {
        dispatch(get_meta("checkout"));
    }, ["checkout"]);

    // UseSelectors
    const cart = useSelector((state) => state.newCart.cart);
    // const myCartLists = useSelector((state) => state.newCart.cartData);
    const myAddressLists = useSelector(
        (state) => state.checkout.checkout_address_data
    );

    const shippingId = myAddressLists?.shipping_address?.id;

    // console.log(myAddressLists);
    const isLoading = useSelector((state) => state.checkout.loaderStatus);
    const addressList = useSelector((state) => state.home.addressList);
    // const addStatus = useSelector((state) => state.checkout.add_status);
    const userAddress = useSelector((state) => state.home.user_Address);
    const countries = useSelector((state) => state.home.Country);
    const states_list = useSelector((state) => state.home.states_list);
    const state = useSelector((state) => state.checkout);
    const stripeCredentials = useSelector(
        (state) => state.newCart.stripeCredentials
    );
    const paypalCredentials = useSelector(
        (state) => state.newCart.paypalCredentials
    );

    // UseStates
    const [showSelectShippingModal, setShowSelectShippingModal] = useState(false);
    const [showAddAddressModal, setShowAddAddressModal] = useState(false);
    const [shippingAddress, setShippingAddress] = useState({});
    // const [selectedValue, setSelectedValue] = useState(
    //   myAddressLists?.shipping_address?.id
    // );
    const [errors, setErrors] = useState(false);
    const [showStates, setShowStates] = useState(false);
    const [loading, setLoading] = useState(true);
    const [expressIds, setExpressIds] = useState("");
    const [giftIds, setGiftIds] = useState("");
    const checkoutAmount = cart?.total * 100;

    //handling stripe redirections
    const [searchParams, setSearchParams] = useSearchParams();
    const paymentIntent = searchParams.get('payment_intent');
    const shippingIdParams = searchParams.get('shippingId')
    const status = searchParams.get('redirect_status');
    const [transactionLoading, setTransactionLoading] = useState(false);
    const location = useLocation().pathname;
    const [show, setShow] = useState(false);

    const [isShipping, setIsShipping] = useState({id: null, value: false})
    const [isBilling, setIsBilling] = useState({id: null, value: false})

    // Methods
    const onToken = async (token) => {
        if (token) {
            tokenId = token.id;

            let isSuccess = await dispatch(
                await placeOrderAfterStripePayment({
                    token: tokenId,
                    shipping_address: Number(shippingAddress.id),
                    // express_products: expressIds,
                    // gift_products: giftIds,
                })
            );

            dispatch(getHeaderData({user_id: userDetails?.ID}));

            if (isSuccess) {
                navigate("/thank-you");
            } else {
                navigate("/checkout");
            }
        }
    };

    // Select shipping modal
    const onOpenSelectShippingModal = async () => {
        const addressList = await dispatch(getUserAddresses());
        const activeAddress = addressList.data.address.filter(address=>address.is_shipping)[0];
        localStorage.setItem("defaultAddress", JSON.stringify(activeAddress.id));
        setShowSelectShippingModal(true);
    };
    const onCloseSelectShippingModal = () => {
        setShowSelectShippingModal(false);
    };
    // Add address modal
    const onOpenAddAddressModal = () => {
        setShowAddAddressModal(true);
    };
    const onCloseAddAddressModal = () => {
        setShowAddAddressModal(false);
    };
    const handleChange = async (event) => {
        await dispatch(updateAddressFor(Number(event.target.value), "shipping", false))
        await dispatch(getCartData());
        setShippingAddress(
            addressList.find((address) => address.id === Number(event.target.value))
        );
        setShowSelectShippingModal(false);
    };
    const createOrder = (data, actions) => {
        return actions.order.create({
            purchase_units: [
                {
                    amount: {
                        currency_code: "USD", // Change this to the appropriate currency code if needed
                        value: cart.total, // Change this to the appropriate amount for the order
                    },
                },
            ],
        });
    };
    const onApprove = async (data, actions) => {
        try {
            const response = await actions.order.capture();
            if (response) {
                let isSuccess = await dispatch(
                    await placeOrderAfterPayPalPayment({
                        shipping_address: Number(shippingAddress.id),
                        response_data: response,
                        express_products: expressIds,
                        gift_products: giftIds,
                    })
                );
                dispatch(updateAddressFor(localStorage.getItem('defaultAddress'), "shipping", false))
                localStorage.removeItem("defaultAddress")
                if (isSuccess) {
                    navigate("/thank-you");
                } else {
                    navigate("/checkout");
                }
            }
            return response;
        } catch (e) {
        }
    };
    // Add New Address function
    const [newAddressLoading, setNewAddressLoading] = useState(false)
    const handleSubmit = async (e) => {
        e.preventDefault();
        setNewAddressLoading(true)
        if (editAddress && validateInputs(userAddress)) {
            if (isBilling.value) await dispatch(updateAddressFor(isBilling.id, "billing", false));
            if (isShipping.value) await dispatch(updateAddressFor(isShipping.id, "shipping", false));
            await dispatch(updateAddressDetails(userAddress)).then();
            await dispatch(getUserDefaultAddress())
            await dispatch(getUserAddresses());
            setShowModal(false);
            setEditAddress(false);
        } else if (validateInputs(userAddress)) {
            await dispatch(saveUserAddress(userAddress));
            await dispatch(getUserDefaultAddress())
            setShowModal(false);
            setShowAddAddressModal(false)
            toast("New Address added successfully!");
        }
        setNewAddressLoading(false)
    };

    const onChangeInput = (e) => {
        const {name, value} = e.target;
        if (name === "country" && ["United States", "Canada"].includes(value)) {
            const selectElement = e.target;
            const selectedIndex = selectElement.selectedIndex;
            const selectedOption = selectElement.options[selectedIndex];
            if (selectedOption) {
                const id = selectedOption.getAttribute("data-id");
                dispatch(getStatesList(id));
            }

            setShowStates(true);
        } else if (name === "country") {
            setShowStates(false);
        }

        let input = {field: name, value: value};
        dispatch(manageAddressField(input));
    };
    const validateInputs = (values, key) => {
        const errors = {};
        // const regex =
        //   /^(|(([A-Za-z0-9]+_+)|([A-Za-z0-9]+\-+)|([A-Za-z0-9]+\.+)|([A-Za-z0-9]+\++))*[A-Za-z0-9]+@((\w+\-+)|(\w+\.))*\w{1,63}\.[a-zA-Z]{2,6})$/i;

        if (!values.name) {
            errors.name = "Your Name is required!";
        }

        // if (!values.email) {
        //   errors.email = "Email is required!";
        // } else if (!regex.test(values.email)) {
        //   errors.email = "Please enter the correct format of the email.";
        // }

        if (!values.phone) {
            errors.phone = "Phone Number is required!";
        }

        if (!values.street_address) {
            errors.street_address = "Street Address is required!";
        }

        if (!values.city) {
            errors.city = "City is required!";
        }

        if (!values.state) {
            errors.state = "State is required!";
        }

        // if (!values.postcode) {
        //     errors.postcode = "Postcode is required!";
        // }

        if (!values.country) {
            errors.country = "Country is required!";
        }
        // if (key === "new") {
        //   if (!values.address_for) {
        //     errors.address_for = "Address Type is required!";
        //   }
        // }
        setErrors(errors);
        return Object.values(errors).length <= 0;
    };

    useEffect(() => {
        dispatch(getCartData());
        dispatch(getStripeCredentials());
        dispatch(getPayPalCredentials());
        dispatch(getCountry());

        if (userDetails) {
            dispatch(getUserDefaultAddress());
            dispatch(getUserAddresses());
        }
    }, []);


    useEffect(() => {
        if (addressList && addressList.length) {
            let shippingAddress = addressList.find(
                (address) => address.is_shipping === 1
            );
            if (shippingAddress) setShippingAddress(shippingAddress);
            else setShippingAddress({});
        }
    }, [addressList]);

    const handleGiftAndExpress = async () => {
        let expressIds = "";
        let giftIds = "";
        for (let i = 0; i < cart?.data?.length; i++) {
            const store = cart?.data[i];
            if (store?.is_express) {
                const ids = await store?.cart_items
                    ?.map((product) => product?.product_id.toString())
                    .join(",");
                if (expressIds === "") {
                    expressIds = ids;
                } else {
                    expressIds += "," + ids;
                }
            }
            if (store?.is_gift) {
                const ids = await store?.cart_items
                    ?.map((product) => product?.product_id.toString())
                    .join(",");
                if (giftIds === "") {
                    giftIds = ids;
                } else {
                    giftIds += "," + ids;
                }
            }
        }
        setExpressIds(expressIds);
        setGiftIds(giftIds);
        setLoading(false);
    };

    useEffect(() => {
        if (cart && !status) {
            cart.status === 'nok' && navigate('/cart')
        }
        setLoading(true);
        handleGiftAndExpress();
    }, [cart, pathname]);

    //handling stripe redirections

    async function placeOrderWrapper() {
        if (status === 'succeeded') {
            setTransactionLoading(true)
            try {
                await handleFinalizeOrder(paymentIntent, shippingIdParams, location);

                localStorage.removeItem('publishable_key')
                localStorage.removeItem('identifier')
                localStorage.removeItem('checkout_amount')
                localStorage.removeItem('client_secret')
                localStorage.removeItem('guestUserAddress')

                await dispatch(getHeaderData());
                await dispatch(getCartData(false))

                setTransactionLoading(false)
                navigate('/thank-you')
            } catch (error) {
                setShow(true)
            }

        } else if (status === 'failed') {
            setSearchParams('');
            setShow(true)
        }
    }

    useEffect(() => {
        if (paymentIntent && status) placeOrderWrapper().then()
    }, [paymentIntent, status]);

    const [editAddress, setEditAddress] = useState(false);
    const [showModal, setShowModal] = useState(false);

    const handleModalClose = () => {
        setEditAddress(false);
        setShowModal(false);
    };

    const handleChangeUpdate = (e) => {
        const {name, value} = e.target;
        if (name === "country" && ["United States", "Canada"].includes(value)) {
            const selectElement = e.target;
            const selectedIndex = selectElement.selectedIndex;
            const selectedOption = selectElement.options[selectedIndex];
            if (selectedOption) {
                const id = selectedOption.getAttribute("data-id");
                dispatch(getStatesList(id));
            }

            setShowStates(true);
        } else if (name === "country") {
            setShowStates(false);
        }

        let input = {field: name, value: value};
        dispatch(manageAddressField(input));
    };

    const onAddressEdit = (address) => {
        setShowModal(true);
        setEditAddress(true);
        dispatch(getAddressDetails(address)).then();
    };

    const handleAddAddressModal = () => {
        dispatch(getAddressDetails({})).then();
        setEditAddress(false);
        setShowModal(true);
    };

    useEffect(() => {
        if (userAddress) {
            setIsShipping({id: userAddress.id, value: !!userAddress.is_shipping})
            setIsBilling({id: userAddress.id, value: !!userAddress.is_billing})
        }
    }, [userAddress]);

    return (
        <>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{metaTags?.meta_data?.meta_title}</title>
                <meta
                    name="description"
                    content={metaTags?.meta_data?.meta_description}
                />
                <meta name="keywords" content={metaTags?.meta_data?.meta_keywords}/>

                <meta name="twitter:card" content="summary_large_image"/>
                <meta name="twitter:url" content={siteUrl}/>
                <meta name="twitter:title" content={metaTags?.meta_data?.og_title}/>
                <meta
                    name="twitter:description"
                    content={metaTags?.meta_data?.og_description}
                />
                <meta name="twitter:image" content={metaTags?.meta_data?.og_image}/>

                <meta property="og:type" content={metaTags?.meta_data?.og_type}/>
                <meta property="og:url" content={siteUrl}/>
                <meta property="og:title" content={metaTags?.meta_data?.og_title}/>
                <meta
                    property="og:description"
                    content={metaTags?.meta_data?.og_description}
                />
                <meta property="og:image" content={metaTags?.meta_data?.og_image}/>
            </Helmet>
            {(isLoading || transactionLoading) ? (
                <SiteLoader status={isLoading || transactionLoading}/>
            ) : (
                <main>
                    <GeneralModal show={show} setShow={setShow} title='Payment Failed'
                                  message='Your Amazon payment was not successful.'/>
                    <ThemeBreadcrumb title="Checkout" current_route="Checkout"/>

                    <section className="container mx-auto" style={{marginTop: "40px"}}>
                        <div className="row">
                            {/* <!-- personal details  --> */}
                            <div className="col-12 col-md-7 col-lg-8">
                                <div className="cart-billing">
                                    {/* <!-- personal details  --> */}
                                    <div className="cart-bill-card">
                                        <div className="bill-between">
                                            <h4>
                                                Personal Details
                                                <i className="ri-checkbox-circle-fill text-warning"></i>
                                            </h4>
                                            <div className="flex-content d-block mt-3">
                                                <div className="fl-item mb-2">
                                                    <input
                                                        type="text"
                                                        name="name"
                                                        className="form-control"
                                                        defaultValue={name}
                                                    />
                                                </div>
                                                <div className="fl-item mb-2">
                                                    <input
                                                        type="number"
                                                        name="phone"
                                                        className="form-control"
                                                        defaultValue={phone}
                                                    />
                                                </div>
                                                <div className="fl-item mb-2">
                                                    <input
                                                        type="email"
                                                        name="email"
                                                        className="form-control"
                                                        defaultValue={mail}
                                                        style={{width: "100%"}}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        {/* <!-- Payment Method  --> */}
                                        {/* <div className="cart-bill-card align-items-start d-none hide">
                      <div className="bill-between">
                        <h4>Payment Method </h4>
                        <div className="form-check">
                          <div className="check-head">
                            <i className="ri-bank-card-2-line"></i> Debit Card
                          </div>
                        </div>
                      </div>
                      <div className="bill-between">
                        <a href=" " className="btn btn-light btn-md">
                          Change
                        </a>
                      </div>
                    </div> */}
                                    </div>

                                    {myAddressLists?.billing_address &&
                                    myAddressLists?.shipping_address ? (
                                        <>
                                            {/*Billing Address*/}
                                            <div className="cart-bill-card">
                                                {myAddressLists?.billing_address && (
                                                    <div className="bill-between">
                                                        <h4>
                                                            Billing Address{" "}
                                                            <i className="ri-checkbox-circle-fill text-warning"></i>
                                                        </h4>
                                                        <div className="flex-content">
                                                            <h5 className="card-title mt-2">
                                                                {myAddressLists?.billing_address?.name}
                                                            </h5>
                                                            <h6 className="card-subtitle my-2 text-muted">
                                                                {`${myAddressLists?.billing_address?.street_address}, ${myAddressLists?.billing_address?.city}, ${myAddressLists?.billing_address?.state},  ${myAddressLists?.billing_address?.country}`}
                                                            </h6>
                                                            <p className="card-text">{`Zip: ${myAddressLists?.billing_address?.postcode}, Phone # ${myAddressLists?.billing_address?.phone}`}</p>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>

                                            {/*Shipping Address*/}
                                            <div className="cart-bill-card">
                                                {/*{myAddressLists?.shipping_address &&*/}
                                                {shippingAddress && (
                                                    <div style={{width: "100% "}}>
                                                        <div
                                                            className="d-flex justify-content-between flex-wrap"
                                                            style={{width: "100% "}}
                                                        >
                                                            <h4>
                                                                Shipping Address
                                                                <i className="ri-checkbox-circle-fill text-warning"></i>
                                                            </h4>
                                                            <button
                                                                onClick={onOpenSelectShippingModal}
                                                                className="theme-btn px-3 py-2"
                                                            >
                                                                Change
                                                            </button>
                                                        </div>
                                                        {myAddressLists.billing_address.id === myAddressLists.shipping_address.id ?
                                                            <span>Same as billing address</span> :
                                                            <div className="flex-content">
                                                                <h5 className="card-title mt-2">
                                                                    {shippingAddress?.name}
                                                                </h5>
                                                                <p className="card-text">{}</p>
                                                                <h6 className="card-subtitle my-2 text-muted">
                                                                    {`${shippingAddress?.street_address}, ${shippingAddress?.city}, ${shippingAddress?.state},  ${shippingAddress?.country}`}
                                                                </h6>
                                                                <p className="card-text">{`Zip: ${shippingAddress?.postcode}, Phone # ${shippingAddress?.phone}`}</p>
                                                            </div>}
                                                    </div>
                                                )}
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            {/*Add New Address*/}
                                            <div className="cart-bill-card">
                                                <div style={{width: "100% "}}>
                                                    <div
                                                        className="d-flex justify-content-between"
                                                        style={{width: "100% "}}
                                                    >
                                                        <h4>Add Address</h4>
                                                        <Button
                                                            onClick={onOpenAddAddressModal}
                                                            className="btn btn-warning"
                                                        >
                                                            Add Address
                                                        </Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>

                            {/* <!-- order  --> */}
                            <div className="col-12 col-md-5 col-lg-4 position-relative">
                                {cart ? (
                                    <>
                                        <div
                                            className={`order-wrap border-line ${
                                                addressList?.length === 0 && "cursor-none"
                                            }`}
                                        >
                                            <h3
                                                className="heading-3"
                                                style={{marginBottom: "10px"}}
                                            >
                                                Your Order
                                            </h3>
                                            <hr
                                                style={{borderColor: "#bdbdbd", margin: "25px 0"}}
                                            />
                                            <div className="flex-item">
                                                <div className="flex-bill">
                                                    <div className="flex-list">
                                                        <div>Quantity</div>
                                                        <div className="bold">
                                                            {cart.cart_count ? cart.cart_count : 0}
                                                        </div>
                                                    </div>
                                                    <div className="flex-list">
                                                        <div>Subtotal</div>
                                                        <div className="bold">
                                                            ${cart.subtotal ? cart.subtotal : 0}
                                                        </div>
                                                    </div>
                                                    <div className="flex-list">
                                                        <div>Discount</div>
                                                        <div className="bold">
                                                            ${cart.discount ? cart.discount : 0}
                                                        </div>
                                                    </div>
                                                    <div className="flex-list">
                                                        <div>Shipping & Processing</div>
                                                        <div className="bold">
                                                            ${cart.shipping_charge ? cart.shipping_charge : 0}
                                                        </div>
                                                    </div>
                                                    <div className="flex-list">
                                                        <div>Express Charges</div>
                                                        <div className="bold">
                                                            ${cart.express_charge ? cart.express_charge : 0}
                                                        </div>
                                                    </div>
                                                    <div className="flex-list">
                                                        <div>Gift Charges</div>
                                                        <div className="bold">
                                                            ${cart.gift_charge ? cart.gift_charge : 0}
                                                        </div>
                                                    </div>
                                                    <div className="flex-list">
                                                        <div>Tax</div>
                                                        <div className="bold">
                                                            ${cart.tax_amount ? cart.tax_amount : 0}
                                                        </div>
                                                    </div>
                                                    {/*<div className="flex-list">*/}
                                                    {/*    <div>Delivery Charges</div>*/}
                                                    {/*    <div*/}
                                                    {/*        className="bold">${cart.delivery_charge ? cart.delivery_charge : 0}</div>*/}
                                                    {/*</div>*/}
                                                    <hr/>
                                                    <div className="flex-list">
                                                        <div>Total</div>
                                                        <div className="bold">
                                                            ${cart.total ? cart.total : 0}
                                                        </div>
                                                    </div>
                                                </div>

                                                {addressList?.length > 0 ? (
                                                    <>
                                                        {loading ? (
                                                            <div className="mx-auto d-flex justify-content-center">
                                                                <Circles
                                                                    type="Circles"
                                                                    color="#f6a92c"
                                                                    height={30}
                                                                    width={30}
                                                                />
                                                            </div>
                                                        ) : (
                                                            <div>
                                                                {Object.keys(paypalCredentials).length > 0 && (
                                                                    <PayPalScriptProvider
                                                                        options={{
                                                                            clientId: paypalCredentials?.wobble_key
                                                                                ? paypalCredentials?.wobble_key
                                                                                : process.env.REACT_APP_PAYPAL_ID,
                                                                        }}
                                                                    >
                                                                        <PaypalComponent order={(data, actions) =>
                                                                            createOrder(data, actions)}
                                                                                         onApprove={(data, actions) =>
                                                                                             onApprove(data, actions)}
                                                                                         onError={(err) => console.log(err)}/>
                                                                    </PayPalScriptProvider>
                                                                )}

                                                                <div className='mt-2'>
                                                                    <Separator text='Or, Pay with card / Wallet'/>
                                                                    <StripePaymentContainer
                                                                        shippingId={shippingId}
                                                                        checkoutAmount={checkoutAmount}
                                                                        expressIds={expressIds}
                                                                        giftIds={giftIds}
                                                                    />
                                                                </div>
                                                            </div>
                                                        )}
                                                    </>
                                                ) : (
                                                    <div className="checkout_blur_container">
                                                        <div className="address_not_added_for_order_container">
                                                            <div className="warning_part">
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    fill="none"
                                                                    viewBox="0 0 24 24"
                                                                    stroke-width="2"
                                                                    stroke="currentColor"
                                                                    data-slot="icon"
                                                                >
                                                                    <path
                                                                        stroke-linecap="round"
                                                                        stroke-linejoin="round"
                                                                        d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126ZM12 15.75h.007v.008H12v-.008Z"
                                                                    />
                                                                </svg>

                                                                <h2>Address Required for Order</h2>
                                                            </div>
                                                            <p>
                                                                To complete your order, please make sure to add
                                                                at least one valid address. Having a delivery
                                                                address on file is essential for processing and
                                                                delivering your order accurately. If you haven't
                                                                added an address yet, please add a new address.
                                                            </p>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div className="d-flex justify-content-center accordian">
                                            <Circles
                                                type="Circles"
                                                color="#f6a92c"
                                                height={80}
                                                width={80}
                                            />
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </section>
                    {/* <!-- clients  --> */}

                    {/* Modal */}
                    <Modal
                        show={showAddAddressModal}
                        onHide={onCloseAddAddressModal}
                        size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>
                                <h5 className="modal-title" id="addAddressLabel">
                                    Add Address
                                </h5>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12 login-wrap">
                                        <form onSubmit={handleSubmit}>
                                            <div className="row">
                                                <div className="col-md-6 form-group mb-3">
                                                    <label htmlFor="name">Name</label>
                                                    <input
                                                        type="text"
                                                        name="name"
                                                        maxLength={50}
                                                        onChange={onChangeInput}
                                                        className="form-control"
                                                        placeholder="Your Full Name"
                                                        defaultValue={userAddress.name}
                                                    />
                                                    <p className="register_error">{errors.name}</p>
                                                </div>

                                                <div className="col-md-6 form-group mb-3">
                                                    <label htmlFor="street_address">Street Address</label>
                                                    <input
                                                        type="text"
                                                        name="street_address"
                                                        onChange={onChangeInput}
                                                        className="form-control"
                                                        placeholder="Address"
                                                        defaultValue={userAddress.street_address}
                                                    />
                                                    <p className="register_error">
                                                        {errors.street_address}
                                                    </p>
                                                </div>

                                                <div className="col-md-6 form-group mb-3">
                                                    <label htmlFor="country">Country</label>
                                                    <select
                                                        name="country"
                                                        id="country"
                                                        onChange={onChangeInput}
                                                        className="form-control"
                                                        defaultValue={userAddress.country}
                                                    >
                                                        <option value="">Choose Your Country</option>
                                                        {countries.map((country) => (
                                                            <option
                                                                key={country.id}
                                                                data-id={country.id}
                                                                value={country.country_name}
                                                            >
                                                                {country.country_name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    <p className="register_error">{errors.country}</p>
                                                </div>

                                                <div className="col-md-6 form-group mb-3">
                                                    <label htmlFor="state">State</label>
                                                    {showStates ? (
                                                        <select
                                                            name="state"
                                                            id="state"
                                                            onChange={onChangeInput}
                                                            className="form-control"
                                                            defaultValue={userAddress.state}
                                                        >
                                                            <option value="">Choose Your State</option>
                                                            {states_list.length > 0 &&
                                                                states_list.map((state) => (
                                                                    <option value={state.state_name}>
                                                                        {state.state_name}
                                                                    </option>
                                                                ))}
                                                        </select>
                                                    ) : (
                                                        <input
                                                            type="state"
                                                            name="state"
                                                            onChange={onChangeInput}
                                                            className="form-control"
                                                            placeholder="State"
                                                            defaultValue={userAddress.state}
                                                        />
                                                    )}

                                                    <p className="register_error">{errors.state}</p>
                                                </div>

                                                <div className="col-md-6 form-group mb-3">
                                                    <label htmlFor="city">City</label>
                                                    <input
                                                        type="text"
                                                        name="city"
                                                        onChange={onChangeInput}
                                                        className="form-control"
                                                        placeholder="Your City Name"
                                                        defaultValue={userAddress.city}
                                                    />
                                                    <p className="register_error">{errors.city}</p>
                                                </div>

                                                <div className="col-md-6 form-group mb-3">
                                                    <label htmlFor="postcode">Zipcode</label>
                                                    <input
                                                        type="text"
                                                        name="postcode"
                                                        onChange={onChangeInput}
                                                        className="form-control"
                                                        placeholder="Pincode"
                                                        defaultValue={userAddress.postcode}
                                                    />
                                                    <p className="register_error">{errors.postcode}</p>
                                                </div>

                                                <div className="col-md-6 form-group mb-3">
                                                    <label htmlFor="phone">Phone Number</label>
                                                    <input
                                                        type="text"
                                                        name="phone"
                                                        onChange={onChangeInput}
                                                        className="form-control"
                                                        placeholder="Phone Number/Mobile Number"
                                                        defaultValue={userAddress.phone}
                                                    />
                                                    <p className="register_error">{errors.phone}</p>
                                                </div>

                                                <div className="col-md-12 center-btn">
                                                    <button
                                                        type="submit"
                                                        disabled={state.loaderStatus}
                                                        className="btn btn-sm btn-warning w-50"
                                                    >
                                                        Add Address
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>

                    {/* modal start */}
                    <Modal
                        show={showSelectShippingModal}
                        onHide={onCloseSelectShippingModal}
                        size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>
                                <h5 className="modal-title" id="addAddressLabel">
                                    Select an address for shipping:
                                </h5>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="containerr row">
                                <div className="col-lg-12">
                                    {newAddressLoading ? <div className="mx-auto d-flex justify-content-center">
                                        <Circles
                                            type="Circles"
                                            color="#f6a92c"
                                            height={30}
                                            width={30}
                                        />
                                    </div> : <ul className="address-ul row">
                                        {addressList?.map((addresslist, key) => {
                                            return (
                                                <li key={key}
                                                    className='col-lg-6'>
                                                    <div>
                                                        <div>
                                                            <input
                                                                type="radio"
                                                                id={addresslist.id}
                                                                name="address"
                                                                className="form-check-input"
                                                                defaultValue={addresslist.id}
                                                                defaultChecked={
                                                                    shippingAddress?.id === addresslist.id
                                                                }
                                                                onChange={handleChange}
                                                            />
                                                            <label
                                                                htmlFor={addresslist.id}
                                                                style={{color: "#ffc107"}}
                                                            >
                                                                <strong>{addresslist.name}</strong>&nbsp;
                                                            </label>
                                                            <p>
                                                                {addresslist.street_address}
                                                                {addresslist.street_address2}, {addresslist.city},
                                                                {addresslist.state}, {addresslist.country}-
                                                                {addresslist.postcode}
                                                            </p>
                                                        </div>
                                                        <button className='default-button'
                                                                onClick={() => onAddressEdit(addresslist)}>
                                                            <BsPencil style={{fontSize: '18px'}}/>
                                                        </button>
                                                    </div>
                                                </li>
                                            );
                                        })}
                                    </ul>}
                                    <button className='default-button float-end' onClick={handleAddAddressModal}>
                                        <BsPlus/>
                                        Add new address
                                    </button>
                                </div>
                                <div className="col-md-12 center-btn">
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>
                    {/* modal end */}

                    <Modal
                        show={showModal}
                        onHide={handleModalClose}
                        size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>
                                <h5
                                    className="modal-title"
                                    id="addAddressLabel"
                                >
                                    {editAddress ? "Update" : "Add"} Address
                                </h5>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12 login-wrap">
                                        <form onSubmit={handleSubmit}>
                                            <div className="row">
                                                <div
                                                    className="col-md-6 form-group mb-3">
                                                    <label htmlFor="name">Name</label>
                                                    <input
                                                        type="text"
                                                        name="name"
                                                        maxLength={50}
                                                        onChange={handleChangeUpdate}
                                                        className="form-control"
                                                        placeholder="Your Full Name"
                                                        defaultValue={userAddress.name}
                                                    />
                                                    <p className="register_error">
                                                        {errors.name}
                                                    </p>
                                                </div>

                                                <div
                                                    className="col-md-6 form-group mb-3">
                                                    <label htmlFor="street_address">
                                                        Street Address
                                                    </label>
                                                    <input
                                                        type="text"
                                                        name="street_address"
                                                        onChange={handleChangeUpdate}
                                                        className="form-control"
                                                        placeholder="Address"
                                                        defaultValue={
                                                            userAddress.street_address
                                                        }
                                                    />
                                                    <p className="register_error">
                                                        {errors.street_address}
                                                    </p>
                                                </div>

                                                <div
                                                    className="col-md-6 form-group mb-3">
                                                    <label htmlFor="country">
                                                        Country
                                                    </label>
                                                    <select
                                                        name="country"
                                                        id="country"
                                                        onChange={handleChangeUpdate}
                                                        className="form-control"
                                                        defaultValue={userAddress.country}
                                                    >
                                                        <option value="">
                                                            Choose Your Country
                                                        </option>
                                                        {countries.map((country) => (
                                                            <option
                                                                key={country.id}
                                                                data-id={country.id}
                                                                value={country.country_name}
                                                            >
                                                                {country.country_name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    <p className="register_error">
                                                        {errors.country}
                                                    </p>
                                                </div>

                                                <div
                                                    className="col-md-6 form-group mb-3">
                                                    <label htmlFor="city">City</label>
                                                    <input
                                                        type="text"
                                                        name="city"
                                                        onChange={handleChangeUpdate}
                                                        className="form-control"
                                                        placeholder="Your City Name"
                                                        defaultValue={userAddress.city}
                                                    />
                                                    <p className="register_error">
                                                        {errors.city}
                                                    </p>
                                                </div>

                                                <div
                                                    className="col-md-6 form-group mb-3">
                                                    <label htmlFor="state">State</label>
                                                    {showStates ? (
                                                        <select
                                                            name="state"
                                                            id="state"
                                                            onChange={handleChangeUpdate}
                                                            className="form-control"
                                                            defaultValue={userAddress.state}
                                                        >
                                                            <option value="">
                                                                Choose Your State
                                                            </option>
                                                            {states_list.length > 0 &&
                                                                states_list.map((state) => (
                                                                    <option
                                                                        value={state.state_name}
                                                                    >
                                                                        {state.state_name}
                                                                    </option>
                                                                ))}
                                                        </select>
                                                    ) : (
                                                        <input
                                                            type="state"
                                                            name="state"
                                                            onChange={handleChangeUpdate}
                                                            className="form-control"
                                                            placeholder="State"
                                                            defaultValue={userAddress.state}
                                                        />
                                                    )}

                                                    <p className="register_error">
                                                        {errors.state}
                                                    </p>
                                                </div>

                                                <div
                                                    className="col-md-6 form-group mb-3">
                                                    <label htmlFor="postcode">
                                                        Zipcode
                                                    </label>
                                                    <input
                                                        type="text"
                                                        name="postcode"
                                                        onChange={handleChangeUpdate}
                                                        className="form-control"
                                                        placeholder="Pincode"
                                                        defaultValue={userAddress.postcode}
                                                    />
                                                    <p className="register_error">
                                                        {errors.postcode}
                                                    </p>
                                                </div>

                                                <div
                                                    className="col-md-6 form-group mb-3">
                                                    <label htmlFor="phone">
                                                        Phone Number
                                                    </label>
                                                    <input
                                                        type="text"
                                                        name="phone"
                                                        onChange={handleChangeUpdate}
                                                        className="form-control"
                                                        placeholder="Phone Number/Mobile Number"
                                                        defaultValue={userAddress.phone}
                                                    />
                                                    <p className="register_error">
                                                        {errors.phone}
                                                    </p>
                                                </div>
                                                {editAddress && <div className="col-md-6 form-group mb-3">
                                                    <label htmlFor="phone">
                                                        Default Address (Optional)
                                                    </label>
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox"
                                                               checked={isBilling.value}
                                                               onChange={e => setIsBilling({
                                                                   id: userAddress.id,
                                                                   value: e.target.checked
                                                               })}
                                                               value="billing"
                                                               id="flexCheckDefault"/>
                                                        <label className="form-check-label" htmlFor="flexCheckDefault">
                                                            Default billing address
                                                        </label>
                                                    </div>
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox"
                                                               checked={isShipping.value}
                                                               onChange={e => setIsShipping({
                                                                   id: userAddress.id,
                                                                   value: e.target.checked
                                                               })}
                                                               value="shipping"
                                                               id="flexCheckChecked"/>
                                                        <label className="form-check-label" htmlFor="flexCheckChecked">
                                                            Default shipping address
                                                        </label>
                                                    </div>
                                                </div>}
                                                <div className="col-md-12 center-btn">
                                                    <button
                                                        type="submit"
                                                        disabled={state.loaderStatus}
                                                        className="theme-btn w-50"
                                                        style={{
                                                            padding: "12px 10px",
                                                        }}
                                                    >
                                                        {newAddressLoading
                                                            ? "Loading..."
                                                            : "Add Address"}
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>
                </main>
            )}
        </>
    );
};

export default Checkout;
