import React, {useEffect, useRef, useState} from "react";
import "./reviewStyles.css";
import {Rating} from "react-simple-star-rating";
import {Button, Modal, Spinner} from "react-bootstrap";
import {Controller, useForm} from "react-hook-form";
import {mgtApi} from "../../store/axios";
import {toast} from "react-toastify";

const AddReview = ({open, onHide, refetch}) => {
    const {
        handleSubmit,
        register,
        control,
        reset,
        setValue,
        watch,
        formState: {errors},
    } = useForm();
    const [loading, setLoading] = useState(false);
    const [images, setImages] = useState([]);

    const imageRef = useRef();
    const handleImages = (files) => {
        if (files?.length > 5) {
            toast.warning("You can Select Max 5 Images");
            imageRef.current.value = null;

        } else {
            if (images?.length > 4) {
                toast.warning("You can Select Max 5 Images");
            } else {
                if (images?.length + files?.length > 5) {
                    toast.warning(`Already ${images?.length} images selected`);
                    imageRef.current.value = null;

                } else {
                    setImages([...images, ...files]);
                }
            }
        }
    };

    const handelReview = async (data) => {
        setLoading(true);
        const reviewData = {
            ...data,
            order_id: open?.order_id,
            order_item_id: open?.id,
            product_id: open?.product_id,
        };

        const newReview = new FormData();
        if (images && images?.length > 0) {
            images.forEach((item, index) => {
                newReview.append(`photos`, item, item.name);
            });
        }

        // console.log([...newReview.entries()].forEach(i => console.log(i)))

        for (const key in reviewData) {
            newReview.append(key, reviewData[key]);
        }

        try {
            const response = await mgtApi.post("/user/review", newReview);
            // console.log(response);
            if (response?.data?.status === "success") {
                toast.success(response?.data?.msg);
                setLoading(false);
                setImages([]);
                reset();
                refetch();
                onHide(null);
            } else if (response?.data?.status === "fail") {
                toast.error("Something went wrong");
            }
            setLoading(false);
        } catch (error) {
            // console.log(error);
            setLoading(false);
        }
    };

    useEffect(() => {
        if (open?.rating_status !== "no") {
            setValue("review", open?.rating?.review);
            setValue("rating", parseInt(open?.rating?.rating));
        }
    }, [open?.rating]);

    return (
        <Modal
            show={open ? true : false}
            onHide={() => {
                onHide(null);
                setImages([]);
            }}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <form
                onSubmit={handleSubmit(handelReview)}
                className="add-review-container mx-auto p-2 p-md-5"
            >
                <h2 className="review-label">Review</h2>
                <hr style={{margin: "10px 0", border: "2px solid #D7D7D7"}}/>

                <div className="pt-2 pt-md-5">
                    <h3 className="review-product-label">Product</h3>
                    <p className="review-product-p">{open?.pro_name}</p>
                </div>
                <div className="pt-2 pt-md-5">
                    <h3 className="review-product-label">
                        Rating
                        {errors.rating && (
                            <small className="errorpass" style={{fontSize: "12px"}}>
                                (Rating is Required)
                            </small>
                        )}
                    </h3>
                    <Controller
                        name="rating"
                        control={control}
                        rules={{required: "Rating is required", min: 1, max: 5}}
                        render={({field}) => (
                            <Rating
                                ratingValue={watch("rating")}
                                initialValue={watch("rating")}
                                size={25}
                                readonly={open?.rating_status === "no" ? false : true}
                                onClick={(value) => setValue("rating", value)}
                            />
                        )}
                    />
                </div>
                <div className="pt-2 pt-md-5">
                    <h3 className="review-product-label">Comment</h3>
                    <textarea
                        {...register("review", {required: true})}
                        className="comment-input-field w-100 p-2 review-product-p"
                        placeholder="Write your review here..."
                        name="review"
                    />
                </div>

                {/* Review Images */}

                <div className="pt-2 pt-md-5 review-images-container">
                    <h3 className="review-product-label">Review Images</h3>
                    {open?.rating_status === "no" ? (
                        <>
                            <div className="position-relative image-input d-flex justify-content-between">
                                <div className="d-flex justify-content-center align-items-center bg-theme browse-btn">
                                    <span className="browse-text">Browse</span>
                                </div>
                                <div
                                    className="d-flex justify-content-start align-items-center flex-grow-1 border-top border-end border-bottom">
                  <span className="review-product-p ps-2">
                    Select up to 5 Images
                  </span>
                                </div>
                                <input
                                    ref={imageRef}
                                    onChange={(e) => handleImages(e.target.files)}
                                    type="file"
                                    multiple
                                    accept=".png, .jpg, .jpeg, .png"
                                    className="position-absolute w-100 h-100 pointer"
                                    style={{opacity: "0"}}
                                    max="5"
                                />
                            </div>

                            <div className="images-container d-flex flex-wrap gap-2 mt-3 pointer">
                                {images?.length > 0 &&
                                    images?.map((img, index) => (
                                        <div key={index} className="review-img-container">
                                            <img
                                                className="w-100"
                                                src={URL.createObjectURL(img)}
                                                alt=""
                                            />
                                            <div className="p-2">
                                                <h1>
                                                    {img?.name?.length > 12
                                                        ? img?.name.slice(0, 12) + "..."
                                                        : img?.name}
                                                </h1>
                                                <p className="review-product-p">
                                                    {(img?.size / 1024).toFixed(2)}kb
                                                </p>
                                            </div>
                                        </div>
                                    ))}
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="images-container d-flex flex-wrap gap-2 mt-3 pointer">
                                {Array.isArray(open?.rating?.review_image) &&
                                    open?.rating?.review_image?.map((img, index) => (
                                        <div key={index} className="review-img-container">
                                            <img className="w-100" src={img} alt=""/>
                                        </div>
                                    ))}
                            </div>
                        </>
                    )}
                </div>

                <p className="review-img-input-instructions">
                    These images are visible in product review page gallery.
                </p>

                <div className="review-actions-btn mt-5 d-flex justify-content-end align-items-center gap-4">
                    <div
                        onClick={() => {
                            onHide(null);
                            setImages([]);
                        }}
                        className="px-4 py-3 rounded review-cancel-btn pointer"
                    >
                        Cancel
                    </div>
                    {open?.rating_status === "no" && (
                        <>
                            {loading ? (
                                <Button
                                    variant="primary"
                                    className="px-4 py-3 rounded review-submit-btn border-0 bg-theme"
                                    disabled
                                >
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                    <span className="visually-hidden">Loading...</span>
                                </Button>
                            ) : (
                                <button
                                    type="submit"
                                    className="px-4 py-3 rounded review-submit-btn border-0 bg-theme"
                                >
                                    Submit Review
                                </button>
                            )}
                        </>
                    )}
                </div>
            </form>
        </Modal>
    );
};

export default AddReview;
