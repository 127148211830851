import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {get_meta, getPrivacypolicy} from "../store/home";
import SiteLoader from "../SiteLoader";

import {Helmet} from "react-helmet";
import ThemeBreadcrumb from "../components/common/ThemeBreadcrumb";

const TermsCondition = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getPrivacypolicy());
    }, []);
    const termscondition = useSelector((state) => state.home.tmc);
    const state = useSelector((state) => state.home);

    // META TAGS START
    useEffect(() => {
        dispatch(get_meta("terms-and-condition"));
    }, ["terms-and-condition"]);
    const metaTags = state.allmeta;

    const siteUrl = window.location.href;
    // META TAGS END

    return (
        <div>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{metaTags?.meta_data?.meta_title}</title>
                <meta
                    name="description"
                    content={metaTags?.meta_data?.meta_description}
                />
                <meta name="keywords" content={metaTags?.meta_data?.meta_keywords}/>
                <meta name="twitter:card" content="summary_large_image"/>
                <meta name="twitter:url" content={siteUrl}/>
                <meta name="twitter:title" content={metaTags?.meta_data?.og_title}/>
                <meta
                    name="twitter:description"
                    content={metaTags?.meta_data?.og_description}
                />
                <meta name="twitter:image" content={metaTags?.meta_data?.og_image}/>

                <meta property="og:type" content={metaTags?.meta_data?.og_type}/>
                <meta property="og:url" content={siteUrl}/>
                <meta property="og:title" content={metaTags?.meta_data?.og_title}/>
                <meta
                    property="og:description"
                    content={metaTags?.meta_data?.og_description}
                />
                <meta property="og:image" content={metaTags?.meta_data?.og_image}/>
            </Helmet>
            <SiteLoader status={state.loaderStatus}/>
            <ThemeBreadcrumb
                title={termscondition[0]?.heading}
                current_route="Terms and Conditions"
            />
            <section className="single-wrapper section-wrapper mt-4">
                <div className="container">
                    <div className="terms-width">
                        {termscondition?.map((t) => (
                                <div className="content" dangerouslySetInnerHTML={{__html: t.content}} />
                        ))}
                    </div>
                </div>
            </section>
        </div>
    );
};

export default TermsCondition;
