import {Outlet, useLocation, useNavigate} from "react-router-dom";
import {useEffect} from "react";
import {isRedirect} from "../../lib/services/services";

export const openRoutes = ['/login', '/register', '/reset-password', '/authentication', '/forgot-password'];

export default function OpenRoutesLayout() {
    const pathname = useLocation().pathname;
    const navigate = useNavigate();
    const userExists = !!localStorage.getItem('userDetails');
    useEffect(() => {
        const {previous_path} = isRedirect(pathname);
        if (userExists) {
            if (openRoutes.includes(previous_path)) navigate('/')
            else navigate(-1)
        }
    }, [pathname]);
    return (
        <div>
            <Outlet/>
        </div>
    )
}