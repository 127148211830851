import moment from "moment/moment";
import React from "react";
import { Link } from "react-router-dom";

const RelatedBlogs = ({ blogs }) => {
  // console.log(blogs);
  return (
    <div className="blog-category-container bg-white rounded p-2 mt-5">
      <h3 className="text-theme-blue font-theme">Related Blogs</h3>
      <div>
        {blogs?.map((item, index) => (
          <div
            key={index}
            className="related-blog-card d-flex gap-2 px-1 font-theme mt-2"
          >
            <img src={item?.coverimage} alt="" />
            <div>
              <Link to={`/blog/${item?.slug}`} className="twoLine">
                {item?.title?.length > 25
                  ? item?.title.slice(0, 25) + "..."
                  : item?.title}
              </Link>
              <p>{moment(item?.date * 1000).format("MMM DD YYYY")}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default RelatedBlogs;
