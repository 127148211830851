import React from "react";
import { ProgressBar } from "react-bootstrap";
import { Rating } from "react-simple-star-rating";
import img from "../../assets/img/popup-image.png";
import UserReviewCard from "./UserReviewCard";

const ReviewContent = ({ reviews, rating }) => {
  // console.log(reviews, rating);
  return (
    <div className="mt-2 mt-md-4">
      <h4 className="review-title">Reviews</h4>
      <div className="row mt-4">
        <div className="col-md-5 d-flex flex-column flex-sm-row justify-content-between align-items-center gap-5">
          <div className="review-count-container">
            <h2 className="review-count">{rating?.average_rating}</h2>
            <Rating readonly size={25} initialValue={rating?.average_rating} />
            <p className="total-reviews">{rating?.total_rating} Reviews</p>
          </div>

          {/* <div className="w-100">
            <div className="d-flex align-items-center gap-2 w-100">
              <div
                className="review-range-label"
                style={{ width: "fit-content" }}
              >
                5
              </div>
              <ProgressBar className="w-100" variant="warning" now={60} />
            </div>
            <div className="d-flex align-items-center gap-2 w-100">
              <div
                className="review-range-label"
                style={{ width: "fit-content" }}
              >
                4
              </div>
              <ProgressBar className="w-100" variant="warning" now={50} />
            </div>
            <div className="d-flex align-items-center gap-2 w-100">
              <div
                className="review-range-label"
                style={{ width: "fit-content" }}
              >
                3
              </div>
              <ProgressBar className="w-100" variant="warning" now={20} />
            </div>
            <div className="d-flex align-items-center gap-2 w-100">
              <div
                className="review-range-label"
                style={{ width: "fit-content" }}
              >
                2
              </div>
              <ProgressBar className="w-100" variant="warning" now={45} />
            </div>
            <div className="d-flex align-items-center gap-2 w-100">
              <div
                className="review-range-label"
                style={{ width: "fit-content" }}
              >
                1
              </div>
              <ProgressBar className="w-100" variant="warning" now={100} />
            </div>
          </div> */}
        </div>
        {/* <div className="col-md-7 mt-4 mt-md-0">
          <h4 className="filter-review-title">Filter Reviews</h4>
          <div className="d-flex align-items-center flex-wrap gap-4">
            <button className="btn bg-theme filter-review-btn-text-active text-white rounded">
              With Images
            </button>
            <button className="btn bg-theme-gray filter-review-btn-text rounded">
              5 Stars
            </button>
            <button className="btn bg-theme-gray filter-review-btn-text rounded">
              Newest
            </button>
            <button className="btn bg-theme-gray filter-review-btn-text rounded">
              Without Image
            </button>
          </div>
        </div> */}
      </div>

      {/* section 2 */}

      <section>
        {reviews?.length > 0 && (
          <>
            {reviews?.map((review, index) => (
              <UserReviewCard key={index} review={review} />
            ))}
          </>
        )}
      </section>
    </div>
  );
};

export default ReviewContent;
