export default function Page500() {
    return (
        <div className='error-container error-500'>
            <div className='error-body'>
                <h1>Internal server error</h1>
                <p>It's always time for a coffee break.<br/>
                    We should be back by the time you finish your coffee.</p>

                <a href="/">Back to home</a>
            </div>
        </div>
    )
}