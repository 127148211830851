import {Outlet, useLocation, useNavigate} from "react-router-dom";
import {useEffect} from "react";

export default function ProtectedLayout() {
    const pathname = useLocation().pathname;
    const navigate = useNavigate();
    const userExists = !!localStorage.getItem('userDetails');

    useEffect(() => {
        if (!userExists) navigate('/login?cart=true')
    }, [pathname]);

    return (
        <div>
            <Outlet/>
        </div>
    )
}