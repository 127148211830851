import React, {useEffect, useMemo, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useLocation, useNavigate, useSearchParams} from "react-router-dom";
import YourOrderSummary from "../../components/common/YourOrderSummary";
import {useForm} from "react-hook-form";
import {getCountry, getHeaderData, setGuestForm} from "../../store/home";
import GuestAddressInput from "../../components/guest-checkout/GuestAddressInput";
import {PayPalButtons, PayPalScriptProvider, usePayPalScriptReducer} from "@paypal/react-paypal-js";
import {getCartData, getPayPalCredentials, placeOrderAfterPayPalPayment,} from "../../store/newCart";
import StripePaymentContainer from "../../components/StripePaymentContainer";
import {images} from "../../utils/images";
import Loader from "../../Loader";
import Separator from "../../components/Seprator";
import {handleFinalizeOrder} from "../../lib/stripe-helpers";
import GeneralModal from "../../components/modal/GeneralModal";
import SiteLoader from "../../SiteLoader";

export function CreditCardIcon({className}) {
    return (
        <svg viewBox="0 0 24 24" fill="none" width='20'
             stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
             className="tr-icon">
            <rect x="1" y="4" width="22" height="16" rx="2" ry="2"></rect>
            <line x1="1" y1="10" x2="23" y2="10"></line>
        </svg>
    )
}

export function PaypalComponent(props) {
    const [{isPending}] = usePayPalScriptReducer();
    return (
        <>
            {isPending ? <div className='py-3 d-flex justify-content-center'><Loader width='40'/></div> : <PayPalButtons
                className="small-buttons-grp flex-grow-1 flex-lg-grow-0 mx-auto"
                disabled={props.disabled}
                style={{
                    layout: "horizontal", tagline: false,
                }} // You can customize the layout of the PayPal button
                createOrder={props.order
                } // Call the createOrder function when the button is clicked
                onApprove={props.onApprove
                } // Call the onApprove function after the payment is approved
                onError={props.onError} // Call the onError function if an error occurs during the payment process
            />}
        </>
    );
}

const GuestCheckout = () => {
    const paypalCredentials = useSelector((state) => state.newCart.paypalCredentials);
    const cart = useSelector((state) => state.newCart.cart);
    const countries = useSelector((state) => state.home.Country);
    const address = useSelector(state => state.home.guestFormData);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [saveAddress, setSaveAddress] = useState(false);

    //handling stripe redirections
    const [searchParams, setSearchParams] = useSearchParams();
    const paymentIntent = searchParams.get('payment_intent');
    const shippingIdParams = searchParams.get('shippingId')
    const status = searchParams.get('redirect_status');
    const [transactionLoading, setTransactionLoading] = useState(false);
    const location = useLocation().pathname;
    const [show, setShow] = useState(false);

    const {
        register,
        handleSubmit,
        formState: {errors},
        setValue,
        watch
    } = useForm();

    const onSubmit = (data) => {
        dispatch(setGuestForm(data)).then()
        setSaveAddress(true);
        dispatch(getCartData(true, data)).then()
        //the amazon payment gateway returns back to app and refreshes the page causing lost of redux and states
        //stored in localhost to retrieve address even after page refreshes.
        sessionStorage.setItem('guestUserAddress', JSON.stringify(data));
    };

    const createOrder = (data, actions) => {
        return actions.order.create({
            purchase_units: [
                {
                    amount: {
                        currency_code: "USD",
                        value: cart.total,
                    },
                },
            ],
        });
    };

    const onApprove = async (data, actions, address) => {
        // console.log(data, actions);
        try {
            const response = await actions.order.capture();
            if (response) {
                let isSuccess = await dispatch(
                    await placeOrderAfterPayPalPayment({
                        payment_response: response,
                        ...address,
                        guest_user_id: localStorage.getItem('guest_user_id')
                    }, '/guest-checkout')
                );

                if (isSuccess) {
                    navigate("/thank-you");
                }
            }
            return response;
        } catch (e) {
            // console.log("something went wrong in paypal: ", e);
        }
    };

    useMemo(() => {
        dispatch(getCartData(true))
        dispatch(getPayPalCredentials());
        dispatch(getCountry());
    }, []);

    useEffect(() => {
        if (cart) cart.status === 'nok' && navigate('/cart')
    }, [cart]);

    useEffect(() => {
        if (address) setSaveAddress(!!address)
    }, [address]);

    //order placement with return URL
    async function placeOrderWrapper() {
        if (status === 'succeeded') {
            setTransactionLoading(true)
            let guestAddress = {
                guest_user_id: localStorage.getItem('guest_user_id'),
                stripe_payment_intent: paymentIntent
            }
            const guestUserAddress = JSON.parse(sessionStorage.getItem('guestUserAddress'))
            try {
                await handleFinalizeOrder(paymentIntent, shippingIdParams, location, {...guestAddress, ...guestUserAddress});

                localStorage.removeItem('publishable_key')
                localStorage.removeItem('identifier')
                localStorage.removeItem('checkout_amount')
                localStorage.removeItem('client_secret')
                sessionStorage.removeItem('guestUserAddress')

                await dispatch(getHeaderData());
                await dispatch(getCartData(false))

                setTransactionLoading(false)
                navigate('/thank-you')
            } catch (error) {
                console.log(error)
            }

        } else if (status === 'failed') {
            const addressDetails = sessionStorage.getItem('guestUserAddress');
            dispatch(setGuestForm(JSON.parse(addressDetails))).then()
            setSaveAddress(true);
            setShow(true)
            setSearchParams('');
        }
    }

    useEffect(() => {
        if (paymentIntent && status) placeOrderWrapper().then()
    }, [paymentIntent, status]);

    if (!cart) {
        return (
            <div className="no-results cart">
                <img src={images["not-found.png"]} alt=""/>
                <h4>Oops! Your cart is empty.</h4>
            </div>
        )
    }

    if (transactionLoading) {
        return <SiteLoader status={true}/>
    }

    return (
        <div className="container">
            <GeneralModal show={show} setShow={setShow} title='Payment Failed'
                          message='Your Amazon payment was not successful.'/>
            <div className="row" style={{marginTop: "50px"}}>
                <div className="col-12 col-lg-8 order-1 order-lg-0">
                    <div className="guest_address_container">
                        <div className="d-flex justify-content-center align-items-center flex-column h-100">
                            <h1 className="guest_shipping_address_title text-center">Shipping Address</h1>
                            <form
                                onSubmit={handleSubmit(onSubmit)}
                                className="mt-2"
                                style={{maxWidth: "500px", margin: "0 auto"}}
                            >

                                {!saveAddress && (
                                    <GuestAddressInput
                                        register={register}
                                        errors={errors}
                                        countries={countries}
                                        setValue={setValue}
                                        watch={watch}
                                        address={JSON.parse(sessionStorage.getItem('guestUserAddress')) || address}
                                    />
                                )}

                                {saveAddress && (
                                    <div className="save_address_container">
                                        <div className="d-flex justify-content-between align-items-start">
                                            <h1 className="guest_shipping_address_title">Address</h1>
                                            <button
                                                onClick={() => setSaveAddress(!saveAddress)}
                                                className="edit_address_btn"
                                            >
                                                Edit Address
                                            </button>
                                        </div>

                                        <div className="guest_address_list">
                                            <p><strong>Name : </strong>{address?.name}</p>
                                            <p><strong>Country : </strong>{address?.country}</p>
                                            <p><strong>Address : </strong>
                                                {address?.street_address}, {address?.city}, {address?.state},{address?.postcode}
                                            </p>

                                            {address?.phone && <p><strong>Phone : </strong>{address.phone}</p>}
                                            <p><strong>Email : </strong>{address?.email}</p>
                                        </div>
                                    </div>
                                )}
                                {!saveAddress && <button type="submit" className="guest_address_save_btn">
                                    Checkout
                                </button>}
                            </form>
                            <div className="payment-btns">
                                {saveAddress ? (
                                    <>
                                        {Object.keys(paypalCredentials).length > 0 && (
                                            <PayPalScriptProvider
                                                options={{
                                                    clientId: paypalCredentials?.wobble_key
                                                        ? paypalCredentials?.wobble_key
                                                        : process.env.REACT_APP_PAYPAL_ID,
                                                }}
                                            >
                                                <PaypalComponent order={(data, actions) => createOrder(data, actions)}
                                                                 onApprove={(data, actions) => onApprove(data, actions, address)}
                                                                 onError={(err) => console.log(err)}/>
                                            </PayPalScriptProvider>
                                        )}
                                    </>
                                ) : null}
                            </div>
                            {saveAddress && <div>
                                <Separator text='Or, Pay with card / Wallet'/>
                                <StripePaymentContainer
                                    shippingId={''}
                                    guestAddress={address}
                                    checkoutAmount={parseInt(cart.total * 100)}
                                />
                            </div>}
                        </div>
                    </div>
                </div>
                <div className="col-12 col-lg-4 h-100 my-sm-4 my-lg-0 py-4 py-sm-0">
                    {cart && <YourOrderSummary cart={cart}/>}
                </div>
            </div>
        </div>
    );
};

export default GuestCheckout;
