import {useLayoutEffect, useMemo} from "react";
import {useLocation} from "react-router-dom";
import {handleNoRedirect} from "../../lib/services/services";

const ScrollToTop = () => {
    const {pathname} = useLocation();
    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    useMemo(() => {
        handleNoRedirect(pathname).then();
    }, [pathname]);
};

export default ScrollToTop;
