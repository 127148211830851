import {Link, useNavigate} from "react-router-dom";
import defaultImg from "../../components/img/default.png";
import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {images} from "../../utils/images";
import Dropdown from "../../components/General/DropDown";
import {addWishlist} from "../../store/product";
import SiteLoader from "../../SiteLoader";
import {Autoplay} from "swiper";
import {Swiper, SwiperSlide} from "swiper/react";
import BannerCarousel, {LazyImage} from "../Home/components/BannerCarousel";

export default function FeaturedProducts() {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const user = localStorage.getItem("userDetails");

    const homePageResponse = useSelector((state) => state.home.home_array);
    const [isGrid, setIsGrid] = useState(false);

    const adsImages = homePageResponse?.ads_banner;

    const addToWishList = (product_id, index) => {
        if (user) {
            dispatch(addWishlist(product_id)).then();

            let heartIcon = document.getElementById("heartIcon-" + index);
            heartIcon.classList.toggle("selected");
            heartIcon.classList.toggle("hearticoncolor");
        } else {
            navigate("/login");
        }
    };

    if (Array.isArray(homePageResponse)) {
        return <SiteLoader status={true}/>
    }


    const webImages = adsImages?.filter(image => image.is_mobile === 0);
    const mobileImage = adsImages?.filter(image => image.is_mobile === 1);

    return (
        <div className="mt-lg-5">
            <div className="position-relative">
                <Link to="/sale-products"><LazyImage img={webImages[0]?.image} smallImage={mobileImage[0]?.image}/></Link>
            </div>
            <div className="page-title">
                <div className="breadcrumb-head container d-block mt-5"><h1
                    className="feature-title text-center">Best
                    Selling</h1>
                </div>
            </div>
            <div className="col-12 mt-md-0 container pt-5">
                <Swiper
                    className="w-100"
                    spaceBetween={15}
                    slidesPerView={4}
                    freeMode={true}
                    modules={[Autoplay]}
                    autoplay={{
                        delay: 2000,
                        disableOnInteraction: false,
                    }}
                    speed={1000}
                    watchSlidesProgress={true}
                >
                    {homePageResponse.popular_products?.map((popularProduct, index) => {
                        return (
                            <SwiperSlide key={index}>
                                <div className="swiper popularProduct">
                                    <div className="swiper-wrapper">
                                        <div className="swiper-slide">
                                            <div className="mb-4 bg-white">
                                                <Link
                                                    to={`/shop/${popularProduct.pro_slug}-${popularProduct?.id}`}

                                                    className=""
                                                    key={index}
                                                >
                                                    <div className="">
                                                        <div
                                                            className="image-container">
                                                            <img
                                                                src={popularProduct.main_image}
                                                                loading='lazy'
                                                                className="carousel-size-1"
                                                                title=""
                                                                alt=""
                                                                onError={(e) =>
                                                                    (e.target.src = defaultImg)
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                        );
                    })}
                </Swiper>
            </div>
            <div className="breadcrumb-head page-title container mt-5 px-4">
                <h1 className="feature-title">Trending Now</h1>
                <div className="d-flex flex-wrap justify-content-end gap-4">
                    <div className="view-style">
                        <h3>Views : </h3>
                        {isGrid ? (
                            <button onClick={(e) => setIsGrid(false)}>
                                <img src={images["grid.svg"]} alt=""/>
                            </button>
                        ) : (
                            <button>
                                <img
                                    src={images["list.svg"]}
                                    alt=""
                                    onClick={(e) => setIsGrid(true)}
                                />
                            </button>
                        )}
                    </div>
                    {/*<Dropdown className="d-none d-md-block" selectedOption={selectedOption}*/}
                    {/*          setSelectedOption={setSelectedOption}/>*/}
                </div>
            </div>
            <div className={`${isGrid ? '' : 'grid-container best-seller-cards'} container pt-5`}>
                {homePageResponse.featured_products?.map((product, index) => {
                    return (
                        <div
                            key={index}
                            className={isGrid ? 'pb-3' : ''}
                        >
                            <Link to={`/shop/${product.pro_slug}-${product.id}`} keys={product.id}>
                                <div
                                    className={isGrid ? "list-item" : "item  me-2 product-card mb-3"}
                                >
                                    <div className="mt-0">
                                        <div
                                            className={`position-relative ${isGrid ? "row-image-size" : "image-container"}`}
                                            style={{
                                                width: `${!isGrid ? "100%" : "200px"}`,
                                            }}
                                        >
                                            <div className="wishlist-icon">
                                                <i
                                                    className={
                                                        product?.wishlist_status
                                                            ? "fa fa-heart hearticoncolor selected"
                                                            : "fa fa-heart"
                                                    }
                                                    aria-hidden="true"
                                                    wish-class
                                                    id={`heartIcon-${index}`}
                                                    style={{cursor: "pointer"}}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        e.stopPropagation();
                                                        addToWishList(product.id, index);
                                                    }}
                                                ></i>
                                            </div>
                                            <img
                                                src={product.main_image}
                                                className={`img-size`}
                                                title=""
                                                alt=""
                                                style={{
                                                    maxHeight: `${!isGrid && "100%"}`,
                                                }}
                                                // onError={(e) => (e.target.src = images["defaultImg.png"])}
                                            />
                                        </div>
                                        {/* <!-- product-cont  --> */}
                                        <div
                                            style={{
                                                width: `${isGrid ? "90%" : "auto"}`,
                                            }}
                                            className={` ${
                                                isGrid ? "p-0 product-content-row" : "py-3 product-content"
                                            }`}
                                        >
                                            <div className={`category_title`}>
                                                {product.pro_name?.length > 22 ? (
                                                    <p
                                                        spiketip-title={product.pro_name?.slice(0, 120)}
                                                        spiketip-pos="bottom-left"
                                                        spiketip-color="black"
                                                    >
                                                        {product.pro_name?.slice(0, 22)}
                                                        <small className="" style={{fontSize: "12px"}}>
                                                            ...
                                                        </small>
                                                    </p>
                                                ) : (
                                                    product?.pro_name
                                                )}
                                            </div>
                                            <span className='categoryCartDes' style={{fontWeight: '600'}}>
                                    {product.short_desc?.length > 25
                                        ? product.short_desc?.slice(0, 25) + "..."
                                        : product.short_desc}
                            </span>
                                            <div className="price">
                                                <div className="grey-text">

                                                    {!product?.sale_price &&
                                                        <span className={`text-warning ${isGrid ? "m-0" : ""}`}>
                                            ${product.regular_price}
                                          </span>
                                                    }

                                                    {product?.sale_price &&
                                                        <div
                                                            className='d-flex justify-content-center align-items-center gap-2'>
                                                            <p className="text-warning">
                                                                ${product.sale_price}
                                                            </p>
                                                            <p
                                                                className="strike-through">
                                                                ${product.regular_price}
                                                            </p>
                                                        </div>}
                                                </div>
                                            </div>
                                            {isGrid && (
                                                <button className="add-cart d-flex">
                                                    <i className="fa fa-shopping-cart"></i>
                                                    <span>Add to Cart </span>
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    );
                })}
            </div>
        </div>
    )
}