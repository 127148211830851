import React, {useRef, useState} from "react";
import {useOnClickOutside} from "../../../utils/customHooks";

const options = [
    {label: "Low to high", value: "Low to high"},
    {label: "High to low", value: "High to low"},
];

const SortProducts = ({className, selectedOption, handleSelectOption}) => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const priceDropdownRef = useRef();

    useOnClickOutside(priceDropdownRef, () => setIsDropdownOpen(false));

    return (
        <div className='dropdownLabel'>
            <p>Sort By : </p>
            <div ref={priceDropdownRef} className={`dropdown-container ${className}`}>
                <div
                    className="dropdown-input"
                    onClick={() => setIsDropdownOpen(!isDropdownOpen && true)}
                >
                    <input
                        type="text"
                        value={selectedOption ? selectedOption.label : ""}
                        readOnly
                        placeholder="Sort by"
                    />
                </div>
                {isDropdownOpen && (
                    <div className="dropdown-options">
                        {options.map((option) => (
                            <div
                                key={option.value}
                                className="dropdown-option"
                                onClick={() => {
                                    handleSelectOption(option);
                                    setIsDropdownOpen(false)
                                }}
                            >
                                {option.label}
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};

export default SortProducts;
