import React, {useRef} from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import '../styles/testimonials.css'
import {Rating} from "react-simple-star-rating";

//todo : Compress images

const fakeData = [
    {
        id: 1,
        userImage: './assets/img/t7.jpg',
        userDescription: "Bought my wife's engagement ring. She was extremely happy. Wonderful design and great quality.",
        userName: 'Maurice Jones',
        ratings: 4,
        firstImage: './assets/img/tr6.webp',
        lastImage: null
    },
    {
        id: 2,
        userImage: './assets/img/t1.png',
        userDescription: 'I have been shopping with Mangtum Marketplace for a few months now and I’ve been extremely happy with every order. Their customer service, shipping times and quality of the products is outstanding On top of their wonderful customer service! Highly recommend this company!!',
        userName: 'Aivalynn Owens',
        ratings: 5,
        firstImage: './assets/img/tr3.webp',
        lastImage: './assets/img/tr4.webp'
    },
    {
        id: 3,
        userImage: './assets/img/t3.png',
        userDescription: 'My fiancé bought me my engagement ring from Mangtum and I’m absolutely obsessed!',
        userName: 'Holly Courtwight',
        ratings: 5,
        firstImage: './assets/img/tr5.webp',
        lastImage: null
    },
    {
        id: 4,
        userImage: './assets/img/t4.png',
        userDescription: "Thrilled with my gemstone purchase! Stunning selection including aquamarines, Smokey quartz, and unique carnelians. Arrived early. Can't wait to buy more!",
        userName: 'Johanna',
        ratings: 5,
        firstImage: './assets/img/tr1.jpg',
        lastImage: './assets/img/tr2.webp'
    },
    {
        id: 5,
        userImage: './assets/img/t5.jpg',
        userDescription: "Beautiful gems, including aquamarines, Smokey quartz, carnelians, and more. Three green stones, likely dyed jade. Overall, a stunning selection. Arrived early, a pleasant surprise. Smokey quartz and aquamarine pair alone justify the purchase. Will definitely buy again!",
        userName: 'Elisha',
        ratings: 5,
        firstImage: './assets/img/tr7.webp',
        lastImage: null
    },
    {
        id: 6,
        userImage: './assets/img/t6.jpg',
        userDescription: 'Gems were packaged securely and shipping was fast. Got a variety of gemstones with a few chipped stones. A great deal for the price. Would recommend; thank you!',
        userName: 'emkang23',
        ratings: 5,
        firstImage: './assets/img/tr8.webp ',
        lastImage: './assets/img/tr9.jpg'
    },
    {
        id: 7,
        userImage: './assets/img/t8.jpg',
        userDescription: 'Mangtum doesn\'t just see us as a supplier; they see us as part of their extended team. It\'s more than just business; it\'s a partnership built on trust and mutual respect.',
        userName: 'Pawan Kumar',
        ratings: 5,
        lastImage: null,
        firstImage: null
    },
    {
        id: 8,
        userImage: './assets/img/t9.webp',
        userDescription: "As a small vendor, it can be daunting to work with larger retailers, but Mangtum has proven to be an exception. Bravo",
        userName: 'Susan Grover',
        ratings: 5,
        firstImage: null,
        lastImage: null
    },
    {
        id: 9,
        userImage: './assets/img/t2.jpeg',
        userDescription: 'The marketplace is very user friendly and their support has been invaluable in helping us reach a wider audience.',
        userName: 'Jessica E. Cook',
        ratings: 5,
        firstImage: null,
        lastImage: null
    },
]

export default function Testimonials() {
    const sliderRef = useRef(null);

    const next = () => {
        sliderRef.current.slickNext();
    };

    const previous = () => {
        sliderRef.current.slickPrev();
    };

    const settings = {
        infinite: true,
        speed: 1000,
        lazyLoad: true,
        autoplay: false,
        autoplaySpeed: 2000,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
    };

    return (
        <div className='position-relative text-center testimonials'>
            <h2 className='home-about-heading'>Testimonials</h2>
            <Slider ref={sliderRef} {...settings}>
                {fakeData.map((item, index) => {
                    return (
                        <div className='testimonial-card' key={index}>
                            <div className='flx'>
                                <figure>
                                    <img src={item.userImage} alt=''/>
                                </figure>
                                <div>
                                    <strong>{item.userName}</strong>
                                    <div className='text-start'>
                                        <Rating initialValue={5} size={20} readonly={true}/>
                                    </div>
                                </div>
                            </div>
                            <p className='ls_content'>{item.userDescription}</p>
                            {(item.firstImage || item.lastImage) && <div className='seller-rev'>
                                {item.firstImage && <figure>
                                    <img
                                        src={item.firstImage}
                                        alt=''/>
                                </figure>}
                                {item.lastImage && <figure>
                                    <img
                                        src={item.lastImage}
                                        alt=''/>
                                </figure>}
                            </div>}
                        </div>
                    )
                })}
            </Slider>

            {fakeData && (
                <div
                    className="position-absolute w-100 mx-auto d-flex align-items-center justify-content-between svg-width"
                    style={{top: "45%", zIndex: 55}}
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="2"
                        stroke="currentColor"
                        className="text-theme pointer"
                        style={{backgroundColor: "transparent"}}
                        onClick={previous}
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M15.75 19.5L8.25 12l7.5-7.5"
                        />
                    </svg>

                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="2"
                        stroke="currentColor"
                        className="text-theme pointer"
                        style={{backgroundColor: "transparent"}}
                        onClick={next}
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M8.25 4.5l7.5 7.5-7.5 7.5"
                        />
                    </svg>
                </div>
            )}
        </div>
    );
};
