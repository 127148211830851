import React, {useRef, useState} from "react";
import {useOnClickOutside} from "../../utils/customHooks";
import {useDispatch, useSelector} from "react-redux";
import {productActions} from "../../store/product";

const options = [
    {label: "Low to high", value: "Low to high"},
    {label: "High to low", value: "High to low"},
];

const Dropdown = ({className, setSmallScreen = null, selectedOption, setSelectedOption}) => {
    const {products} = useSelector((state) => state.prodLi);
    const dispatch = useDispatch();
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const priceDropdownRef = useRef();

    const handleSelectOption = async (option) => {
        if (option.value === "Low to high") {
            const resultAsc = [...products]?.sort(
                (a, b) => parseFloat(a.sell_price) - parseFloat(b.sell_price)
            );
            dispatch(productActions.setClearProducts(resultAsc));
        } else if (option.value === "High to low") {
            const resultDesc = [...products]?.sort(
                (a, b) => parseFloat(b.sell_price) - parseFloat(a.sell_price)
            );
            dispatch(productActions.setClearProducts(resultDesc));
        }
        setSelectedOption(option);
        setIsDropdownOpen(false);
        if (!!setSmallScreen) {
            setSmallScreen(false);
        }
    };

    useOnClickOutside(priceDropdownRef, () => setIsDropdownOpen(false));

    return (
        <div ref={priceDropdownRef} className={`dropdown-container ${className}`}>
            <div
                className="dropdown-input"
                onClick={() => setIsDropdownOpen(!isDropdownOpen && true)}
            >
                <input
                    type="text"
                    value={selectedOption ? selectedOption.label : ""}
                    readOnly
                    placeholder="Sort by"
                />
            </div>
            {isDropdownOpen && (
                <div className="dropdown-options">
                    {options.map((option) => (
                        <div
                            key={option.value}
                            className="dropdown-option"
                            onClick={() => handleSelectOption(option)}
                        >
                            {option.label}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default Dropdown;
