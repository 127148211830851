import GoogleOneTapLogin from 'react-google-one-tap-login';
import React, {Suspense, useContext, useEffect} from "react";
import {BrowserRouter as Router, Navigate, Route, Routes,} from "react-router-dom";
import "./index.css";
import Header from "./components/Header/Header.js";
import Footer from "./components/Footer/Footer.js";
import Contact from "./Pages/Contact";
import Personal from "./Pages/Personal";
import ShippingChange from "./Pages/ShippingChange";
import Category from "./Pages/Category";
import SellerStore from "./Pages/SellerStore";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import TermsCondition from "./Pages/TermsCondition";
import PageNotFound from "./Pages/PageNotFound";
import UserCartList from "./Pages/UserCartList";
import HelpTopics from "./Pages/HelpTopics";
import Faq from "./Pages/Faq";
import ProductListBySubCat from "./product/ProductListBySubCat";
import SingleProduct from "./product/SingleProduct";
import ProductByCategory from "./product/ProductByCategory";
import ProductSearch from "./product/ProductSearch";
import UserDashboard from "./dashboard/UserDashboard.js";
import UserAccount from "./dashboard/Account.js";
import UserOrders from "./dashboard/Order.js";
import UserAddress from "./dashboard/Address.js";
import {Helmet} from "react-helmet";
import UserOrderDetails from "./product/UserOrderDetails";
import UserWishlist from "./user/UserWishlist";
import Cart from "./Pages/Cart/Cart";
import Checkout from "./Pages/Checkout/Checkout";
import ThankYou from "./Pages/Checkout/ThankYou";
import {useDispatch, useSelector} from "react-redux";
import {setUserData, socialLogin} from "./store/auth.js";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import Blog from "./Pages/Blog/Blog";
import OrderDetails from "./dashboard/OrderDetails";
import SellerPage from "./Pages/seller/SellerPage";
import AddReview from "./components/reviews-ui/AddReview";
import ErrorPage from "./Pages/ErrorPage/ErrorPage.js";
import BlogDetails from "./Pages/Blog/BlogDetails.js";
import SiteLoader from "./SiteLoader.js";
import ShippingPolicy from "./Pages/ShippingPolicy/ShippingPolicy.js";
import ReturnPolicy from "./Pages/ReturnPolicy/ReturnPolicy.js";
import GuestCheckout from "./Pages/GuestCheckout/GuestCheckout.js";
import Home from './Pages/Home/Home.js'
import {getHomepage} from "./store/home";
import BottomPopup from "./Pages/Home/components/BottomPopup";
import OpenRoutesLayout from "./components/Layout/OpenRoutesLayout";
import ProtectedLayout from "./components/Layout/ProtectedLayout";
import FeaturedProducts from "./Pages/FeaturedProducts/FeaturedProducts";
import Page500 from "./Pages/Page500";
import {GoogleAuthProvider} from "firebase/auth";
import Swal from "sweetalert2";
import {AuthContext} from "./context/AuthContext";
import {getUserDefaultAddress} from "./store/checkout";
import SaleProducts from "./Pages/saleProducts/SaleProducts";

//auth
const Login = React.lazy(() => import("./user/Login"));
const ForgotPassword = React.lazy(() => import("./user/ForgotPassword"));
const ResetPassword = React.lazy(() => import("./user/ResetPassword"));
const Register = React.lazy(() => import("./user/Register"));
const Authentication = React.lazy(() => import("./user/Authentication"));

const About = React.lazy(() => import("./Pages/About/About.js"));

function App() {
    const dispatch = useDispatch();
    const auth = JSON.parse(localStorage.getItem("userDetails"));
    const {userData} = useSelector((state) => state.auth);
    const {status} = useSelector((state) => state.pages);
    const homerec = useSelector((state) => state.home.home_array);
    const Bestsellers = homerec?.featured_products;
    const isLoggedIn = !!localStorage.getItem("userDetails");

    const user = userData || auth;

    useEffect(() => {
        if(isLoggedIn) dispatch(getUserDefaultAddress())
        dispatch(getHomepage()).then();
        dispatch(setUserData(auth));
    }, []);
    
    return (
        <>
            <GoogleOneTapLogin onError={(error) => console.log(error)}
                               disabled={!!user}
                               onSuccess={(response) => dispatch(socialLogin(response))}
                               googleAccountConfigs={{client_id: process.env.REACT_APP_GOOGLE_ID}}/>
            <Router>
                <ScrollToTop/>
                <Header/>
                {Bestsellers && <BottomPopup products={Bestsellers}/>}
                <Helmet>
                    <title>
                        Gems, Jewelry, Beads, and Art Marketplace - Your One-Stop for
                        Exquisite Creations.
                    </title>
                    <meta name="robots" content={process.env.REACT_APP_META_ROBOTS}/>
                    <meta
                        name="description"
                        content="Explore a world of beauty and craftsmanship with our stunning collection of gems, jewelry, beads, and art. Find unique, handcrafted treasures for every occasion."
                    />
                    <meta
                        name="keywords"
                        content="Gemstones, Beads, Fine Jewelry, Art, Marketplace."
                    />
                </Helmet>

                {/*Auth*/}
                <Suspense fallback={<div>Loading...</div>}>
                    <Routes>
                        {/*User Dashboard*/}
                        <Route exact path="/personal" element={<Personal/>}/>
                        <Route exact path="/my-cart" element={user ? <UserCartList/> : <Login/>}/>
                        <Route exact path="/dashboard"
                               element={user || auth ? <UserDashboard/> : <Navigate to="/login"/>}/>
                        <Route exact path="/profile" element={user ? <UserAccount/> : <Navigate to="/login"/>}/>
                        <Route exact path="/orders" element={user ? <UserOrders/> : <Navigate to="/login"/>}/>
                        <Route exact path="/orders/:orderId"
                               element={user ? <OrderDetails/> : <Navigate to="/login"/>}/>
                        <Route exact path="/address" element={user ? <UserAddress/> : <Navigate to="/login"/>}/>
                        <Route exact path="/wishlist" element={user ? <UserWishlist/> : <Navigate to="/login"/>}/>
                        <Route exact path="/user-order-details" element={<UserOrderDetails/>}/>
                        <Route exact path="/featured-products" element={<FeaturedProducts/>}/>

                        {/*Static Web Page*/}
                        <Route
                            exact path="/"
                            element={<Home/>}/>
                        <Route
                            exact path="/sale-products"
                            element={<SaleProducts/>}/>
                        <Route exact path="/about" element={
                            <React.Suspense fallback={<SiteLoader status={true}/>}> <About/>
                            </React.Suspense>}
                        />
                        <Route exact path="/faq" element={<Faq/>}/>
                        <Route exact path="/blog" element={<Blog/>}/>
                        <Route exact path="/blog/:slug" element={<BlogDetails/>}/>
                        <Route exact path="/seller" element={<SellerPage/>}/>
                        <Route exact path="/terms-and-condition" element={<TermsCondition/>}/>
                        <Route exact path="/contact" element={<Contact/>}/>
                        <Route exact path="/privacypolicy" element={<PrivacyPolicy/>}/>
                        <Route exact path="/return-policy" element={<ReturnPolicy/>}/>
                        <Route exact path="/shipping-policy" element={<ShippingPolicy/>}/>
                        <Route exact path="/store/:storeSlug" element={<SellerStore/>}/>
                        <Route exact path="/page-not-found" element={<PageNotFound/>}/>
                        <Route exact path="/help-topics" element={<HelpTopics/>}/>

                        {/*Product, Category, SubCategory*/}
                        <Route exact path="/categories" element={<Category/>}/>
                        <Route exact path="/shop/:productTitle"
                               element={status === 404 ? <ErrorPage/> : <SingleProduct/>}/>
                        <Route
                            exact path="/:category/:subCategory"
                            element={status === 404 ? <ErrorPage/> : <ProductListBySubCat/>}/>
                        <Route exact path="/:category" element={status === 404 ? <ErrorPage/> : <ProductByCategory/>}/>
                        <Route exact path="/product-search" element={<ProductSearch/>}/>

                        {/*Cart CheckOut Page*/}
                        <Route exact path="/cart" element={<Cart/>}/>
                        <Route element={<OpenRoutesLayout/>}>
                            <Route exact path="/login" element={<Login/>}/>
                            <Route exact path="/register" element={<Register/>}/>
                            <Route path="/reset-password/*" element={<ResetPassword/>}/>
                            <Route exact path="/authentication" element={<Authentication/>}/>
                            <Route exact path="/forgot-password" element={<ForgotPassword/>}/>

                            <Route exact path="/guest-checkout" element={<GuestCheckout/>}/>
                        </Route>
                        <Route element={<ProtectedLayout/>}>
                            <Route exact path="/checkout" element={<Checkout/>}/>
                        </Route>
                        <Route exact path="/thank-you" element={<ThankYou/>}/>
                        <Route exact path="/shippingchange" element={<ShippingChange/>}/>

                        <Route path='/blogs' element={<Blog/>}/>

                        <Route exact path="/add-review" element={<AddReview/>}/>

                        {/* error page */}
                        <Route path="/500" element={<Page500/>}/>
                        <Route path="*" element={<ErrorPage/>}/>
                    </Routes>
                </Suspense>
                <Footer/>
            </Router>
        </>
    );
}

export default App;

{/*<Route exact path="/checkout" element={user ? <CheckoutOldCode /> : <Login />}/>*/
}
{/* <Route path="/reset-password/1edee312-9141-6170-8bed-6bbc4f1719e0" element={<ResetPassword/>} /> */
}
{/* <Route path='/sign-up' element={<SignUp/>} /> */
}
{/* <Route path="404" element={<ErrorPage statusCode={404} />} /> */
}
{/* <Route exact path="/checkout" element={<ShippingInfo />} /> */
}
{/* <Route exact path="/wishlist" element={user ? <UserWishlist /> : <Login />}/> */
}
