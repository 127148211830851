import React from "react";
import { Link } from "react-router-dom";

const BlogCategories = ({ categories }) => {
  // console.log(categories);
  return (
    <div className="blog-category-container bg-white rounded p-2">
      <h3 className="text-theme-blue font-theme">Categories</h3>

      <div>
        {categories?.map((cate, index) => (
          <Link to={`/blog?category=${cate?.slug}`}>
            <div
              key={index}
              className="category-button d-flex justify-content-between align-items-center gap-2 px-1 font-theme"
            >
              <div className="d-flex align-items-center gap-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  style={{ width: "10px" }}
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.348a1.125 1.125 0 010 1.971l-11.54 6.347a1.125 1.125 0 01-1.667-.985V5.653z"
                  />
                </svg>
                <p className="ms-2">{cate?.cate_name}</p>
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default BlogCategories;
