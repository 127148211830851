import moment from "moment/moment";
import React, { useState } from "react";
import { Lightbox } from "react-modal-image";
import { Rating } from "react-simple-star-rating";
// import profile from "../../assets/images/global/profile.png";

const UserReviewCard = ({ review }) => {
  const [open, setOpen] = useState("");
  return (
    <div className="row bg-theme-gray p-2 p-md-4 w-100 rounded my-5">
      <div className="col-md-4 border-0 border-end">
        <div className="d-flex align-items-center gap-3">
          <div className="review_user">
            <img src={review?.user?.avatar} alt="" />
            <h4 className="review-title mt-2">{review?.user?.full_name}</h4>
          </div>
          <p className="review-buyer">Verified Buyer</p>
        </div>
        <Rating readonly size={22} initialValue={review?.rating} />
        <p className="review-buyer">
          {moment(review?.created_at).format("MM/DD/YYYY")}
        </p>
      </div>
      <div className="col-md-8 px-md-4 mt-2 mt-md-0">
        <p className="user-review-paragraph">{review?.review}</p>
        <div className="mt-2 d-flex flex-wrap gap-3 pointer">
          {review?.review_image?.length > 0 && (
            <>
              {review?.review_image?.map((img, index) => (
                <>
                  {open === img ? (
                    <Lightbox
                      key={index}
                      medium={open}
                      large={open}
                      showRotate={true}
                      title=""
                      alt=""
                      onClose={() => setOpen("")}
                    />
                  ) : (
                    <img
                      onClick={() => setOpen(img)}
                      key={index}
                      src={img}
                      className="img-fluid"
                      width="90px"
                      height="90px"
                      style={{
                        borderRadius: "5px",
                        maxHeight: "90px",
                        minHeight: "90px",
                        minWidth: "90px",
                        objectFit: "cover",
                      }}
                      title=""
                      alt=""
                    />
                  )}
                </>
              ))}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default UserReviewCard;
