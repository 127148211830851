import React from "react";
import { Circles } from "react-loader-spinner";

const Loader = ({width}) => {
  return (
    <div>
        <Circles type="Circles" color="#f6a92c" height={width} width={width} />
    </div>
  );
};

export default Loader;
