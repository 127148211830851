export const handleNoRedirect = async (pathname) => {
    const cp = sessionStorage.getItem("current_path");
    if (cp && cp !== pathname) {
        sessionStorage.setItem("current_path", pathname);
        sessionStorage.setItem("previous_path", cp);
    } else if (cp && cp === pathname) {
        const pp = sessionStorage.getItem("previous_path");
        sessionStorage.setItem("current_path", pathname);
        sessionStorage.setItem("previous_path", pp);
    } else {
        sessionStorage.setItem("current_path", pathname);
        sessionStorage.setItem("previous_path", "");
    }
};

export const isRedirect = () => {
    const pp = sessionStorage.getItem("previous_path");
    if (pp) {
        if (pp === "/login" || pp === "/register") {
            return {status: false, previous_path: pp};
        } else if (pp === '/guest-checkout') {
            return {status: true, previous_path: '/checkout'};
        } else {
            return {status: true, previous_path: pp};
        }
    } else {
        return {status: false, previous_path: pp};
    }
};
