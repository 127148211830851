import React, {useEffect, useState} from "react";
import {Link, useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {Circles} from "react-loader-spinner";
import {Helmet} from "react-helmet";

import product, {
    addWishlist,
    getFiltercode,
    getProductByCategory, getProductBySale,
    productActions,
} from "../../store/product"
import {images} from "../../utils/images";
import {mgtApi} from "../../store/axios";
import Filters from "../../components/General/Filters";
import ProductContainer from "../../product/ProductContainer";
import ThemeBreadcrumb from "../../components/common/ThemeBreadcrumb";
import Dropdown from "../../components/General/DropDown";

export function removeTags(txt) {
    var rex = /(<([^>]+)>)/gi;
    return txt?.replace(rex, "");
}

const SaleProducts = () => {
    const dispatch = useDispatch();
    const siteUrl = window.location.href;
    const {category} = useParams();
    const stringWithoutHyphens = category?.replace(/-/g, " ");
    // console.log(category)

    const [listStyle, setListStyle] = useState(false);
    const [loadMore, setLoadMore] = useState(true);
    const [page, setPage] = useState(2);

    const {headerCategories} = useSelector((state) => state.home);
    const {
        products,
        fetchPermit,
        priceRange,
        filterQuery,
        filterList,
        total_page,
        categoryId,
        cate_meta,
    } = useSelector((state) => state.prodLi);
    const {filters, search_array} = useSelector((state) => state.home);

    const [showFullDescription, setShowFullDescription] = useState(false);
    const [searchState, setSearchState] = useState(false);

    const navigate = useNavigate();
    // console.log(category);

    const refetch = () => {
        dispatch(getProductBySale());
    };

    useEffect(() => {
        if (cate_meta?.description) {
            setShowFullDescription(removeTags(cate_meta.description).length < 500)
        }
    }, [cate_meta]);

    useEffect(() => {
        setSearchState(false);
        setPage(2);
        setLoadMore(true);
        if (!fetchPermit) {
            dispatch(getProductBySale());
        }
    }, [category]);

    const state = useSelector((state) => state.prodLi);

    const countLength = () => {
        return (
            <div className="counter">
                <strong>{state.length}</strong>
            </div>
        );
    };

    const user = localStorage.getItem("userDetails");

    const addToWishList = (product_id, index) => {
        if (user) {
            dispatch(addWishlist(product_id));

            let heartIcon = document.getElementById("heartIcon-" + index);
            heartIcon.classList.toggle("selected");
            heartIcon.classList.toggle("hearticoncolor");
        } else {
            navigate("/login");
        }
    };

    // --------------------------------------------
    const searchParams = useSelector((state) => state.prodLi.searchParams);
    const [smallScreen, setSmallScreen] = useState(false);

    useEffect(() => {
        setLoadMore(true);
    }, [searchState]);

    async function loadMoreProducts() {
        dispatch(productActions.setFetchPermit(true));
        if (page > 1 && loadMore && fetchPermit) {
            if (page > total_page) {
                setLoadMore(false);
            } else {
                let userDetails = localStorage.getItem("userDetails");
                let userData = JSON.parse(userDetails);
                let user_id = userData?.ID;
                let res = null;
                if (searchState) {
                    const res = await mgtApi.post(
                        `/sale-product-list?price_between=${priceRange[0]},${
                            priceRange[1]
                        }&page=${page}${categoryId && `&category_id=${categoryId}`}${filterQuery && `&${filterQuery}`}`
                    );
                    setLoadMore(res.data?.products?.length > 0 ? true : false);
                    dispatch(productActions.setProducts(res.data?.products));
                } else {
                    res = await mgtApi.post("/sale-product-list", {
                        page,
                        user_id,
                    });
                    setLoadMore(res.data?.products?.length > 0 ? true : false);
                    if (selectedOption?.value === 'Low to high') {
                        dispatch(productActions.setAscProducts(res.data?.data.products));
                    } else if (selectedOption?.value === 'High to low') {
                        dispatch(productActions.setDscProducts(res.data?.data.products));
                    } else {
                        dispatch(productActions.setProducts(res.data?.products));
                    }
                }
                setPage(page + 1);
            }
        }
    }

    const [selectedOption, setSelectedOption] = useState(null);

    return (
        <div>
            <Helmet>
                {cate_meta?.schema && (
                    <script type="application/ld+json">{`${cate_meta?.schema}`}</script>
                )}
                <meta charSet="utf-8"/>
                <link rel="canonical" href={siteUrl}/>
                <title>{cate_meta?.meta_title}</title>
                <meta name="description" content={cate_meta?.meta_description}/>
                <meta name="keywords" content={cate_meta?.meta_keywords}/>

                <meta name="twitter:card" content="summary_large_image"/>
                <meta name="twitter:url" content={siteUrl}/>
                <meta name="twitter:title" content={cate_meta?.meta_title}/>
                <meta
                    name="twitter:description"
                    content={removeTags(cate_meta?.meta_description)}
                />
                <meta name="twitter:image" content={cate_meta?.og_image}/>

                <meta property="og:type" content="website"/>
                <meta property="og:url" content={siteUrl}/>
                <meta property="og:title" content={cate_meta?.meta_title}/>
                <meta
                    property="og:description"
                    content={removeTags(cate_meta?.meta_description)}
                />
                <meta property="og:image" content={cate_meta?.og_image}/>
            </Helmet>

            <main>
                <ThemeBreadcrumb
                    title="Sale Products"
                    current_route="Sale Products"
                />

                <section className="single-wrapper container">
                    <div className="filter-head row">
                        {products?.length > 0 ? (
                            <div className="col-lg-5 col-12">
                                <div className="counter grey-text">
                                    Showing all {products.length} results{" "}
                                </div>
                            </div>
                        ) : (
                            <span className="col-lg-5 col-12"></span>
                        )}
                        <div className="col-lg-7 col-12 mt-4 mt-md-0">
                            {products?.length !== 0 && <div className="d-flex flex-wrap justify-content-end gap-4">
                                <div className="view-style">
                                    <h3>Views : </h3>
                                    {listStyle ? (
                                        <button onClick={(e) => setListStyle(false)}>
                                            <img src={images["grid.svg"]} alt=""/>
                                        </button>
                                    ) : (
                                        <button>
                                            <img
                                                src={images["list.svg"]}
                                                alt=""
                                                onClick={(e) => setListStyle(true)}
                                            />
                                        </button>
                                    )}
                                </div>
                                {/*<Dropdown className="d-none d-md-block" selectedOption={selectedOption}*/}
                                {/*          setSelectedOption={setSelectedOption}/>*/}
                                <button
                                    className="filter-btn"
                                    onClick={(e) => setSmallScreen(true)}
                                >
                                    Filters
                                </button>
                            </div>}
                        </div>
                    </div>
                    {/* <!-- product  --> */}
                    <div className="row">
                        {products?.length !== 0 &&
                            <div
                                className={`col-lg-3 col-md-4 ${
                                    smallScreen ? "resp-show" : "resp-hide"
                                }`}
                            >
                                <Filters
                                    options={filters}
                                    setSmallScreen={setSmallScreen}
                                    setSearchState={setSearchState}
                                    setPage={setPage}
                                    setLoadMore={setLoadMore}
                                    refetch={refetch}
                                />
                            </div>}
                        <ProductContainer
                            products={products}
                            loadMore={loadMore}
                            loadProducts={loadMoreProducts}
                            addToWishList={addToWishList}
                            isGrid={listStyle}
                            isLoading={state.loaderStatus}
                        />
                    </div>
                </section>
            </main>
        </div>
    );
};

export default SaleProducts;
