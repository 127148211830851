import React, {useEffect, useState} from "react";
import {images} from "../utils/images";
import SiteLoader from "../SiteLoader";
import InfiniteScroll from "react-infinite-scroller";
import {Circles} from "react-loader-spinner";
import ProductCard from "./ProductCard";

const ProductContainer = ({
                              products,
                              loadMore,
                              loadProducts,
                              addToWishList,
                              isGrid,
                              isLoading,
                          }) => {

    return (
        <div className={products.length ? `col-lg-9 col-md-8` : 'col-12'}>
            {isLoading ? (
                <SiteLoader status={isLoading}/>
            ) : (
                <>
                    {products?.length === 0 && !isLoading && (
                        <div className="no-results">
                            <img src={images["not-found.png"]} alt=""/>
                            <h4>Oops! No Products found.</h4>
                        </div>
                    )}
                    {products?.length > 0 && (
                        <InfiniteScroll
                            pageStart={2}
                            className="row g-4"
                            loadMore={loadProducts}
                            hasMore={loadMore}
                            loader={
                                <div className="spinner">
                                    <Circles type="Circles" color="#f6a92c" height={60} width={60}/>
                                </div>
                            }
                        >
                            {products.map((plist, index) => (
                                <ProductCard
                                    key={index}  // Added key prop for each mapped element
                                    index={index}
                                    product={plist}
                                    addToWishList={addToWishList}
                                    isGrid={isGrid}
                                />
                            ))}
                        </InfiniteScroll>
                    )}
                </>
            )}
        </div>

    );
};

export default ProductContainer;
