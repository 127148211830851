import {createSlice} from "@reduxjs/toolkit";
import {mgtApi} from "./axios";
import {toast} from "react-toastify";
import {getHeaderData, homeActions} from "./home";
import {getUserDefaultAddress} from "./checkout";
import {useSelector} from "react-redux";

//show the loader
let userDetails = localStorage.getItem("userDetails");
let data = JSON.parse(userDetails);

const initialCheckoutState = {
    loaderStatus: false,
    status: "init",
    cart: {},
    cartData: [],
    toastMessage: {text: "", status: ""},
    loggedInUser: data?.ID,
    updateCartItemQuantityLoading: false,
    stripeCredentials: {},
    paypalCredentials: {},

    checkoutAddressData: [],
    cartStatusUpdate: {},
    expStatus: false,
    checkDex: 0,
    expGiftStatus: false,
    checkDexGift: 0,
    addressList: [],
    addStatus: false,
    cartUpdateFail: {},
    cartFailStatus: false,
};

const newCartSlice = createSlice({
    name: "newCart",
    initialState: initialCheckoutState,
    reducers: {
        setCart(state, action) {
            state.cart = action.payload;
        },
        setCartData(state, action) {
            state.cartData = action.payload;
        },
        setErrorMsgInToast(state, action) {
            state.toastMessage.text = action.payload;
            state.toastMessage.status = "error";
            toast.error(action.payload, {
                position: "bottom-center",
                autoClose: 1500,
                theme: "dark",
            });
        },
        setSuccessMsgInToast(state, action) {
            state.toastMessage.text = action.payload;
            state.toastMessage.status = "success";
            toast.success(action.payload, {
                position: "bottom-center",
                autoClose: 1500,
                theme: "dark",
            });
        },
        setUpdateCartItemQuantityLoading(state, action) {
            state.updateCartItemQuantityLoading = action.payload;
        },
        setStripeCredentials(state, action) {
            state.stripeCredentials = action.payload;
        },
        setPaypalCredentials(state, action) {
            state.paypalCredentials = action.payload;
        },

        defaultAddressLists(state, action) {
            state.checkoutAddressData = action.payload;
        },
        updateCartSuccess(state, action) {
            state.cartStatusUpdate = action.payload;
        },
        updateCartFailure(state, action) {
            state.cartStatusUpdate = action.payload;
            state.cartFailStatus = false;
            toast.error(state.cartStatusUpdate.message);
        },

        checkExpressCheck(state, action) {
            state.expStatus = true;
            state.checkDex = action.payload.is_checked;
        },
        checkExpressUncheck(state, action) {
            state.expStatus = false;
            state.checkDex = action.payload.is_checked;
        },
        checkDexGiftCheck(state, action) {
            state.expGiftStatus = true;
            state.checkDexGift = action.payload.is_checked;
        },
        checkDexGiftUncheck(state, action) {
            state.expGiftStatus = false;
            state.checkDexGift = action.payload.is_checked;
        },
        userAddressDetailsSuccess(state, action) {
            state.addressList = action.payload;
        },
        addressUpdateModalOpen(state, action) {
            state.addStatus = action.payload;
        },
        addressUpdate(state, action) {
            state.addStatus = false;
        },
        updateLoaderStatus(state, action) {
            state.loaderStatus = action.payload;
        },
        updateQty(state, {payload}) {
            const {index, qty} = payload;
            state.cartItemsData.data[0].cart_items[index].quantity = qty;
            state.cartItemsData.data[0].cart_items[index].total_amount =
                Number(state.cartItemsData.data[0].cart_items[index].total_amount) *
                qty;
        },
    },
});

export const getStripeCredentials = (shouldShowLoading = false) => {
    return async (dispatch) => {
        if (shouldShowLoading) dispatch(newCartActions.updateLoaderStatus(true));
        try {
            // const response = await mgtApi.get("/stripe-credential");
            const response = await mgtApi.get("/bouncy-bubble");

            if (
                response?.data.status === "success" ||
                response?.data.status === "ok"
            ) {
                dispatch(newCartActions.setStripeCredentials(response?.data?.data));
            }

            dispatch(newCartActions.updateLoaderStatus(false));
        } catch (error) {
            // console.log("Error while getting stripeCredentials: ", error);
            dispatch(newCartActions.updateLoaderStatus(false));
        }
    };
};
export const getPayPalCredentials = (shouldShowLoading = false) => {
    return async (dispatch) => {
        if (shouldShowLoading) dispatch(newCartActions.updateLoaderStatus(true));
        try {
            const response = await mgtApi.get("/wobble-bubble");

            if (
                response?.data.status === "success" ||
                response?.data.status === "ok"
            ) {
                dispatch(newCartActions.setPaypalCredentials(response?.data?.data));
            }

            dispatch(newCartActions.updateLoaderStatus(false));
        } catch (error) {
            // console.log("Error while getting payPalCredentials: ", error);
            dispatch(newCartActions.updateLoaderStatus(false));
        }
    };
};
export const userFetchCart = (payload) => {

};
export const checkGift = (shipping_profile, is_checked) => {
    let userDetails = JSON.parse(localStorage.getItem("userDetails"));
    let guestAddress = sessionStorage.getItem("guestUserAddress");
    guestAddress = guestAddress ? JSON.parse(guestAddress) : null;
    let payload = {
        shipping_profile: shipping_profile,
        is_checked: is_checked ? 1 : 0,
    };
    if (userDetails?.ID) {
        payload["user_id"] = userDetails.ID;
    }
    return async (dispatch) => {
        try {
            await mgtApi.post("/check-gift", payload.user_id ? payload : {
                ...payload,
                guest_user_id: localStorage.getItem('guest_user_id')
            }).then((response) => {
                dispatch(getCartData(false, guestAddress));
                if (response.data.status === "ok") {
                    if (is_checked) {
                        dispatch(
                            newCartActions.checkDexGiftCheck(response.data.status, is_checked)
                        );
                        toast.success("Gift charge applied");
                    }
                    if (!is_checked) {
                        dispatch(
                            newCartActions.checkDexGiftUncheck(
                                response.data.status,
                                is_checked
                            )
                        );
                        toast.success("Gift charge removed");
                    }
                }
            });
        } catch (e) {
            // console.log('Error while updating gift: ', e);
        }
    };
};
export const checkExpress = (shipping_profile, is_checked, default_address) => {
    let userDetails = JSON.parse(localStorage.getItem("userDetails"));
    const guestAddress = sessionStorage.getItem("guestUserAddress");
    const parsedForm = guestAddress ? JSON.parse(guestAddress) : null;
    let payload = {
        shipping_profile: shipping_profile,
        is_checked: is_checked ? 1 : 0,
        default_address
    };
    if (userDetails?.ID) payload["user_id"] = userDetails.ID;
    else payload['guest_user_id'] = localStorage.getItem('guest_user_id ')

    return async (dispatch) => {
        try {
            await mgtApi.post("/check-express", payload.user_id ? payload : {
                address: parsedForm,
                ...payload,
                guest_user_id: localStorage.getItem('guest_user_id')
            }).then((response) => {
                dispatch(getCartData(false, parsedForm));
                if (response.data.status === "ok") {
                    if (is_checked) {
                        dispatch(
                            newCartActions.checkExpressCheck(response.data.status, is_checked)
                        );
                        toast.success("Expedited fee added");
                    }
                    if (!is_checked) {
                        dispatch(
                            newCartActions.checkExpressUncheck(
                                response.data.status,
                                is_checked
                            )
                        );
                        toast.success("Expedited fee removed");
                    }
                }
            });
        } catch (e) {
            // console.log('Error while updating express: ', e);
        }
    };
};

export const getCartData = (shouldShowLoading = false, address) => {
    return async (dispatch) => {
        let shippingDetails;
        if (shouldShowLoading) dispatch(newCartActions.updateLoaderStatus(true));
        let userDetails = JSON.parse(localStorage.getItem("userDetails"));
        let endPoint = "/temp-usercheckout";
        let payload = userDetails?.ID ? {user_id: userDetails.ID} : "";
        if (userDetails?.ID) {
            shippingDetails = await dispatch(getUserDefaultAddress());
            endPoint = "/usercheckout";
        }
        const guestUserId = localStorage.getItem('guest_user_id');
        try {
            let data = null;
            if (payload) {
                const body = {
                    ...payload,
                    userAddressId: shippingDetails.shipping_address?.id,
                    ...(guestUserId && {guest_user_id: guestUserId})
                };
                data = await mgtApi.post(endPoint, body);
            } else {
                if (guestUserId) {
                    data = await mgtApi.post(endPoint, {guest_user_id: guestUserId, address});
                }
            }

            dispatch(homeActions.setTempCartCount(data?.data?.cart_count || 0));
            dispatch(newCartActions.setCart(data?.data));
            if (data?.data && data?.data?.data?.length) {
                dispatch(newCartActions.setCartData([...data.data?.data]));
            } else {
                dispatch(newCartActions.setCartData([]));
            }
            dispatch(newCartActions.updateLoaderStatus(false));
        } catch (error) {
            // console.log("Error while getting cartData: ", error);
            dispatch(newCartActions.updateLoaderStatus(false));
        }
    };
};
export const placeOrderAfterStripePayment = (payload) => {
    return async (dispatch) => {
        dispatch(newCartActions.updateLoaderStatus(true));

        let data = new FormData();
        data.append("shipping_address", payload.shipping_address);
        data.append("stripe_token", payload.token);

        let isSuccess = false;
        try {
            const response = await mgtApi.post("stripe-transaction", data);
            // console.log(response.data);
            dispatch(getCartData());

            if (response.status === 200 || response?.data?.status === "success") {
                isSuccess =
                    response.status === 200 || response?.data?.status === "success";
            } else {
                isSuccess = false;
            }
            dispatch(newCartActions.updateLoaderStatus(false));
            return isSuccess;
        } catch (e) {
            // console.log("Error while returning response: ", e);
            dispatch(newCartActions.updateLoaderStatus(false));
        }
    };
};
export const placeOrderAfterPayPalPayment = (payload, pathname) => {
    return async (dispatch) => {
        dispatch(newCartActions.updateLoaderStatus(true));
        // console.log('payment_response: ', JSON.stringify(payload.response_data));
        let data = new FormData();
        data.append("shipping_address", payload.shipping_address);
        data.append("payment_response", JSON.stringify(payload.response_data));
        // data.append("express_products", payload.express_products);
        // data.append("gift_products", payload.gift_products);

        let isSuccess = false;
        try {
            let response;
            if (pathname !== '/guest-checkout') {
                response = await mgtApi.post("paypal-transaction", data);
                dispatch(getCartData());
            } else {
                response = await mgtApi.post("guest-paypal-transaction", payload);
                dispatch(getCartData(false))
            }
            isSuccess =
                response.status === 200 || response?.data?.status === "success";
            dispatch(newCartActions.updateLoaderStatus(false));
            return isSuccess;
        } catch (e) {
            // console.log('Error while returning response: ', e)
            dispatch(newCartActions.updateLoaderStatus(false));
        }
    };
};

export const AddToCart = (payload) => {
    // console.log("payload: ", payload);
    const productData = new FormData();
    productData.append("pro_slug", payload.pro_slug);
    productData.append("quantity", payload.quantity ? payload.quantity : 1);
    productData.append("product_id", payload.product_id);
    if (payload?.user_id) productData.append("user_id", payload.user_id);
    else productData.append("guest_user_id", localStorage.getItem('guest_user_id'));

    productData.append("type", "increment");
    if (payload.sku) productData.append("sku", payload.sku);
    if (payload.color) productData.append("color", payload.color);
    if (payload?.selectedAttributes && payload?.selectedAttributes?.length) {
        payload?.selectedAttributes?.forEach((item, index) => {
            productData.append(`attribute_id_${item.id}`, item.value);
        });
    }

    return async (dispatch) => {
        dispatch(newCartActions.updateLoaderStatus(true));
        try {
            const response = await mgtApi.post("user-create-cart-web", productData);
            await dispatch(getHeaderData());
            if (!payload?.user_id) {
                await dispatch(getCartData(false));
            }
            if (response.data.status === "fail") {
                // dispatch(newCartActions.setErrorMsgInToast(response?.data.msg));
            } else if (
                response?.data.status === "success" ||
                response?.data.status === "ok"
            ) {
                // dispatch(newCartActions.setSuccessMsgInToast(response?.data.msg));
                // dispatch(userFetchCart());
            }
            dispatch(newCartActions.updateLoaderStatus(false));
        } catch (e) {
            // dispatch(
            //   newCartActions.setErrorMsgInToast("Error! Something Went Wrong.")
            // );
            dispatch(newCartActions.updateLoaderStatus(false));
            // ERROR HANDLE HERE REMAINING
        }
    };
};
export const updateCartQuantity = (payload) => {
    let guestAddress = sessionStorage.getItem("guestUserAddress");
    guestAddress = guestAddress ? JSON.parse(guestAddress) : null;
    return async (dispatch) => {
        dispatch(newCartActions.setUpdateCartItemQuantityLoading(true));
        try {
            const productData = new FormData();

            productData.append("pro_slug", payload.pro_slug);
            productData.append("product_id", payload.product_id);
            productData.append("quantity", payload.quantity);
            if (payload?.user_id) productData.append("user_id", payload.user_id);
            else productData.append("guest_user_id", localStorage.getItem('guest_user_id'));
            if (payload.sku) productData.append("sku", payload.sku);

            const response = await mgtApi.post("user-create-cart-web", productData);
            dispatch(getCartData(false, guestAddress));
            dispatch(getHeaderData());
            if (response.data.status === "ok") {
                dispatch(newCartActions.setUpdateCartItemQuantityLoading(false));
                //     dispatch(newCartActions.updateCartSuccess(response.data.message));
            } else {
                //     dispatch(newCartActions.updateCartFailure(response.data));
            }
        } catch (e) {
            dispatch(newCartActions.setUpdateCartItemQuantityLoading(false));
            // console.log("Error while updating cartItem: ", e);
        }
    };
};
export const removeCart = (cart_id) => {
    return async (dispatch, getState) => {
        try {
            const currentState = getState();
            const currentUser = JSON.parse(localStorage.getItem('userDetails'));
            const response = await mgtApi.post("user-remove-cart", {
                cart_id: cart_id,
                user_id: currentUser?.ID || null,
            });
            if (response.data.status === "ok" || response.data.status === "success") {
                const cartItemsData = [].concat(
                    ...currentState.newCart?.cartData?.map((obj) => obj.cart_items)
                );
                if (cartItemsData && cartItemsData.length === 1) {
                    dispatch(newCartActions.setCart({}));
                    dispatch(newCartActions.setCartData([]));
                }
            }
        } catch (e) {
            // console.log("Error while deleting item in cart: ", e);
        }
    };
};

export const newCartActions = newCartSlice.actions;
export default newCartSlice.reducer;
