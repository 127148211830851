import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Link, useLocation, useNavigate, useParams,} from "react-router-dom";
import {Circles} from "react-loader-spinner";
import {Nav, Tab} from "react-bootstrap";

import ResponsivePagination from "react-responsive-pagination";
import "react-responsive-pagination/themes/classic.css";

import {addWishlist, getVendorInfo} from "../store/product";
import {images} from "../utils/images";
import "../styles/seller-store.css";
// import { Ci rcles } from "react-loader-spinner";
import {get_meta} from "../store/home";
import {Helmet} from "react-helmet";
import {Rating} from "react-simple-star-rating";
import SortProducts from "./seller/components/SortProducts";

const SellerStore = () => {
    const {pathname} = useLocation();
    const navigate = useNavigate();
    useEffect(() => {
        window.scrollTo({top: 0});
    }, [pathname]);
    const dispatch = useDispatch();
    const {storeSlug, page} = useParams();
    const search = useLocation().search;
    const searchParams = new URLSearchParams(search);
    const [currentPage, setCurrentPage] = useState(
        parseInt(searchParams.get("page")) || 1
    );

    const [products, setProducts] = useState([]);
    const [selectedFilter, setSelectedFilter] = useState({label: "Low to high", value: "Low to high"})
    // const [loader, setLoader] = useState(false);
    const [query, setInputQuery] = useState("");

    const vendorInfo = useSelector((state) => state.prodLi.vendorInfo);
    const initialProducts = vendorInfo.products;
    const loaderStatus = useSelector((state) => state.prodLi.loaderStatus);
    const singleProduct = useSelector((state) => state.prodLi.singleProduct);

    const handleInputQuery = (value) => {
        if (value.length > 0) {
            setInputQuery(value);
        } else {
            setInputQuery("");
        }
    };

    const handleKeyPress = (e) => {
        const search_text = (e.target.value || query).toLowerCase();
        setLoader(true);
        if (search_text.length > 0) {
            dispatch(getVendorInfo(storeSlug, currentPage, search_text));
        } else {
            dispatch(getVendorInfo(storeSlug, 1));
            setCurrentPage(1);
            navigate(`/store/${storeSlug}?page=${1}`);
        }
    };

    const checkQuery = () => {
        const search_text = query.toLowerCase();
        if (search_text.length < 1) {
            navigate(`/store/${storeSlug}?page=${currentPage}`);
        }
    };

    /** get all meta information */
    const mainstate = useSelector((state) => state.home);
    const metaTags = mainstate.allmeta;
    const siteUrl = window.location.href;

    useEffect(() => {
        dispatch(get_meta("vendor-store"));
    }, []);

    /**  show the products return from search */
    useEffect(() => {
        setProducts(vendorInfo?.products);
        setLoader(loaderStatus);
    }, [query]);

    const handlePaginate = (pageNumber) => {
        /** set scroll to that section */
        const element = document.querySelector(".tab-content");
        element.scrollIntoView({
            behavior: "smooth",
        });

        setLoader(true);
        dispatch(getVendorInfo(storeSlug, pageNumber));
        setCurrentPage(pageNumber);
        navigate(`/store/${storeSlug}?page=${pageNumber}`);
    };

    /** find products on the basis of current page */
    useEffect(() => {
        setLoader(true);
        if (query && query?.length) {
            dispatch(getVendorInfo(storeSlug, currentPage, query));
        } else {
            dispatch(getVendorInfo(storeSlug, currentPage));
        }
    }, [currentPage]);

    /** set the matched products to show */
    useEffect(() => {
        setProducts(vendorInfo?.products);
        setLoader(loaderStatus);
    }, [vendorInfo]);

    const [loader, setLoader] = useState(false);
    const {vendor, address} = vendorInfo;
    // console.log("Vendor Info", vendorInfo);

    const user = localStorage.getItem("userDetails");

    const addToWishList = (product_id, index) => {
        if (user) {
            dispatch(addWishlist(product_id));

            let heartIcon = document.getElementById("heartIcon-" + index);
            heartIcon.classList.toggle("selected");
            heartIcon.classList.toggle("hearticoncolor");
        } else {
            navigate("/login");
        }
    };

    function handleProductSort(selectedOption) {
        if (selectedOption.value === "Low to high") {
            const resultAsc = [...products]?.sort(
                (a, b) => parseFloat(a.selling_price) - parseFloat(b.selling_price)
            );
            setProducts(resultAsc)
            setSelectedFilter(selectedOption)
        } else {
            const resultDesc = [...products]?.sort(
                (a, b) => parseFloat(b.selling_price) - parseFloat(a.selling_price)
            );
            setProducts(resultDesc)
            setSelectedFilter(selectedOption)
        }
    }

    return (
        <div>
            <Helmet>
                <meta charSet="utf-8"/>
                {/*<title>{vendor?.store_name}</title>
        <meta name="description" content={vendor?.bio} /> */}

                <title>{metaTags?.meta_data?.meta_title}</title>
                <meta
                    name="description"
                    content={metaTags?.meta_data?.meta_description}
                />
                <meta name="keywords" content={metaTags?.meta_data?.meta_keywords}/>

                <meta name="twitter:card" content="summary_large_image"/>
                <meta name="twitter:url" content={siteUrl}/>
                <meta name="twitter:title" content={metaTags?.meta_data?.og_title}/>
                <meta
                    name="twitter:description"
                    content={metaTags?.meta_data?.og_description}
                />
                <meta name="twitter:image" content={metaTags?.meta_data?.og_image}/>
                {/*<meta name="twitter:title" content={vendor?.store_name} />
        <meta name="twitter:description" content={vendor?.bio} />
        <meta name="twitter:image" content={SiteLogo} /> */}

                {/* <meta property="og:type" content="website" /> */}
                <meta property="og:type" content={metaTags?.meta_data?.og_type}/>
                <meta property="og:url" content={siteUrl}/>
                <meta property="og:title" content={metaTags?.meta_data?.og_title}/>
                <meta
                    property="og:description"
                    content={metaTags?.meta_data?.og_description}
                />
                <meta property="og:image" content={metaTags?.meta_data?.og_image}/>
                {/*<meta property="og:title" content={vendor?.store_name} />
        <meta property="og:description" content={vendor?.bio} />
        <meta property="og:image" content={SiteLogo} /> */}
            </Helmet>
            <main>
                <section className="p-0 store-section">
                    {/* <!-- seller profile --> */}
                    <div className="container">
                        <div
                            className="seller-profile border border-white rounded"
                            style={{
                                backgroundImage: `url(${vendor?.store_cover_image})`,
                                backgroundSize: "cover",
                                backgroundRepeat: "no-repeat",
                            }}
                        >
                            <div className="bgForProfile profile_box border border-white rounded">
                                <img
                                    className="rounded"
                                    style={{width: "80px", height: "80px", objectFit: "cover"}}
                                    src={vendor?.avatar === 'https://mangtum.net/admin/public/images/default-image.jpg' ? `${window.location.origin}/assets/img/user.png` : vendor?.avatar}
                                    title={vendor?.store_name}
                                    alt="store-avatar"
                                />

                                <h5 className="store_name"> {vendor?.store_name} </h5>
                                <div className="seller-country-text">
                                    <p>{address?.city},</p>
                                    <p>{address?.country_name}</p>
                                </div>
                                <Rating
                                    initialValue={5}
                                    ratingValue={5}
                                    size={25}
                                    readonly={true}
                                />
                            </div>
                        </div>
                    </div>

                    <Tab.Container id="left-tabs-example" defaultActiveKey="productsTab">
                        <div className="container single-product-section">
                            <div
                                className="page-title py-3 px-1 px-sm-5 d-flex flex-wrap align-items-center justify-content-between"
                                style={{minheight: "70px"}}
                            >
                                <div className="store-tabs">
                                    <Nav variant="tabs" className="d-flex align-items-center">
                                        <Nav.Item className="">
                                            <Nav.Link
                                                eventKey="productsTab"
                                                className="sllerNav px-0"
                                            >
                                                All Products
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="aboutTab" className="sllerNav ms-3">
                                                About
                                            </Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                </div>
                                <div
                                    className="searchtxt searchtxtSeller w-100"
                                    spiketip-title="🔍 Search Anything"
                                    spiketip-pos="left"
                                    spiketip-color="error"
                                >
                                    <input
                                        type="text"
                                        name="query"
                                        className="form-control"
                                        placeholder="Search Product"
                                        id="search_item"
                                        value={query}
                                        onKeyUp={(e) => handleKeyPress(e)}
                                        onChange={(e) => handleInputQuery(e.target.value)}
                                    />
                                    <span
                                        to=""
                                        className="search_icon"
                                        //  onClick={handleSearch}
                                    >
                    <i className="fa fa-search" aria-hidden="true"></i>
                  </span>
                                </div>
                            </div>
                        </div>

                        <Tab.Content>
                            {/* <!-- vendor about tab  --> */}

                            <Tab.Pane eventKey="aboutTab">
                                <div className="container">
                                    <div className="flex-between">
                                        <div className="profile-dtl">
                                            <h3>{vendor?.store_name}</h3>
                                            <div className="content set-content-width">
                                                {vendor?.bio}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Tab.Pane>

                            {/* <!-- end about tab  --> */}

                            {/* <!-- vendor products tab  --> */}

                            <Tab.Pane eventKey="productsTab" id="productsTab">
                                <div className="container">
                                    <div className="sortProductContainer">
                                        <div className="counter grey-text">
                                            Showing all {products?.length} results{" "}
                                        </div>
                                        <SortProducts
                                            handleSelectOption={(selectedOption) => handleProductSort(selectedOption)}
                                            selectedOption={selectedFilter}/>
                                    </div>

                                    {/* <!-- loader  --> */}
                                    <div className="">
                                        {loader ? (
                                            <div className="d-flex align-items-center mb-4">
                                                <div className="spinner">
                                                    <Circles
                                                        type="Circles"
                                                        color="#f6a92c"
                                                        height={60}
                                                        width={60}
                                                    />
                                                </div>
                                            </div>
                                        ) : (
                                            <></>
                                        )}
                                        <div className="grid-container">
                                            {products?.map((plist, index) => {
                                                return (
                                                    <div
                                                        key={index}
                                                        className={`position-relative w-100`}
                                                    >
                                                        <div className="wishlist-icon">
                                                            <i
                                                                className={
                                                                    plist?.wishlist_status
                                                                        ? "fa fa-heart hearticoncolor selected"
                                                                        : "fa fa-heart"
                                                                }
                                                                aria-hidden="true"
                                                                wish-class
                                                                id={`heartIcon-${index}`}
                                                                style={{cursor: "pointer"}}
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    addToWishList(plist.id, index);
                                                                }}
                                                            ></i>
                                                        </div>
                                                        <Link
                                                            to={`/shop/${plist.product_slug}-${plist?.id}`}
                                                            className={"item product-card me-2 mb-3 w-100"}
                                                            keys={plist.id}
                                                        >
                                                            <div className="wow animated fadeInUp mt-0">
                                                                <div className="image-container">
                                                                    <img
                                                                        src={plist.main_image}
                                                                        className="img-size"
                                                                        title=""
                                                                        alt=""
                                                                        onError={(e) =>
                                                                            (e.target.src = images["defaultImg.png"])
                                                                        }
                                                                    />
                                                                </div>
                                                                <div
                                                                    style={{width: "auto"}}
                                                                    className={`product-content py3`}
                                                                >
                                                                    <div className="category_title mt-3"
                                                                         spiketip-title={plist.pro_name?.slice(0, 120)}
                                                                         spiketip-pos="bottom-left"
                                                                         spiketip-color="black">
                                                                        {plist.pro_name.length > 18
                                                                            ? plist.pro_name.slice(0, 18) + "..."
                                                                            : plist.pro_name}
                                                                    </div>
                                                                    <div className="categoryCartDes oneLine text-wrap">
                                                                        {plist.short_desc?.length > 25
                                                                            ? plist.short_desc?.slice(0, 25) + "..."
                                                                            : plist.short_desc}
                                                                    </div>
                                                                    <div className="price pb-2">
                                                                        <div className="grey-text">

                                                                            {!plist?.sale_price &&
                                                                                <span className={`text-warning`}>
                                                                                ${plist.regular_price}
                                                                            </span>}

                                                                            {plist?.sale_price && <div
                                                                                className='d-flex justify-content-center align-items-center gap-2'>
                                                                                <p className="text-warning">
                                                                                    ${plist.sale_price}
                                                                                </p>
                                                                                <p
                                                                                    className="strike-through">
                                                                                    ${plist.regular_price}
                                                                                </p>
                                                                            </div>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                </div>

                                <div
                                    className="d-flex justify-content-center pagination-root container px-4"
                                    style={{marginTop: "100px"}}
                                >
                                    <ResponsivePagination
                                        current={currentPage}
                                        total={parseInt(vendorInfo?.total_product_pages) || 0}
                                        onPageChange={(page) => handlePaginate(page)}
                                        limit={15}
                                        maxWidth="100"
                                    />
                                </div>
                            </Tab.Pane>

                            {/* <!-- end products tab  --> */}
                        </Tab.Content>
                    </Tab.Container>
                </section>
            </main>
        </div>
    );
};

export default SellerStore;
