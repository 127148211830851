import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { productActions } from "../../store/product";

const FilterOption = ({ item, optionIndex, optionItem }) => {
  const { filterArray } = useSelector((state) => state.prodLi);
  const dispatch = useDispatch();
  let isExist = filterArray?.some(
    (obj) => obj.id === item?.attr_id && obj.attribute_value === optionItem.id
  );

  return (
    <li
      key={optionIndex}
      className="pointer d-flex align-items-center mt-1"
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        dispatch(
          productActions.setFilterUniqueArray({
            id: item?.attr_id,
            attribute_value: optionItem.id,
          })
        );
      }}
    >
      <input type="checkbox" className="pointer" checked={isExist} />
      <span className="filterSub_category pointer">
        {optionItem.attribute_value}
      </span>
    </li>
  );
};

export default FilterOption;
