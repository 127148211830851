import React, {useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {PaymentElement, useElements, useStripe,} from "@stripe/react-stripe-js";
import {handleFinalizeOrder, handleUpdatePaymentIntent} from "../lib/stripe-helpers";
import {useDispatch} from "react-redux";
import {getCartData} from "../store/newCart";

/**
 * Payment form for credit card payment method and collecting other customer details.
 * @component
 * @returns {JSX.Element} The rendered React component.
 */
const PaymentForm = ({checkoutAmount, shippingId, expressIds, giftIds, guestAddress}) => {
    const dispatch = useDispatch();
    const location = useLocation().pathname;
    const [paymentSucceeded, setPaymentSucceeded] = useState(false);
    const [error, setError] = useState("");
    const [isProcessing, setIsProcessing] = useState(false);

    const elements = useElements();
    const stripe = useStripe();
    const navigate = useNavigate();

    const handleOnSubmit = async (e) => {
        e.preventDefault();
        setError("");
        setIsProcessing(true);

        if (!stripe || !elements) {
            return;
        }

        const oldCheckoutAmount = localStorage.getItem("checkout_amount");
        const identifier = localStorage.getItem("identifier");

        if (oldCheckoutAmount !== checkoutAmount) {
            const response = await handleUpdatePaymentIntent(
                identifier,
                checkoutAmount
            );

            if (response === "requires_payment_method") {
                elements.fetchUpdates();
            }
        }

        const confirmPaymentIntentOptions = {
            elements,
            redirect: "if_required",
            confirmParams: {
                return_url: `${window.location.href}?shippingId=${shippingId}`,
            },
        };

        const {error: paymentIntentError, paymentIntent} = await stripe.confirmPayment(confirmPaymentIntentOptions);

        if (paymentIntentError) {
            setError(paymentIntentError.message);
            setIsProcessing(false);
            return;
        } else if (paymentIntent.status === "succeeded") {
            handleFinalizeOrder(paymentIntent.id, shippingId, location, {
                ...guestAddress,
                guest_user_id: localStorage.getItem('guest_user_id'),
                stripe_payment_intent: localStorage.getItem("identifier")
            }).then(() => {
                dispatch(getCartData(false));
                setIsProcessing(false)
                navigate("/thank-you");
            });
        } else {
            setError(
                "Something went wrong with confirming payment. Please try again later."
            );
        }
        localStorage.removeItem('publishable_key')
        localStorage.removeItem('identifier')
        localStorage.removeItem('checkout_amount')
        localStorage.removeItem('client_secret')
        setPaymentSucceeded(true);
    };

    return (
        <form
            style={{display: "flex", flexDirection: "column"}}
            onSubmit={handleOnSubmit}
        >
            <PaymentElement onLoadError={(error) => setError(error.error.message)}/>
            <button className="theme-btn px-3 py-2 mt-3" type="submit">
                {isProcessing ? "Processing.." : "Pay now"}
            </button>
            <span style={{color: "red"}}>{error}</span>
        </form>
    );
};

export default PaymentForm;
