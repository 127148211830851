import BlogCard from "../../Blog/BlogCard";
import author from "../../../assets/brand/mangtum.png";
import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getBlogs} from "../../../store/product";
import {Link} from "react-router-dom";

export default function HomeBlog() {
    const {blogs, loaderStatus} = useSelector((state) => state.prodLi);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getBlogs(null)).then();
    }, []);

    if (loaderStatus) {
        return <p>Loading...</p>
    }

    return (
        <section>
            <div className='row'>
                <div className='text-center ls_content'>
                    <h2 className='mb-3 home-about-heading'>Blog Updates</h2>
                    <p>Our blog is your ultimate guide to finding inspiration and expressing yourself </p>
                </div>
                {blogs.blog_post?.slice(0, 3).map((blog, index) => (
                    <BlogCard key={index} blog={blog} author={author}/>
                ))}

            </div>

            <Link to='/blog' className="view-more">Read More</Link>
        </section>
    )
}