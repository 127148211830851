import '../styles/CartPopup.css'
import {FaXmark} from "react-icons/fa6";
import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {fixChar} from "../../utils/helper";
import {useDispatch, useSelector} from "react-redux";
import {getCartData, removeCart} from "../../store/newCart";
import {images} from "../../utils/images";
import {showConfirmation} from "../../utils/confirmation";
import {getHeaderData} from "../../store/home";
import Loader from "../../Loader";

function CartItemPopupList({item}) {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false)

    async function handleRemoveCart(e, cartItemId) {
        e.preventDefault();
        setLoading(true)
        await dispatch(removeCart(cartItemId));
        await dispatch(getCartData());
        await dispatch(getHeaderData());
        setLoading(false)
    }

    return (
        <li>
            <Link to={`/shop/${item.pro_slug}`}>
                <figure>
                    <img src={item.main_image} alt=''/>
                </figure>
                <div className='cart-card_body'>
                    <span>{fixChar(item.pro_name, 30)}</span>
                    <div className='d-flex justify-content-between align-items-end'>
                        <div>
                            <p>${item.total_amount}</p>
                            <p>Quantity : {item.quantity}</p>
                            {Object.keys(item.pro_combinations).map((comb, index) => (
                                <React.Fragment key={index}>
                                    {comb === "color_combi" ? (
                                        <div className="d-flex gap-1">
                                            <p className="">
                                                Color :
                                            </p>
                                            <p className="">
                                                {item["pro_combinations"]["color_combi"]["color"]}
                                            </p>
                                        </div>
                                    ) : (
                                        <>
                                            {item["pro_combinations"]["other_combi"].map(
                                                (os) => (
                                                    <div className="d-flex gap-1">
                                                        <p
                                                            className=""
                                                        >
                                                            {os.name} :
                                                        </p>
                                                        <p className="">
                                                            {os.value}
                                                        </p>
                                                    </div>
                                                )
                                            )}
                                        </>
                                    )}
                                </React.Fragment>
                            ))}
                        </div>
                        {loading ? <Loader width='26'/> :
                            <button className="removebtnBin"
                                    onClick={e => handleRemoveCart(e, item.cart_id)}>
                                <img src={images["Bin.png"]} alt=""
                                     style={{width: '16px'}}/>
                            </button>}
                    </div>
                </div>
            </Link>
        </li>
    )
}

export default function CartPopup({showPopup, setShowPopup, cartData}) {
    const cart = useSelector((state) => state.newCart.cart);
    const headerData = useSelector((state) => state.home.headerData);
    const tempCartCount = useSelector((state) => state.home.tempCartCount)

    const isLoggedIn = !!localStorage.getItem('userDetails');

    useEffect(() => {
        if (!tempCartCount || !headerData?.cart_count) {
            setShowPopup(false)
        }
    }, [cart]);

    return (
        <aside className={`cart-drawer_container ${showPopup.value ? 'active' : ''}`}>
            <div className='cart-drawer_top'>
                <strong>
                    My Cart ({isLoggedIn ? (
                    <span>
                        {headerData && headerData.cart_count > 0 ? headerData.cart_count : 0}
                    </span>
                ) : (
                    <span>{tempCartCount}</span>
                )})
                </strong>
                <button onClick={() => setShowPopup({product: null, value: false})}>
                    <FaXmark className='gen-icon'/>
                </button>
            </div>
            <div className='cart-drawer_body'>
                <ul>
                    {cartData.map((item, index) => {
                        return (
                            <li key={index} className='pt-2'>
                                <h5 className='py-2 text-center border-bottom border-top'>{item.store_name}</h5>
                                <ul className='pt-2'>
                                    {item.cart_items.map((item) => {
                                        return <CartItemPopupList item={item} key={item.id}/>
                                    })}
                                </ul>
                            </li>
                        )
                    })}
                </ul>
                <div>
                    <div className='sub-total'>
                        <strong>Subtotal : </strong>
                        <strong>${cart?.subtotal || 0}</strong>
                    </div>
                    <div className='action-buttons'>
                        <Link to='/cart'>View Cart</Link>
                        <Link to={isLoggedIn ? '/checkout' : '/login?cart=true'}>Checkout</Link>
                    </div>
                </div>
            </div>
        </aside>
    )
}