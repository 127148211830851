import {configureStore} from "@reduxjs/toolkit";
import homeReducer from "./home";
import authReducer from "./auth";
import productReducer from "./product";
import newCartReducer from "../../src/store/newCart";
import pagesReducer from "../../src/store/pages";
import cart from "./cart";
import checkOutReducer from "./checkout";

const store = configureStore({
    reducer: {
        home: homeReducer,
        auth: authReducer,
        prodLi: productReducer,
        cart: cart,

        newCart: newCartReducer,
        checkout: checkOutReducer,
        pages: pagesReducer,
    },
});

export default store;
