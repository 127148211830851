import React, { useEffect, useRef, useState } from "react";
import { useOnClickOutside } from "../../../utils/customHooks";
import { checkSubscribe } from "../../../store/home";
import { useDispatch, useSelector } from "react-redux";
import logo from "../../../assets/images/home/logo.png";
import close from "../../../assets/icons/close.png";
import { Spinner } from "react-bootstrap";

const LiftimePopup = () => {
  const dispatch = useDispatch();
  const [modalShown, setModalShown] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const closeModal = useRef();

  const { SubscribeSuccess } = useSelector((state) => state.home);

  const handleClose = () => {
    setModalShown(false);
    localStorage.setItem("lastCloseTimestamp", Date.now());
  };
  useOnClickOutside(closeModal, () => handleClose());

  const [errors_subsemail, setErrors_subsemail] = useState({});
  const handleSubmitSubsemail = (e) => {
    e.preventDefault();
    setIsLoading(true);
    var errors = {};
    const emailInput = e.target.elements.email;
    // const nameInput = e.target.elements.name;
    const emailRegex =
      /^(|(([A-Za-z0-9]+_+)|([A-Za-z0-9]+\-+)|([A-Za-z0-9]+\.+)|([A-Za-z0-9]+\++))*[A-Za-z0-9]+@((\w+\-+)|(\w+\.))*\w{1,63}\.[a-zA-Z]{2,6})$/i;
    if (!emailInput.value || !emailRegex.test(emailInput.value)) {
      errors = { ...errors, email: "Please enter a valid email!" };
      setIsLoading(false);
      emailInput.focus();
    }
    if (Object.keys(errors).length === 0) {
      const formData = new FormData(e.target);
      dispatch(checkSubscribe(formData));
      setErrors_subsemail({});
      setIsLoading(false);
    } else {
      setErrors_subsemail(errors);
      setIsLoading(false);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (SubscribeSuccess) {
      handleClose();
    }
  }, [SubscribeSuccess]);

  useEffect(() => {
    const lastCloseTimestamp = localStorage.getItem("lastCloseTimestamp");
    const sevenDaysInMillis = 7 * 24 * 60 * 60 * 1000;

    if (
      !lastCloseTimestamp ||
      Date.now() - lastCloseTimestamp > sevenDaysInMillis
    ) {
      setModalShown(true);
    }
  }, []);

  return (
    <>
      {modalShown && (
        <div className="lifTimePopup-container">
          <div
            ref={closeModal}
            className={`popup_main_bg position-relative ${
              modalShown ? "visibleLiftTimePopup" : "hiddenLiftTimePopup"
            }`}
          >
            <div className="popup_bg">
              <div className="popup_content">
                <h1 className="popup_title">Want ACCESS TO EXCLUSIVE DEALS?</h1>
                <p className="popup_sub_title">
                  Sign up to recive access to our latest updates <br /> and best
                  offer
                </p>
                <form
                  onSubmit={(e) => handleSubmitSubsemail(e)}
                  className="w-100 popup_form"
                >
                  <input
                    type="text"
                    name="name"
                    placeholder="Name"
                    onChange={(e) => setErrors_subsemail({})}
                    className={`popup_input`}
                  />
                  <input
                    type="email"
                    name="email"
                    placeholder="Email"
                    onChange={(e) => setErrors_subsemail({})}
                    className={`${
                      errors_subsemail.email
                        ? "popup_input_error"
                        : "popup_input"
                    }`}
                  />

                  <button
                    type="submit"
                    disabled={isLoading}
                    className="popup_submit_btn"
                  >
                    SUBSCRIBE{" "}
                    {isLoading && (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        style={{ width: "12px", height: "12px" }}
                        role="status"
                        aria-hidden="true"
                        className="ms-2"
                      />
                    )}
                  </button>
                  <img width={231} height={51} src={logo} alt="logo" />
                </form>
              </div>
            </div>

            <button
              onClick={() => handleClose(false)}
              className="popup_close_btn"
            >
              <img src={close} alt="" />
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default LiftimePopup;
