import React, {useState, useEffect} from "react";
import {loadStripe} from "@stripe/stripe-js";
import {Elements} from "@stripe/react-stripe-js";
import StripePaymentForm from "./StripePaymentForm";
import {
    handleFetchPublishableKey,
    handleCreatePaymentIntent,
} from "../lib/stripe-helpers";

/**
 * Payment form container handles getting client secret and publishable key
 * using rest api.
 *
 * @component
 * @returns {JSX.Element} The rendered React component.
 */
const StripePaymentContainer = ({
                                    checkoutAmount,
                                    shippingId,
                                    expressIds,
                                    giftIds,
                                    guestAddress
                                }) => {
    const [stripePromise, setStripePromise] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [clientSecret, setClientSecret] = useState(null);
    const [elementsOptions, setElementsOptions] = useState(undefined);

    useEffect(() => {
        setIsLoading(true);

        try {
            const fetchPublishableKey = async () => {
                const publishableKey = await handleFetchPublishableKey();
                const stripe_promise = loadStripe(publishableKey);

                setStripePromise(stripe_promise);

                typeof publishableKey === "string" &&
                window.localStorage.setItem("publishable_key", publishableKey);
            };

            const fetchPaymentIntent = async () => {
                const {client_secret, slogan_address} =
                    await handleCreatePaymentIntent(checkoutAmount);
                setClientSecret(client_secret);
                setElementsOptions({
                    clientSecret: client_secret,
                });
                localStorage.setItem("identifier", slogan_address);

                client_secret &&
                window.localStorage.setItem("client_secret", client_secret);
            };

            const localStoragePublishableKey =
                window.localStorage.getItem("publishable_key");
            const localStorageClientSecret =
                window.localStorage.getItem("client_secret");

            if (!localStoragePublishableKey || !localStorageClientSecret) {
                fetchPublishableKey();
                fetchPaymentIntent();
            } else {
                // @ts-ignore
                const stripe_promise = loadStripe(localStoragePublishableKey);
                setStripePromise(stripe_promise);
                setClientSecret(localStorageClientSecret);
                setElementsOptions({
                    clientSecret: localStorageClientSecret,
                });
            }
            localStorage.setItem("checkout_amount", checkoutAmount);
        } catch (error) {
            console.error(error);
            setError(error.message);
        }

        setIsLoading(false);
    }, []);

    return (
        <div className="">
            <div className="">
                {/* Payment form */}
                {clientSecret && stripePromise && !isLoading && (
                    <Elements stripe={stripePromise} options={elementsOptions}>
                        <StripePaymentForm
                            guestAddress={guestAddress}
                            checkoutAmount={checkoutAmount}
                            shippingId={shippingId}
                            expressIds={expressIds}
                            giftIds={giftIds}
                        />
                    </Elements>
                )}
                <span>{error}</span>
            </div>
        </div>
    );
};

export default StripePaymentContainer;
