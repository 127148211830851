import "../../App.css";
import "spiketip-tooltip/spiketip.min.css";
import React, {useEffect, useState} from "react";
import {images} from "../../utils/images";
import {Link, useLocation, useParams} from "react-router-dom";
import SearchModal from "../General/SearchModal";
import {useOnClickOutside} from "../../utils/customHooks";
import {useRef} from "react";
import {useDispatch, useSelector} from "react-redux";
import {FaRegHeart, FaSignOutAlt, FaStore, FaSignInAlt, FaRegUserCircle} from "react-icons/fa";
import {
    getProductByCategory,
    getproductList,
    // productActions,
} from "../../store/product";

// const data = [{ name: "apple" }, { name: "peas" }, { name: "mango" }];
const MobileHeader = ({
                          headerCategories,
                          setSearchTerm,
                          searchTerm,
                          handleSearch,
                          ...otherProps
                      }) => {
    //to reduce size of props
    const {showModal, setShowModal} = otherProps;
    // const [icon, seticon] = useState(true);
    const headerData = useSelector((state) => state.home.headerData);
    const tempCartCount = useSelector((state) => state.home.tempCartCount);
    const wishlists = useSelector((state) => state.prodLi.wishList);

    const {userData} = useSelector((state) => state.auth);

    let userDetails = JSON.parse(localStorage.getItem("userDetails"));
    let user_id = userDetails?.ID;

    const ref = useRef();
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [openReplyMenuIndex, setOpenReplyMenuIndex] = useState(-1);

    const location = useLocation();
    const dispatch = useDispatch();

    useEffect(() => {
        setSidebarOpen(false);
        setOpenReplyMenuIndex(-1);
    }, [location]);

    useOnClickOutside(ref, () => setSidebarOpen(false));
    const [iconStates, setIconStates] = useState({});
    const handleReplyClick = (index) => {
        setIconStates((prevStates) => ({
            ...prevStates,
            [index]: !prevStates[index], // Toggle the icon state for the clicked category
        }));
        if (openReplyMenuIndex === index) {
            setOpenReplyMenuIndex(-1);
        } else {
            setOpenReplyMenuIndex(index);
        }
    };

    const handleLogout = () => {
        const protectedRoutes = ['/login', '/register', '/profile', '/address', '/orders', '/checkout', '/wishlist', '/cart'];
        localStorage.removeItem("userDetails");
        if (protectedRoutes.includes(location.pathname)) {
            window.location.replace('/');
            return
        }
        window.location.reload();
    };

    return (
        <div className="container">
            <div className="small-header align-items-center">
                <button
                    className="header-icon"
                    onClick={(e) => setShowModal((prev) => !prev)}
                >
                    <i className="fa fa-search"/>
                </button>
                <Link to={"/"}>
                    <img width={160} height={35} src={images["logo.webp"]} alt=""/>
                </Link>
                <div className="navLine d-flex align-items-center gap-3 gap-sm-4 ">
                    <Link
                        to="/cart"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#offcanvasNavbar"
                        aria-controls="offcanvasNavbar"
                        className="cart"
                        spiketip-title="🛒 View Cart!"
                        spiketip-pos="bottom"
                        spiketip-color="success"
                    >
                        {/* <i className="fa fa-shopping-cart" aria-hidden="true"></i> */}
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="#f6a92c"
                            color="#f6a92c"
                            className="text-theme"
                        >
                            <g clipPath="url(#clip0_27_24)">
                                <path
                                    d="M4 16V4H2V2H5C5.26522 2 5.51957 2.10536 5.70711 2.29289C5.89464 2.48043 6 2.73478 6 3V15H18.438L20.438 7H8V5H21.72C21.872 5 22.022 5.03466 22.1586 5.10134C22.2952 5.16801 22.4148 5.26495 22.5083 5.38479C22.6019 5.50462 22.6668 5.6442 22.6983 5.79291C22.7298 5.94162 22.7269 6.09555 22.69 6.243L20.19 16.243C20.1358 16.4592 20.011 16.6512 19.8352 16.7883C19.6595 16.9255 19.4429 17 19.22 17H5C4.73478 17 4.48043 16.8946 4.29289 16.7071C4.10536 16.5196 4 16.2652 4 16ZM6 23C5.46957 23 4.96086 22.7893 4.58579 22.4142C4.21071 22.0391 4 21.5304 4 21C4 20.4696 4.21071 19.9609 4.58579 19.5858C4.96086 19.2107 5.46957 19 6 19C6.53043 19 7.03914 19.2107 7.41421 19.5858C7.78929 19.9609 8 20.4696 8 21C8 21.5304 7.78929 22.0391 7.41421 22.4142C7.03914 22.7893 6.53043 23 6 23ZM18 23C17.4696 23 16.9609 22.7893 16.5858 22.4142C16.2107 22.0391 16 21.5304 16 21C16 20.4696 16.2107 19.9609 16.5858 19.5858C16.9609 19.2107 17.4696 19 18 19C18.5304 19 19.0391 19.2107 19.4142 19.5858C19.7893 19.9609 20 20.4696 20 21C20 21.5304 19.7893 22.0391 19.4142 22.4142C19.0391 22.7893 18.5304 23 18 23Z"
                                    fill="#f6a92c"
                                />
                            </g>
                            <defs>
                                <clipPath id="clip0_27_24">
                                    <rect width="24" height="24" fill="white"/>
                                </clipPath>
                            </defs>
                        </svg>
                        {user_id ? (
                            <span>
                {headerData && headerData.cart_count > 0
                    ? headerData.cart_count
                    : 0}
              </span>
                        ) : (
                            <span>{tempCartCount}</span>
                        )}
                    </Link>
                    <button
                        className="header-icon"
                        onClick={(e) => setSidebarOpen((prev) => !prev)}
                    >
                        <i className="fa fa-bars"/>
                    </button>
                    <div
                        ref={ref}
                        className="drawer"
                        style={{
                            transform: `translateX(${sidebarOpen ? "0%" : "100%"})`,
                        }}
                    >
                        <button
                            className="svg-icon"
                            onClick={(e) => setSidebarOpen((prev) => !prev)}
                        >
                            <img src={images["x.svg"]} alt=""/>
                        </button>
                        <h2 className="mobile-heading">Categories</h2>
                        <ul className="drawer-list bg-white" style={{zIndex: 500}}>
                            {headerCategories &&
                                headerCategories?.map((item, index) => {
                                    return (
                                        <li onClick={(e) => handleReplyClick(index)} key={index}>
                                            <Link
                                                to={`/${item?.categories?.slug}`}
                                                onClick={() =>
                                                    dispatch(getProductByCategory(item?.categories?.slug))
                                                }
                                            >
                                                {item?.categories?.cate_name}
                                            </Link>
                                            <img
                                                src={
                                                    iconStates[index]
                                                        ? images["chevron-up.svg"]
                                                        : images["chevron-down.svg"]
                                                }
                                                className=" ms-1 mt-1"
                                                title=""
                                                alt=""
                                                width="13px"
                                                style={{float: "right"}}

                                                // style={{ width: "192px; height:42px" }}
                                            />
                                            <ul
                                                className="subcategory"
                                                style={{
                                                    height: `${
                                                        openReplyMenuIndex === index ? "auto" : "0px"
                                                    }`,
                                                }}
                                            >
                                                {item?.subcategories &&
                                                    item?.subcategories?.map((subCategory, index) => {
                                                        return (
                                                            <li key={index}>
                                                                <Link
                                                                    to={`/${item?.categories?.slug}/${subCategory?.sub_cate_slug}`}
                                                                    onClick={(e) => {
                                                                        setSidebarOpen(false);
                                                                        dispatch(
                                                                            getproductList(subCategory.sub_cate_slug)
                                                                        );
                                                                    }}
                                                                >
                                                                    {subCategory.subcate_name}
                                                                </Link>
                                                            </li>
                                                        );
                                                    })}
                                            </ul>
                                        </li>
                                    );
                                })}
                            <li>
                                <Link to={process.env.REACT_APP_JOIN_URL} target="_blank">
                                    <FaStore className="slide-icons"/>Sell Your Product
                                </Link>
                            </li>
                            <li>
                                <Link to='/wishlist' target="_blank" className="d-flex align-items-center">
                                    <FaRegHeart className="slide-icons"/>Wish List
                                    <span className="badge-b">
                                    {wishlists && wishlists?.total_products > 0 ? wishlists.total_products : 0}
                                </span>
                                </Link>
                            </li>

                            {userData ? (
                                <>
                                    <li>
                                        <Link to="/profile">
                                            <FaRegUserCircle className="slide-icons"/>Profile</Link>
                                    </li>
                                    <li>
                                        <Link
                                            className="dropdown-item text-danger"
                                            to="/"
                                            onClick={handleLogout}
                                        >
                                            <FaSignOutAlt className="slide-icons"/>Logout
                                        </Link>
                                    </li>
                                </>
                            ) : (
                                <li>
                                    <Link to={"/login"}><FaSignInAlt className="slide-icons"/>Sign In</Link>
                                </li>
                            )}
                        </ul>
                    </div>
                </div>
            </div>
            {showModal && (
                <SearchModal
                    setShowModal={setShowModal}
                    setSearchTerm={setSearchTerm}
                    searchTerm={searchTerm}
                    handleSearch={handleSearch}
                />
            )}
        </div>
    );
};

export default MobileHeader;
