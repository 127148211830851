import {useEffect, useState} from "react"
import {Link, useSearchParams} from "react-router-dom"
import {handleFinalizeOrder} from "../../lib/stripe-helpers";
import {getCartData} from "../../store/newCart";
import {useDispatch, useSelector} from "react-redux";
import {getHeaderData} from "../../store/home";
import Loader from "../../Loader";

const ThankYou = () => {
    return (
        <div className='mt-4 d-flex justify-content-center align-items-center'>
            <div className=' col-md-4 bg-white p-5'>
                <div className='mb-4 text-center'>
                    <svg
                        xmlns='http://www.w3.org/2000/svg'
                        className='bi bi-check-circle'
                        width='75'
                        height='75'
                        fill='currentColor'
                        viewBox='0 0 16 16'
                        style={{color: "#4BB543"}}>
                        <path d='M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z'/>
                        <path
                            d='M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z'/>
                    </svg>
                </div>
                <div className='text-center'>
                    <h1>Thank You !</h1>
                    <p>
                        Your order has been confirmed, we will send you confirmation email
                        shortly!
                    </p>
                    <Link to='/' className=''>
                        <button className='mt-4 btn  bg-theme-blue text-white'>
                            Back Home
                        </button>
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default ThankYou
