import React, {useEffect, useState} from "react";
import {Circles} from "react-loader-spinner";
import {useDispatch, useSelector} from "react-redux";
import {getStatesList} from "../../store/home";

const staticCountries = [
    {country_name: 'United States', id: 2},
    {country_name: 'Canada', id: 9}
]

const GuestAddressInput = ({register, errors, countries, setValue, watch, address}) => {
    const states_list = useSelector((state) => state.home.states_list);
    const dispatch = useDispatch();

    const validateEmailMatch = (value) => {
        const email = watch('email');
        return value === email || "The emails do not match";
    };

    const [countryId, setCountryId] = useState()
    useEffect(() => {
        let guestAddressDetails = sessionStorage.getItem('guestUserAddress') || '';
        if (guestAddressDetails) guestAddressDetails = JSON.parse(guestAddressDetails);
        if (countryId) {
            sessionStorage.setItem('guestUserAddress', JSON.stringify({...guestAddressDetails, countryId}));
            setValue('countryId', countryId);
        }
    }, [countryId]);
    return (
        <>
            <div className="row mt-2" style={{marginBottom: "6px"}}>
                <div className="guest_address_input_container col-12 col-md-6">
                    <label>Full Name</label>
                    <input
                        {...register("name", {
                            required: 'Full name is required'
                        })}
                        type="text"
                        defaultValue={address?.name}
                        placeholder="Full Name"
                        className={`guest_address_input ${
                            errors.name && "guest_address_input_error"
                        }`}
                    />
                    {errors.name && (
                        <i className="guest_address_input_error_message">
                            {errors.name.message}
                        </i>
                    )}
                </div>
            </div>

            {countries?.length > 0 ? <div
                className="guest_address_input_container"
                style={{marginBottom: "6px"}}
            >
                <label>Country</label>
                <select
                    {...register("country", {required: "Country is Required"})}
                    onChange={e => {
                        const selectElement = e.target;
                        const selectedIndex = selectElement.selectedIndex;
                        const selectedOption = selectElement.options[selectedIndex];
                        const id = selectedOption.getAttribute("data-id");
                        dispatch(getStatesList(id))
                        setCountryId(id)
                        register("country").onChange(e);
                        setValue("state", "")
                    }}
                    className={`guest_address_input ${
                        errors.country && "guest_address_input_error"
                    }`}
                >
                    <option value="">Select Country</option>
                    <option disabled value="">-----------------</option>
                    {staticCountries.map((staticCountry) => (
                        <option
                            key={staticCountry.id}
                            data-id={staticCountry.id}
                            selected={staticCountry.country_name === address?.country}
                            value={staticCountry.country_name}
                        >
                            {staticCountry.country_name}
                        </option>
                    ))}
                    <option disabled value="">-----------------</option>
                    {countries.map((country) => {
                        return <option
                            key={country.id}
                            data-id={country.id}
                            selected={country.country_name === address?.country}
                            value={country.country_name}
                        >
                            {country.country_name}
                        </option>
                    })}
                </select>
                {errors.country && (
                    <i className="guest_address_input_error_message">
                        {errors.country.message}
                    </i>
                )}
            </div> : <div className="my-3"><Circles type="Circles" color="#f6a92c" height={30} width={30}/></div>}

            <div
                className="guest_address_input_container"
                style={{marginBottom: "6px"}}
            >
                <label>Street Address</label>
                <input
                    {...register("street_address", {
                        required: "Street Address is required",
                    })}
                    defaultValue={address?.street_address}
                    type="text"
                    placeholder="Street Address"
                    className={`guest_address_input ${
                        errors.street_address && "guest_address_input_error"
                    }`}
                />
                {errors.street_address && (
                    <i className="guest_address_input_error_message">
                        {errors.street_address?.message}
                    </i>
                )}
            </div>

            <div
                className="guest_address_input_container"
                style={{marginBottom: "6px"}}
            >
                <label>City</label>
                <input
                    {...register("city", {
                        required: "City is required",
                    })}
                    defaultValue={address?.city}
                    type="text"
                    placeholder="City"
                    className={`guest_address_input ${
                        errors.city && "guest_address_input_error"
                    }`}
                />
                {errors.city && (
                    <i className="guest_address_input_error_message">
                        {errors.city.message}
                    </i>
                )}
            </div>

            <div className="row">
                <div
                    className="guest_address_input_container col-12 col-md-6"
                    style={{marginBottom: "6px"}}
                >
                    <label>State</label>
                    {states_list.length ? <select
                        {...register("state", {
                            required: "State is required",
                        })}
                        className={`guest_address_input ${
                            errors.country && "guest_address_input_error"
                        }`}
                    >
                        <option value="">Select State</option>
                        {states_list.map((state) => {
                            return <option
                                key={state.id}
                                selected={state.state_name === address?.state}
                                data-id={state.id}
                                value={state?.state_name}
                            >
                                {state?.state_name || 'Select State'}
                            </option>
                        })}
                    </select> : <input
                        {...register("state", {
                            required: "State is required",
                        })}
                        type="text"
                        defaultValue={address?.state}
                        placeholder="State"
                        className={`guest_address_input ${
                            errors.state && "guest_address_input_error"
                        }`}
                    />}
                    {errors.state && (
                        <i className="guest_address_input_error_message">
                            {errors.state.message}
                        </i>
                    )}
                </div>

                <div
                    className="guest_address_input_container col-12 col-md-6"
                    style={{marginBottom: "6px"}}
                >
                    <label>Post Code</label>
                    <input
                        {...register("postcode", {
                            required: "Post Code is required",
                        })}
                        defaultValue={address?.postcode}
                        type="text"
                        placeholder="Post Code"
                        className={`guest_address_input ${
                            errors.postcode && "guest_address_input_error"
                        }`}
                    />
                    {errors.postcode && (
                        <i className="guest_address_input_error_message">
                            {errors.postcode.message}
                        </i>
                    )}
                </div>
            </div>

            <div
                className="guest_address_input_container"
                style={{marginBottom: "6px"}}
            >
                <label>Phone Number</label>
                <input
                    {...register("phone")}
                    defaultValue={address?.phone}
                    type="text"
                    placeholder="Phone Number"
                    className={`guest_address_input ${
                        errors.phone && "guest_address_input_error"
                    }`}
                />
                {errors.phone && (
                    <i className="guest_address_input_error_message">
                        {errors.phone.message}
                    </i>
                )}
            </div>

            <div className="row">
                <div
                    className="guest_address_input_container col-12" style={{marginBottom: '10px'}}
                >
                    <label>Email</label>
                    <input
                        {...register("email", {
                            required: "Email is required",
                            pattern: {
                                value: '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$',
                                message: "Please enter a valid email address",
                            }
                        })}
                        defaultValue={address?.email}
                        type="email"
                        placeholder="Email Address"
                        className={`guest_address_input ${
                            errors.email && "guest_address_input_error"
                        }`}
                    />
                    {errors.email && (
                        <i className="guest_address_input_error_message">
                            {errors.email.message}
                        </i>
                    )}
                </div>
                {/*<div*/}
                {/*    className="guest_address_input_container col-12"*/}
                {/*>*/}
                {/*    <label>Confirm Email</label>*/}
                {/*    <input*/}
                {/*        {...register("confirmEmail", {*/}
                {/*            required: "Email is required",*/}
                {/*            pattern: {*/}
                {/*                value: '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$',*/}
                {/*                message: "Please enter a valid email address",*/}
                {/*            },*/}
                {/*            validate: validateEmailMatch*/}
                {/*        })}*/}
                {/*        defaultValue={address?.confirmEmail}*/}
                {/*        type="email"*/}
                {/*        placeholder="Confirm Email Address"*/}
                {/*        className={`guest_address_input ${*/}
                {/*            errors.confirmEmail && "guest_address_input_error"*/}
                {/*        }`}*/}
                {/*    />*/}
                {/*    {errors.confirmEmail && (*/}
                {/*        <i className="guest_address_input_error_message">*/}
                {/*            {errors.confirmEmail.message}*/}
                {/*        </i>*/}
                {/*    )}*/}
                {/*</div>*/}
            </div>
        </>
    );
};

export default GuestAddressInput;
