import React, {useEffect} from "react";
import {Swiper, SwiperSlide} from "swiper/react";
import {Link, useNavigate} from "react-router-dom";
import {Autoplay} from "swiper";
import {useDispatch, useSelector} from "react-redux";
import {get_meta} from "../../store/home";
import {Helmet} from "react-helmet";
import SiteLoader from "../../SiteLoader";
import defaultImg from "../../components/img/default.png";
import {images} from "../../utils/images";
import {productActions} from "../../store/product";
import BannerCarousel from "./components/BannerCarousel";
import LifetimePopup from "./components/LiftimePopup";
import Testimonials from "./components/Testimonials";
import HomeBlog from "./components/HomeBlog";
import "../../styles/home.module.css";
import Video from "./components/Video";
import {removeTags} from "../../product/ProductByCategory";
import SalesSection from "./components/SalesSection";

const Home = () => {
    const dispatch = useDispatch();

    const state = useSelector((state) => state.home);
    const homerec = useSelector((state) => state.home.home_array);

    const Banner = homerec?.banner;

    // EDITORPIC

    // const homepageData = useSelector((state) => state.home.homepage_data);

    const cate1 = "Beads"; //useSelector(state => state.home.editorpics_category_one);
    const cate2 = "Fashion Jewellery"; //useSelector(state => state.home.editorpics_category_two);
    const cate3 = "Fine Jwellery"; //useSelector(state => state.home.editorpics_category_three);
    const cate4 = "Gemstones"; //useSelector(state => state.home.editorpics_category_four);
    //'{"foo": 01}'

    const Bestsellers = homerec?.featured_products;
    // SERVICES
    // FRIENDLIST MARCKETPLACE
    const Services = homerec?.services;
    // POPULAR PRODUCT
    const Populars = homerec?.popular_products;

    const abouts = homerec?.about;
    // META TAGS START
    useEffect(() => {
        dispatch(get_meta("home-page"));
    }, ["home-page"]);

    const metaTags = state.allmeta;
    // const metaTags = useSelector((state) => state.contact.data.meta_data);
    const siteUrl = window.location.href;
    // META TAGS END
    let imgurL = "";
    if (
        homerec &&
        homerec.services &&
        homerec.services["0"] &&
        homerec.services["0"].image
    ) {
        imgurL = homerec.services["0"].image;
    } else {
    }
    // scroll top functionality start
    // scroll top functionality end
    const navigate = useNavigate();
    const bannerContent = homerec?.welcome_text?.length && homerec?.welcome_text[0];
    return (
        <>
            {state.loaderStatus ? (
                <SiteLoader status={state.loaderStatus}/>
            ) : (
                <>
                    <LifetimePopup/>
                    <div className="position-relative">
                        {Banner && <BannerCarousel images={Banner}/>}
                        <div className="swiper-caption banner-overly banner-align">
                            <div className="caption-content content-size">
                                <h2
                                    className="slide-head text-theme font-theme text-wrap"
                                    style={{fontSize: "18px"}}
                                >
                                    {bannerContent?.heading}
                                </h2>
                                <h1 className="slide-head text-white font-theme text-wrap">
                                    {bannerContent?.content}
                                </h1>
                                <Link to="/categories" className="shop-now shop_now_btn">
                                    Shop Now
                                </Link>
                            </div>
                        </div>
                    </div>
                    {/* <!-- editors pic  --> */}
                    <>
                        <div className="container mx-auto">
                            <section className="editorsPic pb-5">
                                <div className="row">
                                    <div className="flex-between-header col-md-12 pt-0">
                                        <div className="flex-between w-100 view-container">
                                            <div className="ms-3">
                                                <h3 className="wow animated fadeInUp editorsPic">
                                                    OUR CATEGORIES
                                                </h3>
                                                <span className="wow-text ">
                          Elevate Your Style with Art and Jewelry Unveiled
                        </span>
                                            </div>
                                            <div className="view-all d-flex justify-content-end w-100">
                                                <Link
                                                    to={"/categories"}
                                                    className="d-flex align-items-center"
                                                >
                                                    <span>View All</span>
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="17"
                                                        height="17"
                                                        viewBox="0 0 17 17"
                                                        fill="none"
                                                    >
                                                        <g clipPath="url(#clip0_42_70)">
                                                            <path
                                                                d="M11.4551 7.79165L7.65565 3.99215L8.65723 2.99057L14.1666 8.49999L8.65723 14.0094L7.65565 13.0078L11.4551 9.20832H2.83331V7.79165H11.4551Z"
                                                                fill="black"
                                                            />
                                                        </g>
                                                        <defs>
                                                            <clipPath id="clip0_42_70">
                                                                <rect width="17" height="17" fill="white"/>
                                                            </clipPath>
                                                        </defs>
                                                    </svg>
                                                    {/* <i className="fa fa-arrow-right "></i> */}
                                                </Link>
                                            </div>
                                            <Helmet>
                                                {metaTags?.meta_data?.page_schema && (
                                                    <script
                                                        type="application/ld+json">{`${metaTags?.meta_data?.page_schema}`}</script>
                                                )}
                                                <meta charSet="utf-8"/>
                                                <link rel="canonical" href={siteUrl}/>
                                                <title>{metaTags?.meta_data?.meta_title}</title>
                                                <meta
                                                    name="description"
                                                    content={metaTags?.meta_data?.meta_description}
                                                />
                                                <meta
                                                    name="keywords"
                                                    content={metaTags?.meta_data?.meta_keywords}
                                                />
                                                <meta
                                                    name="twitter:card"
                                                    content="summary_large_image"
                                                />
                                                <meta name="twitter:url" content={siteUrl}/>

                                                <meta
                                                    name="twitter:title"
                                                    content={metaTags?.meta_data?.og_title}
                                                />
                                                <meta
                                                    name="twitter:description"
                                                    content={metaTags?.meta_data?.og_description}
                                                />

                                                <meta
                                                    name="twitter:image"
                                                    content={metaTags?.meta_data?.og_image}
                                                />

                                                <meta
                                                    property="og:type"
                                                    content={metaTags?.meta_data?.og_type}
                                                />
                                                <meta property="og:url" content={siteUrl}/>
                                                <meta
                                                    property="og:title"
                                                    content={metaTags?.meta_data?.og_title}
                                                />
                                                <meta
                                                    property="og:description"
                                                    content={metaTags?.meta_data?.og_description}
                                                />
                                                <meta
                                                    property="og:image"
                                                    content={metaTags?.meta_data?.og_image}
                                                />
                                            </Helmet>
                                        </div>
                                    </div>
                                    <div className="row gutter mx-auto">
                                        <div className="col-md-6 col-sm-6">
                                            <Link
                                                to="/beads"
                                                onClick={() =>
                                                    dispatch(productActions.setFetchPermit(false))
                                                }
                                                className="flex-colmn mx-100 wow animated fadeInUp"
                                            >
                                                <img
                                                    src={images["img1.jpg"]}
                                                    style={{maxHeight: "423px"}}
                                                    className="category-img"
                                                    title=""
                                                    alt={cate1}
                                                />
                                                <p className="overlay-heading">{cate1}</p>
                                            </Link>
                                        </div>
                                        <div className="col-md-3 col-sm-6">
                                            <Link
                                                onClick={() =>
                                                    dispatch(productActions.setFetchPermit(false))
                                                }
                                                to="/fashion-jewelry"
                                                className="flex-colmn wow animated fadeInUp"
                                            >
                                                <img
                                                    src={images["img2.jpg"]}
                                                    className="category-img"
                                                    title=""
                                                    alt=""
                                                />
                                                <p className="overlay-heading">{cate2}</p>
                                            </Link>
                                        </div>
                                        <div className="col-md-3 col-sm-12">
                                            <div className="row sm-col">
                                                <div className="col-sm-6 col-md-12">
                                                    <Link
                                                        onClick={() =>
                                                            dispatch(productActions.setFetchPermit(false))
                                                        }
                                                        to="/fine-jewelry"
                                                        className="flexrow wow animated fadeInUp rm-2"
                                                    >
                                                        <img
                                                            src={images["img3.png"]}
                                                            className="category-img"
                                                            title=""
                                                            alt=""
                                                        />
                                                        <p className="overlay-heading">{cate3}</p>
                                                    </Link>
                                                </div>
                                                <div className="col-sm-6 col-md-12">
                                                    <Link
                                                        onClick={() =>
                                                            dispatch(productActions.setFetchPermit(false))
                                                        }
                                                        to="/gemstones"
                                                        className="flexrow wow animated fadeInUp"
                                                    >
                                                        <img
                                                            src={images["img4.jpg"]}
                                                            className="category-img"
                                                            title=""
                                                            alt=""
                                                        />
                                                        <p className="overlay-heading">{cate4}</p>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                        {/* //  end editors pic */}

                        {/* BESTSELLER PRODUCT  */}

                        {/* <section className="section-wrapper featured"> */}
                        {/*<SalesSection />*/}
                        <section className="section-wrapper featured bg-light"
                                 style={{marginTop: "50px", paddingTop: "20px"}}>
                            <div className="container">
                                <div className="wrapper-header text-center mb-4">
                                    <h2
                                        className="wow animated fadeInUp bestsellerproduct"
                                        data-wow-delay="0.3s"
                                    >
                                        Popular Products
                                    </h2>
                                </div>

                                {/* <!-- product  --> */}
                                {/* <!-- Swiper --> */}
                                <Swiper
                                    className="w-100"
                                    breakpoints={{
                                        320: {
                                            slidesPerView: 1,
                                        },
                                        400: {
                                            slidesPerView: 2,
                                        },
                                        600: {
                                            slidesPerView: 2,
                                        },
                                        768: {
                                            slidesPerView: 3,
                                        },
                                        // when window width is >= 768p
                                        // when window width is >= 992px
                                        992: {
                                            slidesPerView: 4,
                                        },
                                    }}
                                    spaceBetween={15}
                                    slidesPerView={5}
                                    freeMode={true}
                                    modules={[Autoplay]}
                                    autoplay={{
                                        delay: 2000,
                                        disableOnInteraction: false,
                                    }}
                                    speed={1000}
                                    watchSlidesProgress={true}
                                >
                                    {Populars?.map((Bestseller, index) => {
                                        return (
                                            <SwiperSlide key={index}>
                                                <div className="swiper popularProduct">
                                                    <div className="swiper-wrapper">
                                                        <div className="swiper-slide">
                                                            <div className="mb-4 bg-white">
                                                                <Link
                                                                    to={`/shop/${Bestseller.pro_slug}-${Bestseller?.id}`}

                                                                    className=""
                                                                    key={index}
                                                                >
                                                                    <div className="">
                                                                        <div
                                                                            className="image-container">
                                                                            <img
                                                                                src={Bestseller.main_image}
                                                                                loading='lazy'
                                                                                className="carousel-size"
                                                                                title=""
                                                                                alt=""
                                                                                onError={(e) =>
                                                                                    (e.target.src = defaultImg)
                                                                                }
                                                                            />
                                                                        </div>
                                                                        <div
                                                                            className="product-content home-page-card px-1"
                                                                            style={{
                                                                                padding: "20px 0",
                                                                                height: "fit-content",
                                                                            }}
                                                                        >
                                                                            {Bestseller.pro_name?.length > 22 ? (
                                                                                <p
                                                                                    spiketip-title={Bestseller.pro_name?.slice(0, 120)}
                                                                                    spiketip-pos="bottom-left"
                                                                                    spiketip-color="black"
                                                                                    // spiketip-length="xl"
                                                                                >
                                                                                    {Bestseller.pro_name?.slice(0, 22)}
                                                                                    <small className=""
                                                                                           style={{fontSize: "12px"}}>
                                                                                        ...
                                                                                    </small>
                                                                                </p>
                                                                            ) : (
                                                                                Bestseller?.pro_name
                                                                            )}
                                                                            <div
                                                                                className="categoryCartDes mt-1 des-container text-wrap"
                                                                                style={{maxHeight: "fit-content"}}
                                                                            >
                                                                                <small
                                                                                    className="oneLine"
                                                                                    style={{
                                                                                        color: "#737373",
                                                                                        fontFamily: "Montserrat",
                                                                                        fontWeight: "400",
                                                                                    }}
                                                                                >
                                                                                    {Bestseller.short_desc.length > 25
                                                                                        ? Bestseller.short_desc.slice(
                                                                                        0,
                                                                                        25
                                                                                    ) + "..."
                                                                                        : Bestseller.short_desc}
                                                                                </small>
                                                                            </div>
                                                                            <div className="price py-1">
                                                                                <div
                                                                                    className="grey-text"
                                                                                    style={{fontFamily: "Montserrat"}}
                                                                                >
                                                                                    {!Bestseller?.sale_price && <span
                                                                                        className="text-warning ms-1">
                                                                                      $
                                                                                        {Bestseller &&
                                                                                            Bestseller.regular_price}
                                                                                    </span>}

                                                                                    {Bestseller?.sale_price && <div
                                                                                        className='d-flex justify-content-center align-items-center gap-2'>
                                                                                        <p className="text-warning">
                                                                                            ${Bestseller && Bestseller.sale_price}
                                                                                        </p>
                                                                                        <p
                                                                                            className="strike-through">
                                                                                            ${Bestseller && Bestseller.regular_price}
                                                                                        </p>
                                                                                    </div>}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </SwiperSlide>
                                        );
                                    })}
                                </Swiper>
                            </div>
                            <div className='container'>
                                <Video contentHtml={homerec.about?.pagecontent}/>
                            </div>
                            <div className="container">
                                <h4
                                    className="ls_content mx-auto py-5 text-center"
                                    style={{maxWidth: "1000px"}}
                                >
                                    With Mangtum, vendors can swiftly upload thousands of products, eliminating manual
                                    listing and effortlessly managing inventory.
                                </h4>
                            </div>
                        </section>
                        {/* <!-- end  --> */}

                        {/* ABOUT */}
                        <div className="position-relative">
                            <section className="bg-white about-wrapper">
                                <div className="container white-lay">
                                    <div className="">
                                        <div className="row">
                                            <div className="head-wrap-about col-12 col-lg-6 about-section">
                                                <h2 className="home-about-heading mb-2">
                                                    {homerec?.about?.heading}
                                                </h2>
                                                <h3>{homerec?.about?.name}</h3>
                                                <div className="content ls_content p-0">
                                                    <p
                                                        dangerouslySetInnerHTML={{
                                                            __html: homerec?.about?.homecontent,
                                                        }}
                                                    ></p>
                                                </div>
                                                {/* <Link to="/about" className="btn btn-sm btn-warning" >Know More</Link> */}
                                                <Link
                                                    to="/categories"
                                                    className="shop-now"
                                                >
                                                    SHOP NOW
                                                </Link>
                                            </div>
                                            <figure className="col-12 col-md-6 d-flex align-items-center">
                                                <img className="marketPlace_img-1 d-none d-lg-block ms-3" src={homerec?.about?.banner_image} alt="" />
                                            </figure>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>

                        {/* FRIENDLIST MARKETPLACE */}
                        {/* SERVICES */}

                        <>
                            <section className=" marketplace container">
                                {Services?.map((services_data, index) => {
                                    return (
                                        <div className="services" key={index}>
                                            <div
                                                className="d-flex justify-content-center align-items-center gap-5 h-100 w-100 mt-5">
                                                <img
                                                    className="marketPlace_img d-none d-lg-block mr-lg-3"
                                                    src={services_data.image}
                                                    alt=""
                                                />
                                                <div
                                                    className="marketplace-wraps d-flex flex-column align-items-start gap-3 ms-lg-3 text-center text-lg-start py-4 py-lg-0"
                                                    style={{maxWidth: "700px"}}
                                                >
                                                    <h2 className="mb-2 home-about-heading">
                                                        {services_data.heading}
                                                    </h2>
                                                    <h4 className="content mb-2 ls_content p-lg-0">
                                                        <div className="line-clamp-3" dangerouslySetInnerHTML={{__html: services_data.content}} />
                                                        {removeTags(services_data.content).length > 280 && '...'}
                                                    </h4>
                                                    <a
                                                        href={process.env.REACT_APP_JOIN_URL}
                                                        target="_blank"
                                                        className="join-now mx-lg-0"
                                                    >
                                                        Join Now
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        // </div>
                                    );
                                })}
                            </section>
                        </>

                        {/* <!-- POPULAR PRODUCT --> */}
                        <div>
                            <div
                                className=""
                                style={{backgroundColor: "#F8F8F8", padding: "40px 0"}}>
                                <div className="container mx-auto">
                                    <div className="wrapper-header text-center mb-4">
                                        <h3 className="grey-text wow animated fadeInUp featured_product">
                                            Featured Products
                                        </h3>
                                        <h2
                                            className="wow animated fadeInUp bestsellerproduct text-uppercase"
                                            data-wow-delay="0.3s"
                                        >
                                            Bestseller Products
                                        </h2>
                                    </div>

                                    {/* <!-- product  --> */}
                                    <div className="grid-container best-seller-cards">
                                        {Bestsellers?.map((Bestseller, index) => {
                                            return (
                                                <div className="mb-4 bg-white" key={Bestseller.id}>
                                                    <Link
                                                        to={`/shop/${Bestseller.pro_slug}-${Bestseller.id}`}

                                                        className=""
                                                        key={index}
                                                    >
                                                        <div className="">
                                                            <div className="image-container">
                                                                <img
                                                                    src={Bestseller.main_image}
                                                                    className="img-size"
                                                                    title=""
                                                                    alt=""
                                                                    onError={(e) => (e.target.src = defaultImg)}
                                                                />
                                                            </div>
                                                            <div
                                                                className="product-content home-page-card px-1"
                                                                style={{
                                                                    padding: "20px 0",
                                                                    height: "fit-content",
                                                                }}
                                                            >
                                                                {Bestseller.pro_name?.length > 22 ? (
                                                                    <p
                                                                        spiketip-title={Bestseller.pro_name?.slice(0, 120)}
                                                                        spiketip-pos="bottom-left"
                                                                        spiketip-color="black"
                                                                        // spiketip-length="xl"
                                                                    >
                                                                        {Bestseller.pro_name?.slice(0, 22)}
                                                                        <small className="" style={{fontSize: "12px"}}>
                                                                            ...
                                                                        </small>
                                                                    </p>
                                                                ) : (
                                                                    Bestseller?.pro_name
                                                                )}
                                                                <div
                                                                    className="categoryCartDes mt-1 des-container text-wrap">
                                                                    <small
                                                                        className="oneLine"
                                                                        style={{
                                                                            color: "#737373",
                                                                            fontFamily: "Montserrat",
                                                                            fontWeight: "400",
                                                                        }}
                                                                    >
                                                                        {Bestseller.short_desc.length > 25
                                                                            ? Bestseller.short_desc.slice(0, 25) + "..."
                                                                            : Bestseller.short_desc}
                                                                    </small>
                                                                </div>
                                                                <div className="price">
                                                                    <div
                                                                        className="grey-text"
                                                                        style={{fontFamily: "Montserrat"}}
                                                                    >
                                                                        {!Bestseller?.sale_price &&
                                                                            <span className="text-warning">
                                                                        ${Bestseller && Bestseller.regular_price}
                                                                      </span>}

                                                                        {Bestseller?.sale_price && <div
                                                                            className='d-flex justify-content-center align-items-center gap-2'>
                                                                            <p className="text-warning">
                                                                                ${Bestseller && Bestseller.sale_price}
                                                                            </p>
                                                                            <p
                                                                                className="strike-through">
                                                                                ${Bestseller && Bestseller.regular_price}
                                                                            </p>
                                                                        </div>}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>
                                            );
                                        })}
                                    </div>

                                    <Link to="/categories">
                                        <button className="view-more">View More Products</button>
                                    </Link>
                                </div>
                            </div>
                            <div className='container white-lay'>
                                <Testimonials/>
                            </div>
                            <div style={{backgroundColor: "#F8F8F8", padding: "40px 0"}}>
                                <div className='container'>
                                    <HomeBlog/>
                                </div>
                            </div>
                        </div>
                    </>
                </>
            )}
        </>
    );
};

export default Home;
