import {getAuth, signInWithPopup, fetchSignInMethodsForEmail} from "firebase/auth";
import React, {createContext, useState} from "react";
import app from "../Firebase/Firebase.init";

export const AuthContext = createContext();

export function AuthProvider({children}) {
    const [user, setUser] = useState(null);
    const auth = getAuth(app);

    const registerWithGoogle = (provider) => {
        return signInWithPopup(auth, provider);
    };

    const registerWithFacebook = (provider) => {
        return signInWithPopup(auth, provider);
    };

    const registerWithApple = (provider) => {
        return signInWithPopup(auth, provider);
    };

    const getProvidersFromError = (email) => {
        return fetchSignInMethodsForEmail(auth, email);
    };

    // useEffect(() => {
    //   const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
    //     console.log(currentUser);
    //     if (currentUser === null || currentUser.emailVerified) {
    //       setUser(currentUser);
    //     }
    //     setUser(currentUser);
    //   });

    //   return () => {
    //     unsubscribe();
    //   };
    // }, []);

    const contextValue = {
        user,
        setUser,
        registerWithGoogle,
        registerWithFacebook,
        registerWithApple,
        getProvidersFromError
    };

    return (
        <AuthContext.Provider value={contextValue}>
            {children}
        </AuthContext.Provider>
    );
}

export default AuthProvider;
