import React, {useEffect} from "react";
import {Helmet} from "react-helmet";
import {get_meta} from "../../store/home";
import author from "../../assets/brand/mangtum.png";
import BlogAccording from "../../components/blog-ui/BlogAccording";
import {Link, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {getSingleBlog} from "../../store/product";
import BlogCategories from "../../components/blog-ui/BlogCategories";
import RelatedBlogs from "../../components/blog-ui/RelatedBlogs";
import SiteLoader from "../../SiteLoader";
import moment from "moment";

const BlogDetails = () => {
    const {singleBlog, loaderStatus} = useSelector((state) => state.prodLi);
    const {slug} = useParams();
    const stringWithoutHyphenstwo = slug.replace(/-/g, " ");
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getSingleBlog(slug));
    }, [slug]);

    // META TAGS START
    useEffect(() => {
        dispatch(get_meta("blog"));
    }, ["blog"]);
    const state = useSelector((state) => state.home);
    const metaTags = state.allmeta;
    const siteUrl = window.location.href;
    // META TAGS END

    // console.log(singleBlog?.schema);

    return (
        <>
            <Helmet>
                {singleBlog?.schema?.length > 0 &&
                    singleBlog?.schema?.map((item, index) => (
                        <script key={index} type="application/ld+json">
                            {`${item?.schema}`}
                        </script>
                    ))}
                <meta charSet="utf-8"/>
                <title>{singleBlog?.meta_title}</title>
                <meta name="description" content={singleBlog?.meta_description}/>
                <meta name="keywords" content={singleBlog?.meta_tags}/>
                <meta name="twitter:card" content="summary_large_image"/>
                <meta name="twitter:url" content={siteUrl}/>

                <meta name="twitter:title" content={singleBlog?.meta_title}/>
                <meta
                    name="twitter:description"
                    content={singleBlog?.meta_description}
                />
                <meta name="twitter:image" content={singleBlog?.ogimage}/>

                <meta property="og:type" content="article"/>
                <meta property="og:url" content={siteUrl}/>
                <meta property="og:title" content={singleBlog?.meta_title}/>
                <meta
                    property="og:description"
                    content={singleBlog?.meta_description}
                />
                <meta property="og:image" content={singleBlog?.ogimage}/>
            </Helmet>
            {/* <ThemeBreadcrumb title={stringWithoutHyphenstwo} current_route="HomeBlog" /> */}
            {loaderStatus ? (
                <SiteLoader status={loaderStatus}/>
            ) : (
                <main className="container mt-5">
                    <h1 className="blog_banner_title">{singleBlog?.title}</h1>
                    {/* // <main className="container single-product-section pointer my-5"> */}
                    <section className="d-flex justify-content-between gap-2 single-product-section">
                        <div className="flex-row">
                            <section className="rounded">
                                <img
                                    className="mx-auto w-100 rounded"
                                    style={{
                                        maxHeight: "487px",
                                        objectFit: "cover",
                                    }}
                                    src={singleBlog?.coverimage}
                                    alt=""
                                />
                                <div className="mt-4">
                                    {singleBlog?.blog_categories?.length > 0 && (
                                        <div className="d-flex align-items-center flex-wrap gap-3 mb-4">
                                            {singleBlog?.blog_categories?.map((cate, index) => (
                                                <Link to={`/blog?category=${cate?.slug}`}>
                                                    <button key={index} className="blog_cate_btn">
                                                        {cate?.cate_name}
                                                    </button>
                                                </Link>
                                            ))}
                                        </div>
                                    )}

                                    <div className="d-flex align-items-center gap-2 mt-4">
                                        <img
                                            className="rounded-circle"
                                            style={{width: "30px", height: "30px"}}
                                            src={author}
                                            alt=""
                                        />
                                        <p className="blog_author_text">
                                            Mangtum -{" "}
                                            {moment(singleBlog?.created_at).format("MMM DD, YYYY")}
                                        </p>
                                    </div>
                                    <p
                                        className="blog_banner-content"
                                        dangerouslySetInnerHTML={{__html: singleBlog?.content}}
                                    ></p>
                                </div>
                            </section>

                            {singleBlog?.faqs?.length > 0 && (
                                <section
                                    id="faq"
                                    className="blog-according-container p-3 py-md-5 mt-5"
                                >
                                    <h3 className="title">Questions Section</h3>
                                    <BlogAccording faqs={singleBlog?.faqs}/>
                                </section>
                            )}


                        </div>
                        <section className="blog-details-right-sidebar d-none d-lg-block">
                            {singleBlog?.category_list?.length > 0 && (
                                <BlogCategories categories={singleBlog?.category_list}/>
                            )}

                            {singleBlog?.related_blogs?.length > 0 && (
                                <RelatedBlogs blogs={singleBlog.related_blogs}/>
                            )}
                        </section>
                    </section>
                </main>
            )}
        </>
    );
};

export default BlogDetails;
