import React, {useEffect, useMemo, useState,} from "react";
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import {
    FacebookIcon,
    FacebookShareButton,
    LinkedinIcon,
    LinkedinShareButton,
    TwitterIcon,
    TwitterShareButton,
    WhatsappIcon,
    WhatsappShareButton,
} from "react-share";
import {useDispatch, useSelector} from "react-redux";
import {
    addWishlist,
    getProductCartCount,
    getRelatedProducts,
    getSingleProductDetail,
    getWishlistProduct,
} from "../store/product";
import {AddToCart, getCartData} from "../store/newCart";
import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from "react-image-gallery";
// Add this to the parent component that contains the Carousel
// import "react-responsive-carousel/lib/styles/carousel.min.css";
import SiteLoader from "../SiteLoader";
import {fixChar} from "../utils/helper";

// import "swiper/css";
// import "swiper/css/navigation";
// import "swiper/css/pagination";
// import { images } from "../utils/images";
import {Col, Nav, Row, Tab} from "react-bootstrap";
import {MagnifierContainer, MagnifierPreview, MagnifierZoom,} from "react-image-magnifiers";
import {Helmet} from "react-helmet";
import ReviewContent from "./product-info-tabs/ReviewContent";
// import { Rating } from "react-simple-star-rating";
// product image gallery
import "yet-another-react-lightbox/styles.css";

// import optional lightbox plugins
import "yet-another-react-lightbox/plugins/thumbnails.css";
import ProductGallery from "./ProductGallery";
import {mgtApi} from "../store/axios";
import CartPopup from "./components/CartPopup";
import './styles/ProductDetail.css'

let variantData = {};
const SingleProduct = () => {
    const {pathname} = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const {productTitle} = useParams();
    const segments = productTitle.split("-");
    // const numbersRegex = /\d+$/;
    const endsWithHyphenAndNumbersRegex = /-\d+$/;
    const endsWithNumbers = endsWithHyphenAndNumbersRegex.test(productTitle);
    if (endsWithNumbers) {
        const lastSegment = segments.pop();
    }
    const desiredPart = segments.join("-");

    const siteUrl = window.location.href;

    // const

    let userDetails = localStorage.getItem("userDetails");
    let data = JSON.parse(userDetails);
    let user_id = data?.ID;
    let isLogin = false;
    if (user_id) isLogin = true;
    else isLogin = false;
    const filterUrl = window.location.pathname.split("shop/").pop();
    const productSlug = filterUrl.split("/");
    const prodSlug = productSlug[2];

    const [modalImages, setModalImages] = useState([]);
    const [imageIndex, setImageIndex] = useState(0);

    // use selector
    const st = useSelector((state) => state.prodLi);
    const singleProduct = useSelector((state) => state.prodLi.singleProduct);
    const {loaderStatus: loader} = useSelector((state) => state.newCart);
    const {statusKey} = useSelector((state) => state.prodLi);
    const galleryI = useSelector((state) => state.prodLi.galleryimages);
    const relatedProducts = useSelector((state) => state.prodLi.related_prod);
    const inDetails = useSelector((state) => state.prodLi.productVariations);
    const userCartProductCount = useSelector(
        (state) => state.prodLi.userCartProductCount
    );
    const storeInfoTab = useSelector((state) => state.prodLi.vendorInfo);
    const shippingDetails = useSelector(
        (state) => state.prodLi.singleProduct?.product.shipping_profile
    );
    const wish = useSelector((state) => state.prodLi.wishlist_status);
    const cartData = useSelector((state) => state.newCart.cartData);

    const image = [
        // {
        //   original: singleProduct?.product?.main_image,
        //   thumbnail: singleProduct?.product?.main_image,
        //   originalHeight: 350,
        //   originalWidth: 350,
        //   thumbnailHeight: 90,
        //   thumbnailWidth: 60,
        // },
        ...galleryI.map((imgList) => ({
            original: imgList,
            thumbnail: imgList,
            originalHeight: 350,
            originalWidth: 350,
            thumbnailHeight: 90,
            thumbnailWidth: 60,
        })),
    ];

    const product_id = singleProduct?.product?.id;
    // const subCateId = singleProduct?.product?.sub_ids["0"];
    // let pTag = storeInfoTab?.vendor?.return_policy;
    // const sanitizedText = pTag?.replace(/<[^>]+style="[^"]*"[^>]*>|&nbsp;/g, "");
    // let pTagTwo = storeInfoTab?.vendor?.terms_conditions;
    // const sanitizedTextTwo = pTagTwo?.replace(
    //   /<[^>]+style="[^"]*"[^>]*>|&nbsp;/g,
    //   ""
    // );
    // let pTagThree = storeInfoTab?.vendor?.shipping_policy;
    // const sanitizedTextThree = pTagThree?.replace(
    //   /<[^>]+style="[^"]*"[^>]*>|&nbsp;/g,
    //   ""
    // );

    // use state
    // const [invId, setInvId] = useState("");
    const [stockVal, setStockVal] = useState(0);
    const [regularPrice, setRegularPrice] = useState(0);
    const [sellPrice, setSellPrice] = useState(0);
    const [selectedColor, setSelectedColor] = useState("");
    const [dbQuantity, setDbQuantity] = useState(1);
    const [prodQty, setProdQty] = useState(1);
    const [selectedAttributes, setSelectedAttributes] = useState([]);
    const [buttonShouldDisable, setButtonShouldDisable] = useState(false);
    const [selectedRadio, setSelectedRadio] = useState(
        Array(inDetails?.variation?.length).fill(false)
    );
    const [arr, setArr] = useState([]);
    const [showPopup, setShowPopup] = useState({value: false, product: null})
    const [activeTab, setActiveTab] = useState("description");
    const [activeTabNew, setActiveTabNew] = useState("vp");
    const [combinationKey, setCombinationKey] = useState(null);
    const [showFullText, setShowFullText] = useState(500);
    const [updated, setUpdated] = useState(false);
    const [isVisible, setIsVisible] = useState(false);
    const [currentItem, setCurrentItem] = useState(null);

    useEffect(() => {
        setProdQty(1);
    }, [pathname]);

    // const lightGallery = useRef(null);
    // const getItems = useCallback(() => {
    //   return galleryI.map((item, index) => {
    //     return (
    //       <a
    //         key={index}
    //         data-lg-size={item}
    //         className="gallery-item"
    //         data-src={item}
    //       >
    //         <img
    //           style={{ maxWidth: "80px" }}
    //           className="img-responsive"
    //           src={item}
    //           alt={`Image ${index}`}
    //         />
    //       </a>
    //     );
    //   });
    // }, [galleryI]);

    // method
    const addToCart = async (product, str) => {
        let guest_user_id;
        if (!isLogin) {
            const {data} = await mgtApi.post('guest-user', {});
            localStorage.setItem('guest_user_id', data.guest_user_id)
        }
        guest_user_id = localStorage.getItem('guest_user_id') || null

        const isProductHasVariations = !Array.isArray(
            singleProduct?.product_variations?.combinations
        );

        if (isProductHasVariations) {
            // Send with variations
            const newProduct = {
                pro_slug: product.pro_slug,
                product_id: singleProduct.product?.id,
                quantity: prodQty,
                selectedAttributes,
                sku: singleProduct?.product_variations?.combinations[combinationKey]
                    ?.sku,
                color: selectedColor,
                test: "variation request",
                guest_user_id
            };
            if (user_id) {
                newProduct["user_id"] = user_id;
            }
            await dispatch(AddToCart(newProduct))
            await dispatch(getCartData(false));
            if (str) {
                navigate('/cart');
                return
            }
            setShowPopup({value: true, product: product})
        } else {
            // Without Variations
            const newProduct = {
                pro_slug: product.pro_slug,
                quantity: prodQty,
                product_id: singleProduct.product?.id,
                test: "without variation request",
                guest_user_id
            };
            if (user_id) {
                newProduct["user_id"] = user_id;
            }
            await dispatch(AddToCart(newProduct))
            await dispatch(getCartData(false));
            if (str) {
                navigate('/cart');
                return
            }
            setShowPopup({value: true, product: product})
        }
    };
    const incrementProdQuantity = () => {
        if (prodQty < dbQuantity) {
            setProdQty((prev) => prev + 1);
            setButtonShouldDisable(false);
        } else {
            setButtonShouldDisable(true);
        }
    };
    const decrementProdQuantity = () => {
        setProdQty((prev) => (prev > 1 ? prev - 1 : prev));
    };

    const handleTabChange = (tab) => setActiveTab(tab);
    // const handleTabChangeNew = (tab) => setActiveTabNew(tab);
    // const handleOrderClick = (vendor_id, shipping_profile) => {
    //   navigate(`/store/${storeInfoTab?.vendor?.store_slug}`, {
    //     state: {
    //       vendor_id: vendor_id,
    //       shipping_profile: shipping_profile,
    //     },
    //   });
    // };

    const addToWishList = (product_id, index) => {
        if (userDetails) {
            dispatch(addWishlist(product_id));
            dispatch(getWishlistProduct());

            let heartIcon = document.getElementById("heartIcon-" + index);
            heartIcon.classList.toggle("selected");
            heartIcon.classList.toggle("hearticoncolor");
        } else {
            navigate("/login");
        }
    };

    const addToWishListWithoutIndex = (product_id) => {
        if (userDetails) {
            dispatch(addWishlist(product_id));
            let heartIcon = document.getElementById("heartIconWithoutIndex");
            heartIcon.classList.toggle("selected");
            heartIcon.classList.toggle("hearticoncolor");
            dispatch(getWishlistProduct());
        } else {
            navigate("/login");
        }
    };

    const handleAttributeChange = (attributeId, value) => {
        setProdQty(1);
        setSelectedAttributes((prevAttributes) => {
            const attributeIndex = prevAttributes.findIndex(
                (attr) => attr.id === attributeId
            );

            const updatedAttributes = [...prevAttributes];

            if (attributeIndex !== -1) {
                updatedAttributes[attributeIndex].value = value;
            } else {
                updatedAttributes.push({id: attributeId, value});
            }
            updatedAttributes.sort((a, b) => {
                const aIndex =
                    singleProduct.product_variations?.choice_attributes_combination.findIndex(
                        (attr) => attr.id === a.id
                    );
                const bIndex =
                    singleProduct.product_variations?.choice_attributes_combination.findIndex(
                        (attr) => attr.id === b.id
                    );
                return aIndex - bIndex;
            });
            return updatedAttributes;
        });
    };

    const handleColorChange = (color) => {
        setSelectedColor(color);
        setProdQty(1);
    };
    // const handleRadioChange = (value, i, inventory_id, id) => {
    //   const allLabels = document.querySelectorAll("label");
    //   allLabels.forEach((label) => {
    //     const invValue = label.getAttribute("data-inv");
    //     if (invValue === inventory_id) {
    //       label.classList.remove("hide");
    //     } else {
    //       label.classList.add("hide");
    //     }
    //   });

    //   const newSelectedRadio = [...selectedRadio];
    //   newSelectedRadio[i] = value;
    //   setSelectedRadio(newSelectedRadio);
    //   arr[i] = value;
    //   if (arr.length > 1) Object.keys(arr).sort();
    //   setArr(arr);
    //   let data = Object.values(arr).toString().replaceAll(",", "");
    // };

    // use Effect
    useEffect(() => {
        dispatch(getSingleProductDetail(desiredPart));
        dispatch(getCartData(true));
    }, [desiredPart, productTitle]);

    useEffect(() => {
        const cartItemsData = [].concat(...cartData.map((obj) => obj.cart_items));

        const itemExistInCart = cartItemsData.find(
            (item) => item.pro_slug === prodSlug
        );

        // console.log('itemExistInCart: ', itemExistInCart)

        if (itemExistInCart) {
            setProdQty(itemExistInCart.quantity);
        } else {
            setProdQty(1);
        }
    }, [cartData, pathname]);

    useEffect(() => {
        (async () => {
            if (singleProduct) {
                // console.log(singleProduct);

                // dispatch(
                //     getVendorInfo(storeSlug)
                // );

                dispatch(
                    getRelatedProducts(
                        singleProduct?.product?.id,
                        singleProduct?.product?.subcate_ids,
                        user_id
                    )
                );
                setDbQuantity(singleProduct?.product?.stock);
            }
        })();
    }, [singleProduct?.product]);

    useEffect(() => {
        variantData = {};
        singleProduct?.product &&
        Object.entries(singleProduct?.product.varients).map(([varient]) => {
            variantData = {...variantData, [varient]: ""};
        });

        setStockVal(singleProduct?.product && singleProduct?.product.stock);
        setRegularPrice(singleProduct?.product?.sale_price ? singleProduct?.product?.sale_price : singleProduct?.product?.regular_price);
        setSellPrice(singleProduct?.product && singleProduct?.product?.sell_price);
    }, [singleProduct?.product]);
    useEffect(() => {
        if (user_id && product_id) {
            dispatch(getProductCartCount(user_id, product_id));
        }
    }, [product_id]);

    useEffect(() => {
        setDbQuantity(userCartProductCount);
    }, [userCartProductCount]);

    useEffect(() => {
        if (wish === 1) {
            let element = document.getElementsByClassName("wish-class")[0];
            if (element) {
                element.style.color = "red";
            }
        }
        if (wish === 0) {
            let element = document.getElementsByClassName("wish-class")[0];
            if (element) {
                element.style.color = "#ffc107";
            }
        }
    }, [wish]);

    // const singleColor =
    //   singleProduct?.product_variations?.colors &&
    //   Object.entries(singleProduct?.product_variations?.colors);

    // useEffect(() => {
    //   const initialAttributes =
    //     singleProduct?.product_variations?.choice_attributes_combination.map(
    //       (attr) => ({
    //         id: attr.id,
    //         name: attr.name,
    //         value: attr.values[0],
    //       })
    //     );

    //   if (
    //     singleColor?.length ||
    //     singleProduct?.product_variations?.colors?.length
    //   ) {
    //     let color = Object.entries(singleProduct?.product_variations?.colors)[0];
    //     if (color) {
    //       console.log(color);
    //       setSelectedColor(color[1]);
    //     }
    //   } else {
    //     setSelectedColor("");
    //   }
    //   setSelectedAttributes(initialAttributes);
    // }, [singleProduct?.product_variations]);

    useEffect(() => {
        const cartItemsData = [].concat(...cartData.map((obj) => obj.cart_items));
        const itemExistInCart = cartItemsData.find(
            (item) => item.product_id === singleProduct?.product.id
        );
        let selectAttr = itemExistInCart?.pro_combinations?.other_combi;
        const originalAttr =
            singleProduct?.product_variations?.choice_attributes_combination;
        if (selectAttr?.length > 0) {
            const initialAttributes = [];
            selectAttr.forEach((element) => {
                const isExist = originalAttr.find(
                    (item) => item.name === element?.name
                );
                initialAttributes.push({id: isExist?.id, ...element});
            });
            if (initialAttributes?.length > 0) {
                setSelectedAttributes(initialAttributes);
            }
        } else {
            const initialAttributes =
                singleProduct?.product_variations?.choice_attributes_combination.map((attr) => ({
                        id: attr.id,
                        name: attr.name,
                        value: attr.values[0],
                    })
                );
            setSelectedAttributes(initialAttributes);
        }

        if (itemExistInCart) {
            setProdQty(itemExistInCart.quantity);
        } else {
            setProdQty(1);
        }
        const colorData = itemExistInCart?.pro_combinations?.color_combi;
        if (colorData) {
            setSelectedColor(colorData?.color);
        } else {
            const singleColor =
                singleProduct?.product_variations?.colors &&
                Object.entries(singleProduct?.product_variations?.colors);
            if (singleColor?.length > 0) {
                setSelectedColor(singleColor[0][1]);
            }
        }
    }, [cartData, singleProduct?.product, singleProduct?.product_variations]);

    useEffect(() => {
        setCombinationKey("");
        if (selectedAttributes && selectedAttributes?.length) {
            let combinationKey = "";
            if (selectedAttributes?.length === 0) {
                combinationKey += selectedColor;
            } else {
                if (selectedColor) {
                    combinationKey += selectedColor + "-";
                } else {
                    combinationKey += selectedColor;
                }
            }
            combinationKey += `${selectedAttributes
                .map((attr) => attr.value)
                .join("-")}`;

            setCombinationKey(combinationKey);

            if (
                singleProduct?.product_variations?.combinations[combinationKey]?.qty
            ) {
                setDbQuantity(
                    singleProduct?.product_variations?.combinations[combinationKey]?.qty
                );
            } else {
                setDbQuantity(singleProduct?.product?.stock);
            }

            setUpdated(!updated);
        }
    }, [singleProduct?.product, selectedAttributes, selectedColor]);

    useMemo(() => {
        const cartItemsData = [].concat(...cartData.map((obj) => obj.cart_items));
        const existItems = cartItemsData.filter(
            (item) => item.product_id === singleProduct?.product.id
        );

        for (let i = 0; i < existItems.length; i++) {
            const itemExistInCart = existItems[i];
            const colorData = itemExistInCart?.pro_combinations?.color_combi;
            const otherData = itemExistInCart?.pro_combinations?.other_combi;
            if (colorData || otherData?.length > 0) {
                const color =
                    itemExistInCart?.pro_combinations?.color_combi?.color ===
                    selectedColor;

                let checkAttrs = [];
                selectedAttributes.forEach((element) => {
                    const slAttr = otherData.find(
                        (otherAttr) =>
                            otherAttr.name === element.name &&
                            otherAttr.value === element.value
                    );
                    if (slAttr) {
                        checkAttrs.push(slAttr);
                    }
                });
                if (checkAttrs?.length === selectedAttributes?.length && color) {
                    setProdQty(itemExistInCart.quantity);
                }
            }
        }
    }, [selectedAttributes, selectedColor]);

    useEffect(() => {
        if (statusKey) {
            setIsVisible(true);

            const timeout = setTimeout(() => {
                setIsVisible(false);
            }, 2000);

            return () => clearTimeout(timeout);
        }
    }, [statusKey]);

    const CustomSlide = ({item}) => {
        const {original} = item;
        return (
            <div
                style={{
                    overflow: "visible",
                    position: "relative",
                    backgroundColor: "#f7f2f2",
                }}
            >
                <MagnifierContainer
                    className="magnifier-container"
                    style={{
                        width: "100%",
                        position: "relative",
                        objectFit: "fill",
                    }}
                >
                    <MagnifierPreview
                        imageSrc={original}
                        className="previewImg"
                        style={{maxHeight: "100%", objectFit: "fill"}}
                    />
                    <MagnifierZoom
                        imageSrc={original}
                        className="zoomImg"
                        style={{
                            maxHeight: "450px",
                            height: "100%",
                            width: "100%",
                            position: "absolute",
                            top: 0,
                            bottom: 0,
                            right: 0,
                            zIndex: "100",
                        }}
                    />
                </MagnifierContainer>
            </div>
        );
    };

    const renderItem = (item) => {
        return <CustomSlide item={item}/>;
    };

    const [index, setIndex] = useState(-1);
    const modalImageItems = galleryI?.map((img) => {
        return {
            src: img,
            width: 800,
            height: 600,
            href: img, // You can customize this URL
        };
    });

    return (
        <>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{singleProduct?.product?.meta_title}</title>
                <meta
                    name="description"
                    content={singleProduct?.product?.meta_description}
                />
                <meta name="keywords" content={singleProduct?.product?.meta_keywords}/>

                <meta name="twitter:card" content="summary_large_image"/>
                <meta name="twitter:url" content={siteUrl}/>
                <meta
                    name="twitter:title"
                    content={singleProduct?.product?.meta_title}
                />
                <meta
                    name="twitter:description"
                    content={singleProduct?.product?.meta_description}
                />
                <meta name="twitter:image" content={singleProduct?.product?.og_image}/>

                <meta property="og:type" content="website"/>
                <meta property="og:url" content={siteUrl}/>
                <meta
                    property="og:title"
                    content={singleProduct?.product?.meta_title}
                />
                <meta
                    property="og:description"
                    content={singleProduct?.product?.meta_description}
                />
                <meta property="og:image" content={singleProduct?.product?.og_image}/>
            </Helmet>

            {st.loaderStatus ? (
                <SiteLoader status={st.loaderStatus}/>
            ) : (
                <>
                    <main>
                        <div className="bg-light inner-breadcrumb">
                            <div className="container">
                                <div className="d-flex justify-content-between align-items-center py-3">
                                    <h3 className="d-none d-sm-block">
                                        {singleProduct?.product?.pro_name.length > 20
                                            ? singleProduct?.product?.pro_name.slice(0, 20) + "..."
                                            : singleProduct?.product?.pro_name}
                                    </h3>
                                    <span></span>
                                    <span
                                        className="breadcrumb-item active order-span align-items-center"
                                        aria-current="page"
                                        order_id={singleProduct?.product?.id}
                                        key={singleProduct?.product?.id}
                                        style={{
                                            cursor: "pointer",
                                            textDecoration: "none",
                                        }}
                                    >
                    <a
                        href={`/store/${singleProduct?.product?.vendor?.store_slug}`}
                    >
                      <li className="morefromvendor">More from this vendor</li>
                    </a>
                  </span>
                                </div>
                            </div>
                        </div>

                        <section className="single-product-section">
                            <div className="container p-0">
                                <div className="row p-0 m-0">
                                    <div className="col-md-5">
                                        {/*Gallery Start*/}
                                        <div className="zoom_product arrow-parent">
                                            <ImageGallery
                                                items={image}
                                                showFullscreenButton={true}
                                                onSlide={(e) => setImageIndex(e)}
                                                renderFullscreenButton={() => (
                                                    <div
                                                        onClick={() => setIndex(imageIndex)}
                                                        className="position-absolute d-flex justify-content-center align-items-center bg-light"
                                                        style={{
                                                            bottom: "20px",
                                                            right: "20px",
                                                            color: "initial",
                                                            zIndex: "100",
                                                            width: "50px",
                                                            height: "50px",
                                                            borderRadius: "50%",
                                                            cursor: "pointer",
                                                        }}
                                                    >
                                                        <svg
                                                            className=""
                                                            onMouseEnter={(e) =>
                                                                (e.target.style.color = "#F6A92C")
                                                            }
                                                            onMouseLeave={(e) =>
                                                                (e.target.style.color = "initial")
                                                            }
                                                            width="30px"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            stroke="currentColor"
                                                            stroke-width="2"
                                                            stroke-linecap="round"
                                                            stroke-linejoin="round"
                                                        >
                                                            <path
                                                                d="M8 3H5a2 2 0 0 0-2 2v3m18 0V5a2 2 0 0 0-2-2h-3m0 18h3a2 2 0 0 0 2-2v-3M3 16v3a2 2 0 0 0 2 2h3"></path>
                                                        </svg>
                                                    </div>
                                                )}
                                                showPlayButton={false}
                                                showNav={true}
                                                // onSlide={(e) => setImageIndex(e)}
                                                // onClick={() => setModalImages(image)}
                                                renderItem={renderItem}
                                                renderLeftNav={(onClick, disabled) => (
                                                    <button
                                                        className={`arrow-l-btn image-gallery-left-nav p-0 ${
                                                            disabled ? "disabled" : ""
                                                        }`}
                                                        onClick={onClick}
                                                        disabled={disabled}
                                                    >
                                                        {/* You can put your custom arrow icon here */}
                                                        <svg
                                                            width="40px"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            fill="none"
                                                            viewBox="0 0 24 24"
                                                            stroke-width="4"
                                                            stroke="currentColor"
                                                            className="w-6 h-6"
                                                        >
                                                            <path
                                                                stroke-linecap="none"
                                                                stroke-linejoin="none"
                                                                d="M15.75 19.5L8.25 12l7.5-7.5"
                                                            />
                                                        </svg>
                                                    </button>
                                                )}
                                                renderRightNav={(onClick, disabled) => (
                                                    <button
                                                        className={`arrow-r-btn image-gallery-right-nav p-0
                             ${disabled ? "disabled" : ""}`}
                                                        onClick={onClick}
                                                        disabled={disabled}
                                                    >
                                                        {/* You can put your custom arrow icon here */}
                                                        <svg
                                                            width="40px"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            fill="none"
                                                            viewBox="0 0 24 24"
                                                            stroke-width="4"
                                                            stroke="currentColor"
                                                            className="w-6 h-6"
                                                        >
                                                            <path
                                                                stroke-linecap="none"
                                                                stroke-linejoin="none"
                                                                d="M8.25 4.5l7.5 7.5-7.5 7.5"
                                                            />
                                                        </svg>
                                                    </button>
                                                )}
                                            />
                                        </div>

                                        {/* <Lightbox
                      slides={modalImageItems}
                      open={index >= 0}
                      index={index}
                      close={() => setIndex(-1)}
                      plugins={[Fullscreen, Slideshow, Thumbnails, Zoom]}
                      slideshow={false}
                    /> */}
                                        <ProductGallery
                                            index={index}
                                            close={setIndex}
                                            images={image}
                                        />
                                        {/* <!-- end  --> */}
                                    </div>
                                    <div className="col-md-7 p-0 m-0">
                                        <div className="product-detail ms-md-4 mt-2 p-2 ">
                                            {/*Product Title*/}
                                            <div className="d-flex justify-content-between">
                                                <h1 className="detailProductName">
                                                    {singleProduct?.product?.pro_name}
                                                </h1>
                                                <div>
                                                    <i
                                                        className={
                                                            singleProduct?.wishlist_status
                                                                ? "fa fa-heart hearticoncolor selected wishlist-iconColor"
                                                                : "fa fa-heart wishlist-iconColor"
                                                        }
                                                        aria-hidden="true"
                                                        wish-className="true"
                                                        id="heartIconWithoutIndex"
                                                        style={{cursor: "pointer"}}
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            addToWishListWithoutIndex(
                                                                singleProduct.product?.id
                                                            );
                                                        }}
                                                    ></i>
                                                </div>
                                            </div>

                                            {/*Review*/}
                                            <div className="review d-flex align-items-center">
                                                <div>
                                                    {[...Array(5)].map((_, index) => (
                                                        <i
                                                            key={index}
                                                            className={`fa ${
                                                                index <
                                                                parseInt(
                                                                    singleProduct?.rating_data?.average_rating
                                                                )
                                                                    ? "fa-star"
                                                                    : "fa-star-o"
                                                            } me-1`}
                                                            aria-hidden="true"
                                                        ></i>
                                                    ))}
                                                </div>

                                                <span
                                                    className="grey-text detailProductReviews d-flex align-items-center mt-2">
                          <span>
                            {singleProduct?.rating_data?.total_rating}
                          </span>
                          <span style={{marginLeft: "5px"}}>Reviews</span>
                        </span>
                                            </div>

                                            {/*Price Tag*/}
                                            {!singleProduct?.product.sale_price && <div className="priceTag">
                                                <span>&#x24;</span>
                                                {combinationKey
                                                    ? (singleProduct?.product_variations?.combinations[
                                                        combinationKey
                                                        ]?.price * prodQty).toFixed(2)
                                                    : (singleProduct?.product?.sell_price * prodQty).toFixed(2)}
                                            </div>}
                                            {singleProduct?.product.sale_price && <div className='d-flex gap-3'>
                                                <div
                                                    className="priceTag">${(singleProduct.product.sale_price * prodQty).toFixed(2)}</div>
                                                <div className="priceTag strike-through">${combinationKey
                                                    ? (singleProduct?.product_variations?.combinations[
                                                        combinationKey
                                                        ]?.price * prodQty).toFixed(2)
                                                    : (singleProduct?.product?.sell_price * prodQty).toFixed(2)}</div>
                                            </div>}

                                            {/*Product Availability*/}
                                            <div className="available">
                                                {(
                                                    combinationKey
                                                        ? !singleProduct?.product_variations?.combinations[
                                                            combinationKey
                                                            ]?.qty ||
                                                        singleProduct?.product_variations?.combinations[
                                                            combinationKey
                                                            ]?.display !== "on"
                                                        : singleProduct?.product?.stock === 0
                                                ) ? (
                                                    <>
                                                        0 : <span className="text-red">Out of Stock</span>
                                                        &nbsp;&nbsp;
                                                        <span
                                                            className="order-span"
                                                            key={singleProduct?.product?.id}
                                                            style={{cursor: "pointer"}}
                                                        ></span>
                                                    </>
                                                ) : (
                                                    <>
                                                        {singleProduct?.product?.stock !== 0 ||
                                                            singleProduct?.product_variations?.combinations[
                                                                combinationKey
                                                                ]?.qty}
                                                        {combinationKey
                                                            ? singleProduct?.product_variations?.combinations[
                                                                combinationKey
                                                                ]?.qty
                                                            : singleProduct?.product?.stock}
                                                        : <span className="text-green">In Stock</span>
                                                        &nbsp;&nbsp;
                                                        <span
                                                            className="order-span"
                                                            order_id={singleProduct?.product?.id}
                                                            key={singleProduct?.product?.id}
                                                            // onClick={() => handleOrderClick(singleProduct?.product?.vendor_id)}
                                                            style={{cursor: "pointer"}}
                                                        ></span>
                                                    </>
                                                )}
                                            </div>

                                            {/*Product description*/}
                                            <h2 className="productDetailShortdes">
                                                {singleProduct?.product?.short_desc}
                                            </h2>

                                            {/*If Product has variations*/}
                                            {singleProduct?.product_variations && (
                                                <div>
                                                    <table>
                                                        <tbody>
                                                        {/* Render color options */}
                                                        {singleProduct?.product_variations?.colors &&
                                                            Object.keys(
                                                                singleProduct.product_variations.colors
                                                            ).length > 0 && (
                                                                <tr>
                                                                    <td className={`button-column`}>
                                                                        <div className="d-flex">
                                                                            <div className="productDetailColor mt-1">
                                                                                Colors
                                                                            </div>
                                                                            <div className=" ms-2">
                                                                                <div className="d-flex">
                                                                                    {Object.keys(
                                                                                        singleProduct.product_variations
                                                                                            .colors
                                                                                    ).map((colorCode) => {
                                                                                        const colorName =
                                                                                            singleProduct.product_variations
                                                                                                .colors[colorCode];
                                                                                        return (
                                                                                            <div
                                                                                                style={{display: "flex"}}
                                                                                                key={colorCode}
                                                                                                className={
                                                                                                    selectedColor === colorName
                                                                                                        ? "color-selected color me-3"
                                                                                                        : "color me-3"
                                                                                                }
                                                                                            >
                                                                                                <button
                                                                                                    key={colorCode}
                                                                                                    id="variantFontSizeId"
                                                                                                    style={{
                                                                                                        backgroundColor:
                                                                                                        colorCode,
                                                                                                    }}
                                                                                                    onClick={() =>
                                                                                                        handleColorChange(
                                                                                                            colorName
                                                                                                        )
                                                                                                    }
                                                                                                />
                                                                                                {selectedColor ===
                                                                                                    colorName && (
                                                                                                        <i className="fa fa-check"></i>
                                                                                                    )}
                                                                                            </div>
                                                                                        );
                                                                                    })}
                                                                                </div>
                                                                                <span className="productDetailShortdes">
                                            {selectedColor}
                                          </span>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            )}

                                                        {/* Render attribute options */}
                                                        {singleProduct?.product_variations && (
                                                            <tr>
                                                                {singleProduct?.product_variations?.choice_attributes_combination.map(
                                                                    (attribute, index) => (
                                                                        <td
                                                                            className="button-column mt-4"
                                                                            key={index}
                                                                        >
                                                                            <div
                                                                                key={attribute.id}
                                                                                className="d-flex align-items-center"
                                                                            >
                                                                                <div className="productDetailColor">
                                                                                    {attribute.name}
                                                                                </div>
                                                                                <div
                                                                                    key={attribute.id}
                                                                                    style={{
                                                                                        display: "flex",
                                                                                        blockSize: "fit-content",
                                                                                    }}
                                                                                >
                                                                                    {attribute.values.map((value) => {
                                                                                        return (
                                                                                            <button
                                                                                                key={value}
                                                                                                onClick={() =>
                                                                                                    handleAttributeChange(
                                                                                                        attribute.id,
                                                                                                        value
                                                                                                    )
                                                                                                }
                                                                                                className={
                                                                                                    selectedAttributes &&
                                                                                                    (
                                                                                                        selectedAttributes.some(
                                                                                                            (attr) =>
                                                                                                                attr.id === attribute.id &&
                                                                                                                attr.value === value
                                                                                                        )
                                                                                                            ? "attribute-selected attribute-selectedIndex ms-2"
                                                                                                            : "attribute-selected ms-2"
                                                                                                    )
                                                                                                }
                                                                                            >
                                                                                                {value}
                                                                                            </button>
                                                                                        )
                                                                                    })}
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    )
                                                                )}
                                                            </tr>
                                                        )}

                                                        {/*Add to Cart Button*/}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            )}

                                            {/* Error */}
                                            <span id="v_msg"></span>
                                        </div>
                                        <div
                                            className="d-flex flex-wrap flex-lg-nowrap justify-content-between align-items-center gap-2 ms-md-4 mb-5">
                                            <div className="d-flex gap-2 mt-3 w-100">
                                                <div className="qty-style dataButton">
                                                    <button
                                                        disabled={
                                                            combinationKey
                                                                ? singleProduct?.product_variations
                                                                    ?.combinations[combinationKey]?.display !==
                                                                "on" ||
                                                                !singleProduct?.product_variations
                                                                    ?.combinations[combinationKey]?.qty
                                                                : !singleProduct?.product?.stock || loader
                                                        }
                                                        onClick={() => decrementProdQuantity()}
                                                    >
                                                        <i className="fa fa-minus"></i>
                                                    </button>
                                                    <div>{prodQty}</div>
                                                    <button
                                                        disabled={
                                                            combinationKey
                                                                ? singleProduct?.product_variations
                                                                    ?.combinations[combinationKey]?.display !==
                                                                "on" ||
                                                                !singleProduct?.product_variations
                                                                    ?.combinations[combinationKey]?.qty
                                                                : !singleProduct?.product?.stock || loader
                                                        }
                                                        onClick={() => incrementProdQuantity()}
                                                    >
                                                        <i className="fa fa-plus"></i>
                                                    </button>
                                                </div>

                                                <button
                                                    className="add-cart"
                                                    onClick={() =>
                                                        addToCart(singleProduct.product)
                                                    }
                                                    disabled={
                                                        combinationKey
                                                            ? singleProduct?.product_variations?.combinations[
                                                                combinationKey
                                                                ]?.display !== "on" ||
                                                            !singleProduct?.product_variations
                                                                ?.combinations[combinationKey]?.qty
                                                            : !singleProduct?.product?.stock || loader
                                                    }
                                                >
                                                    {loader ? (
                                                        "..."
                                                    ) : (
                                                        <span className="text-center">
                              <i
                                  className="fa fa-shopping-cart"
                                  aria-hidden="true"
                              ></i>
                                                            <p className='cart-text'>Add to cart</p>
                            </span>
                                                    )}
                                                </button>
                                                <CartPopup showPopup={showPopup}
                                                           cartData={cartData}
                                                           setShowPopup={setShowPopup}/>

                                                <button
                                                    className="add-cart"
                                                    onClick={() =>
                                                        addToCart(singleProduct.product, 'buy')
                                                    }
                                                    disabled={
                                                        combinationKey
                                                            ? singleProduct?.product_variations?.combinations[
                                                                combinationKey
                                                                ]?.display !== "on" ||
                                                            !singleProduct?.product_variations
                                                                ?.combinations[combinationKey]?.qty
                                                            : !singleProduct?.product?.stock || loader
                                                    }
                                                >
                                                    {loader ? (
                                                        "Processing..."
                                                    ) : (
                                                        <span className="data_textButton">
                              Buy Now
                            </span>
                                                    )}
                                                </button>
                                            </div>
                                            <div className="px-2">
                                                <p className="share_this">Share this :</p>
                                                <div className="go-social mt-1">
                                                    <FacebookShareButton
                                                        url={siteUrl}
                                                        hashtag="Mangtum"
                                                        quote="Best Seller in town"
                                                    >
                                                        <FacebookIcon size={30} round={true}/>
                                                    </FacebookShareButton>
                                                    <WhatsappShareButton
                                                        url={siteUrl}
                                                        hashtag="Mangtum"
                                                        quote="Best Seller in town"
                                                    >
                                                        <WhatsappIcon size={30} round={true}/>
                                                    </WhatsappShareButton>
                                                    <LinkedinShareButton
                                                        url={siteUrl}
                                                        hashtag="Mangtum"
                                                        quote="Best Seller in town"
                                                    >
                                                        <LinkedinIcon size={30} round={true}/>
                                                    </LinkedinShareButton>
                                                    <TwitterShareButton
                                                        url={siteUrl}
                                                        hashtag="Mangtum"
                                                        quote="Best Seller in town"
                                                    >
                                                        <TwitterIcon size={30} round={true}/>
                                                    </TwitterShareButton>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <hr className="" style={{borderColor: "#ECECEC"}}/>

                        {/*Description*/}

                        <div className="container mx-auto">
                            <div className="desc-tab ">
                                <ul className="nav nav-tabs" id="desTab" role="tablist">
                                    {/*Description*/}
                                    <li className="nav-item" role="presentation">
                                        <button
                                            className={`nav-link ${
                                                activeTab === "description" ? "active" : ""
                                            }`}
                                            id="description-tab"
                                            data-bs-toggle="tab"
                                            data-bs-target="#description-tab-pane"
                                            type="button"
                                            role="tab"
                                            aria-controls="description-tab-pane"
                                            aria-selected={activeTab === "description"}
                                            onClick={() => handleTabChange("description")}
                                        >
                                            Description
                                        </button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button
                                            className={`nav-link ${
                                                activeTab === "Reviews" ? "active" : ""
                                            }`}
                                            id="Reviews-tab"
                                            data-bs-toggle="tab"
                                            data-bs-target="#Reviews-tab-pane"
                                            type="button"
                                            role="tab"
                                            aria-controls="Reviews-tab-pane"
                                            aria-selected={activeTab === "Reviews"}
                                            onClick={() => handleTabChange("Reviews")}
                                        >
                                            Reviews ({singleProduct?.rating_data?.total_rating})
                                        </button>
                                    </li>

                                    {/*Store*/}
                                    <li className="nav-item" role="presentation">
                                        <button
                                            className={`nav-link ${
                                                activeTab === "store" ? "active" : ""
                                            }`}
                                            id="store-tab"
                                            data-bs-toggle="tab"
                                            data-bs-target="#store"
                                            type="button"
                                            role="tab"
                                            aria-controls="store"
                                            aria-selected={activeTab === "store"}
                                            onClick={() => handleTabChange("store")}
                                        >
                                            Store Info
                                        </button>
                                    </li>

                                    {/*Shipping*/}
                                    <li className="nav-item" role="presentation">
                                        <button
                                            className={`nav-link ${
                                                activeTab === "shipping" ? "active" : ""
                                            }`}
                                            id="shipping-tab"
                                            data-bs-toggle="tab"
                                            data-bs-target="#shipping"
                                            type="button"
                                            role="tab"
                                            aria-controls="shipping"
                                            aria-selected={activeTab === "shipping"}
                                            onClick={() => handleTabChange("shipping")}
                                        >
                                            Shipping
                                        </button>
                                    </li>
                                </ul>

                                <div className="tab-content accordion" id="desTabContent">
                                    {/*Description*/}
                                    <div
                                        className={`tab-pane fade accordion-item ${
                                            activeTab === "description" ? "active show" : ""
                                        }`}
                                        id="description-tab-pane"
                                        role="tabpanel"
                                        aria-labelledby="description-tab"
                                        tabIndex={0}
                                    >
                                        <div
                                            id="description"
                                            className="accordion-collapse collapse show d-lg-block"
                                            aria-labelledby="description"
                                            data-bs-parent="#desTabContent"
                                        >
                                            <div className="accordion-body">
                                                <div className="row">
                                                    <div className="col-md-4">
                                                        <div className="des-img">
                                                            <img
                                                                src={singleProduct?.product?.main_image}
                                                                title=""
                                                                alt=""
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="description-content">
                                                            {/* <h3>{singleProduct?.product?.pro_name}</h3> */}

                                                            <p
                                                                onClick={(e) => setShowFullText(-1)}
                                                                style={{cursor: "pointer"}}
                                                                dangerouslySetInnerHTML={{
                                                                    __html: fixChar(
                                                                        singleProduct?.product?.description,
                                                                        showFullText
                                                                    ),
                                                                }}
                                                            ></p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div
                                        className={`tab-pane fade accordion-item ${
                                            activeTab === "Reviews" ? "active show" : ""
                                        }`}
                                        id="Reviews-tab-pane"
                                        role="tabpanel"
                                        aria-labelledby="Reviews-tab"
                                        tabIndex={0}
                                    >
                                        {singleProduct?.rating_data?.total_rating > 0 ? (
                                            <ReviewContent
                                                reviews={singleProduct?.reviews}
                                                rating={singleProduct?.rating_data}
                                            />
                                        ) : (
                                            <div
                                                className="d-flex justify-content-center align-items-center"
                                                style={{minHeight: "200px"}}
                                            >
                                                <h5 className="text-center font-theme">
                                                    No Review Found
                                                </h5>
                                            </div>
                                        )}
                                    </div>

                                    {/*Store*/}
                                    <div
                                        className={`tab-pane fade accordion-item ${
                                            activeTab === "store" ? "active show" : ""
                                        }`}
                                        id="store"
                                        role="tabpanel"
                                        aria-labelledby="store-tab"
                                    >
                                        <Tab.Container
                                            id="left-tabs-example"
                                            defaultActiveKey="key1"
                                        >
                                            <Row className="">
                                                <Col sm={3}>
                                                    <Nav
                                                        variant="text"
                                                        className="flex-column border-end"
                                                        style={{borderColor: "#ECECEC"}}
                                                    >
                                                        <Nav.Item>
                                                            <Nav.Link
                                                                activeTab
                                                                activeKey="key1"
                                                                eventKey="key1"
                                                                className="custom-tab"
                                                            >
                                                                Vendor Information
                                                            </Nav.Link>
                                                        </Nav.Item>
                                                        <Nav.Item>
                                                            <Nav.Link eventKey="key2" className="custom-tab">
                                                                Shipping Policy
                                                            </Nav.Link>
                                                        </Nav.Item>
                                                        <Nav.Item>
                                                            <Nav.Link eventKey="key3" className="custom-tab">
                                                                Return Policy
                                                            </Nav.Link>
                                                        </Nav.Item>
                                                        {/* <Nav.Item>
                                <Nav.Link
                                  eventKey="key4"
                                  className="custom-tab"
                                >
                                  Shipping & Delivery
                                </Nav.Link>
                              </Nav.Item> */}
                                                        <Nav.Item>
                                                            <Nav.Link eventKey="key5" className="custom-tab">
                                                                Terms & Conditions
                                                            </Nav.Link>
                                                        </Nav.Item>
                                                    </Nav>
                                                </Col>
                                                <Col sm={9} className="" style={{minHeight: "300px"}}>
                                                    <Tab.Content className="p-0">
                                                        <Tab.Pane activeKey="key1" eventKey="key1">
                                                            <div className="tab-content d-flex gap-3 default-image">
                                                                <img
                                                                    src={singleProduct?.product?.vendor?.avatar === 'https://mangtum.net/admin/public/images/default-image.jpg' ? `${window.location.origin}/assets/img/user.png` : singleProduct?.product?.vendor?.avatar}
                                                                    alt=''/>
                                                                <div>
                                                                    <h3 className='mb-0'>{singleProduct?.product?.vendor?.name}</h3>
                                                                    {singleProduct?.product?.vendor.bio &&
                                                                        <p>{singleProduct?.product?.vendor.bio}</p>}

                                                                    {singleProduct?.product?.vendor.state && <p>State
                                                                        : {singleProduct?.product?.vendor.state}</p>}

                                                                    {singleProduct?.product?.vendor.country &&
                                                                        <p>Country
                                                                            : {singleProduct?.product?.vendor.country}</p>}
                                                                </div>
                                                            </div>
                                                        </Tab.Pane>
                                                        <Tab.Pane eventKey="key2">
                                                            <div
                                                                className="tab-content"
                                                                dangerouslySetInnerHTML={{
                                                                    __html:
                                                                    singleProduct?.product?.vendor
                                                                        ?.shipping_policy,
                                                                }}
                                                            />
                                                        </Tab.Pane>
                                                        <Tab.Pane eventKey="key3">
                                                            <div
                                                                className="tab-content"
                                                                dangerouslySetInnerHTML={{
                                                                    __html:
                                                                    singleProduct?.product?.vendor
                                                                        ?.return_policy,
                                                                }}
                                                            />
                                                        </Tab.Pane>
                                                        {/* <Tab.Pane eventKey="key4">
                                <div
                                  className="tab-content"
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      singleProduct?.product?.vendor
                                        ?.terms_conditions,
                                  }}
                                />
                              </Tab.Pane> */}
                                                        <Tab.Pane eventKey="key5">
                                                            <div
                                                                className="tab-content"
                                                                dangerouslySetInnerHTML={{
                                                                    __html:
                                                                    singleProduct?.product?.vendor
                                                                        ?.terms_conditions,
                                                                }}
                                                            />
                                                        </Tab.Pane>
                                                    </Tab.Content>
                                                </Col>
                                            </Row>
                                        </Tab.Container>
                                    </div>

                                    {/*Shipping*/}
                                    <div
                                        className={`tab-pane fade accordion-item ${
                                            activeTab === "shipping" ? "active show" : ""
                                        }`}
                                        id="shipping"
                                        role="tabpanel"
                                        aria-labelledby="shipping-tab"
                                    >
                                        <div
                                            id="shipping"
                                            className="accordion-collapse collapse show  d-lg-block"
                                            aria-labelledby="shipping "
                                            data-bs-parent="#desTabContent"
                                        >
                                            <div className="accordion-body">
                                                <div className="description-content row px-6">
                                                    <div className="col-md-6">
                                                        {/* <div
                                                                className="d-flex gap-2 justify-content-between align-items-center mb-2 py-2  border-bottom border-1">
                                                                <h6>Shipping profile Name</h6>
                                                                <p className="color-class">
                                                                    {
                                                                        storeInfoTab?.shipping_info
                                                                            ?.shipping_profile_name
                                                                    }
                                                                </p>
                                                            </div> */}

                                                        <div
                                                            className="d-flex gap-2 justify-content-between align-items-center mb-2 py-2  border-bottom border-1">
                                                            <h6>Standard Shipping</h6>
                                                            <p className="color-class">
                                                                $ {shippingDetails?.handling_fee}
                                                            </p>
                                                        </div>

                                                        <div
                                                            className="d-flex gap-2 justify-content-between align-items-center mb-2 py-2  border-bottom border-1">
                                                            <h6>Additional Item Shipping</h6>
                                                            <p className="color-class">
                                                                $ {shippingDetails?.additional_item_fee}
                                                            </p>
                                                        </div>

                                                        {/*<div*/}
                                                        {/*    className="d-flex gap-2 justify-content-between align-items-center mb-2 py-2  border-bottom border-1">*/}
                                                        {/*    <h6>Expedited Delivery Fee</h6>*/}
                                                        {/*    <p className="color-class">*/}
                                                        {/*        $ {shippingDetails?.expedited_fee}*/}
                                                        {/*    </p>*/}
                                                        {/*</div>*/}

                                                        <div
                                                            className="d-flex gap-2 justify-content-between align-items-center mb-2 py-2">
                                                            <h6>Processing Fee</h6>
                                                            <p className="color-class">
                                                                $ {shippingDetails?.processing_fee}
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div
                                                            className="d-flex gap-2 justify-content-between align-items-center mb-2 py-2  border-bottom border-1">
                                                            <h6>Expedited Shipping</h6>
                                                            <p className="color-class">
                                                                $ {shippingDetails?.expedited_fee}
                                                            </p>
                                                        </div>

                                                        <div
                                                            className="d-flex gap-2 justify-content-between align-items-center mb-2 py-2  border-bottom border-1">
                                                            <h6>Additional Expedited Shipping</h6>
                                                            <p className="color-class">
                                                                $ {shippingDetails?.additional_expedited_fee}
                                                            </p>
                                                        </div>

                                                        <div
                                                            className="d-flex gap-2 justify-content-between align-items-center mb-2 py-2">
                                                            <h6>Gift Packing Fee</h6>
                                                            <p className="color-class">
                                                                $ {shippingDetails?.gift_packing_fee}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/*Related Section*/}
                        <section
                            className={`featured bg-light ${
                                relatedProducts?.length === 0 ? "hide" : ""
                            }`}
                            style={{paddingTop: "40px"}}
                        >
                            <div className="container footerpadding mx-auto">
                                <div
                                    className={`wrapper-header text-center mb-4 ${
                                        relatedProducts?.product?.length < 0 ? "hide" : ""
                                    }`}
                                >
                                    {/*<h4 className="grey-text wow animated fadeInUp">More from this Seller</h4>*/}
                                    <div
                                        className="wow animated bestsellerproduct fadeInUp"
                                        data-wow-delay="0.3s"
                                    >
                                        Related Items
                                    </div>
                                    <p className="wow animated fadeInUp"></p>
                                </div>

                                {/* <!-- product  --> */}
                                <div className="grid-container">
                                    {relatedProducts.product?.map((rp, index) => {
                                        return (
                                            <div
                                                key={index}
                                                className="position-relative mb-4 bg-white"
                                            >
                                                <div className="wishlist-icon">
                                                    <i
                                                        className={
                                                            rp?.status
                                                                ? "fa fa-heart hearticoncolor selected"
                                                                : "fa fa-heart"
                                                        }
                                                        aria-hidden="true"
                                                        wish-className="true"
                                                        id={`heartIcon-${index}`}
                                                        style={{cursor: "pointer"}}
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            e.stopPropagation();
                                                            addToWishList(rp.id, index);
                                                        }}
                                                    ></i>
                                                </div>
                                                <Link
                                                    to={`/shop/${rp.pro_slug}-${rp?.id}`}
                                                    className=""
                                                    // onClick={handleScrollToTop}
                                                >
                                                    <div className="">
                                                        <div className="image-container">
                                                            <img
                                                                src={rp.main_image}
                                                                className="img-size"
                                                                title=""
                                                                alt=""
                                                            />
                                                        </div>

                                                        {/* <!-- product-count  --> */}
                                                        <div
                                                            className="text-center px-1"
                                                            style={{
                                                                padding: "20px 0",
                                                                height: "fit-content",
                                                            }}
                                                        >
                                                            {rp.pro_name?.length > 22 ? (
                                                                <p className="fw-bold mb-1"
                                                                   style={{
                                                                       color: "#252B42",
                                                                       fontFamily: "Montserrat",
                                                                   }}
                                                                   spiketip-title={rp.pro_name?.slice(0, 120)}
                                                                   spiketip-pos="bottom-left"
                                                                   spiketip-color="black"
                                                                    // spiketip-length="xl"
                                                                >
                                                                    {rp.pro_name?.slice(0, 22)}
                                                                    <small className="" style={{fontSize: "12px"}}>
                                                                        ...
                                                                    </small>
                                                                </p>
                                                            ) : (
                                                                <p className="fw-bold mb-1"
                                                                   style={{
                                                                       color: "#252B42",
                                                                       fontFamily: "Montserrat",
                                                                   }}>
                                                                    {rp?.pro_name}
                                                                </p>
                                                            )}

                                                            <div
                                                                className="categoryCartDes my-1 px-2 des-container text-wrap oneLine">
                                                                <small
                                                                    className="oneLine d-none d-sm-block"
                                                                    style={{
                                                                        color: "#737373",
                                                                        fontFamily: "Montserrat",
                                                                        fontWeight: "400",
                                                                        fontSize: "14px",
                                                                    }}
                                                                >
                                                                    {rp.short_desc.length > 25
                                                                        ? rp.short_desc.slice(0, 25) + "..."
                                                                        : rp.short_desc}
                                                                </small>
                                                                <small
                                                                    className="oneLine d-sm-none"
                                                                    style={{
                                                                        color: "#737373",
                                                                        fontFamily: "Montserrat",
                                                                        fontWeight: "400",
                                                                        fontSize: "14px",
                                                                    }}
                                                                >
                                                                    {rp.short_desc.length > 10
                                                                        ? rp.short_desc.slice(0, 10) + "..."
                                                                        : rp.short_desc}
                                                                </small>
                                                            </div>
                                                            <div className="price">
                                                                <div
                                                                    className="grey-text"
                                                                    style={{fontFamily: "Montserrat"}}
                                                                >
                                                                    {!rp?.sale_price &&
                                                                        <span className="text-warning">
                                                                        ${rp.regular_price}
                                                                      </span>}

                                                                    {rp?.sale_price && <div
                                                                        className='d-flex gap-2 justify-content-center align-items-center'>
                                                                        <p className="text-warning">
                                                                            ${rp.sale_price}
                                                                        </p>
                                                                        <p
                                                                            className="strike-through">
                                                                            ${rp.regular_price}
                                                                        </p>
                                                                    </div>}

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </section>
                    </main>
                </>
            )}
        </>
    );
};

export default SingleProduct;
