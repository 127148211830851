import React, {useEffect} from "react";
import "./blog.css";
import {Helmet} from "react-helmet";
import {get_meta} from "../../store/home";
import author from "../../assets/brand/mangtum.png";
import BlogCard from "./BlogCard";
import {useDispatch, useSelector} from "react-redux";
import {getBlogs} from "../../store/product";
import {Link, useLocation} from "react-router-dom";
import SiteLoader from "../../SiteLoader";
import moment from "moment";

const Blog = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const category = queryParams.get("category");

    // console.log(category);
    const {blogs, loaderStatus} = useSelector((state) => state.prodLi);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getBlogs(category));
    }, [category]);

    // META TAGS START
    useEffect(() => {
        dispatch(get_meta("blog"));
    }, ["blog"]);
    const state = useSelector((state) => state.home);
    const metaTags = state.allmeta;
    const siteUrl = window.location.href;
    // META TAGS END

    // console.log(metaTags?.meta_data?.page_schema);
    return (
        <>
            <Helmet>
                {metaTags?.meta_data?.page_schema && (
                    <script type="application/ld+json">{`${metaTags?.meta_data?.page_schema}`}</script>
                )}
                <meta charSet="utf-8"/>
                <title>{metaTags?.meta_data?.meta_title}</title>
                <meta
                    name="description"
                    content={metaTags?.meta_data?.meta_description}
                />
                <meta name="keywords" content={metaTags?.meta_data?.meta_keywords}/>
                <meta name="twitter:card" content="summary_large_image"/>
                <meta name="twitter:url" content={siteUrl}/>

                <meta name="twitter:title" content={metaTags?.meta_data?.og_title}/>
                <meta
                    name="twitter:description"
                    content={metaTags?.meta_data?.og_description}
                />
                <meta name="twitter:image" content={metaTags?.meta_data?.og_image}/>

                <meta property="og:type" content={metaTags?.meta_data?.og_type}/>
                <meta property="og:url" content={siteUrl}/>
                <meta property="og:title" content={metaTags?.meta_data?.og_title}/>
                <meta
                    property="og:description"
                    content={metaTags?.meta_data?.og_description}
                />
                <meta property="og:image" content={metaTags?.meta_data?.og_image}/>
            </Helmet>
            {/* <ThemeBreadcrumb title="HomeBlog" current_route="HomeBlog" /> */}
            {loaderStatus ? (
                <SiteLoader status={loaderStatus}/>
            ) : (
                <main className="container single-product-section pointer">
                    {blogs?.title ? (
                        <>
                            <section className="row align-items-center my-5">
                                <Link to={`/blog/${blogs?.slug}`} className="col-lg-5 ">
                                    <img
                                        className="mx-auto blog-banner w-100"
                                        style={{
                                            borderRadius: "7px",
                                            maxHeight: "385px",
                                        }}
                                        src={blogs?.coverimage}
                                        alt=""
                                    />
                                </Link>
                                <div className="col-lg-7 mt-4 mt-lg-0">
                                    {blogs?.blog_related_categories?.length > 0 && (
                                        <div className="d-flex align-items-center flex-wrap gap-3 mb-4">
                                            {blogs?.blog_related_categories?.map((cate, index) => (
                                                <Link to={`/blog?category=${cate?.slug}`}>
                                                    <button key={index} className="blog_cate_btn">
                                                        {cate?.cate_name}
                                                    </button>
                                                </Link>
                                            ))}
                                        </div>
                                    )}

                                    <Link
                                        to={`/blog/${blogs?.slug}`}
                                        className="blog_banner_title"
                                    >
                                        <h1 className="blog_banner_title">{blogs?.title}</h1>
                                    </Link>
                                    <p
                                        className="banner_title_content"
                                        dangerouslySetInnerHTML={{__html: blogs?.content}}
                                    ></p>

                                    <div className="d-flex align-items-center gap-2">
                                        <img
                                            className="rounded-circle"
                                            style={{width: "30px", height: "30px"}}
                                            src={author}
                                            alt=""
                                        />
                                        <p className="blog_author_text">
                                            mangtum -{" "}
                                            {moment(blogs?.created_at).format("MMM DD, YYYY")}
                                        </p>
                                    </div>
                                </div>
                            </section>

                            <section className="row my-5">
                                {blogs?.blog_post?.map((blog, index) => (
                                    <BlogCard key={index} blog={blog} author={author}/>
                                ))}
                            </section>
                        </>
                    ) : (
                        <div className="d-flex justify-content-center align-items-center w-100 h-100 mt-5">
                            <h4>No blog Found</h4>
                        </div>
                    )}
                </main>
            )}
        </>
    );
};

export default Blog;
