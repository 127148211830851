import React, {useState, useEffect} from "react";
import {Link} from "react-router-dom";
import {useDispatch} from "react-redux";
import {checkSubscribe} from "../../store/home";
import {images} from "../../utils/images";
import "./ResponsiveFooter.css";
import upArrow from "../../assets/icons/up-arrow.png";

const paymentIcons = [
    '/assets/img/cards/p1.svg',
    '/assets/img/cards/p2.svg',
    '/assets/img/cards/p3.svg',
    '/assets/img/cards/p4.svg',
    '/assets/img/cards/p5.svg',
    '/assets/img/cards/p6.svg',
    '/assets/img/cards/p7.svg',
    '/assets/img/cards/p8.svg',
    '/assets/img/cards/p9.svg',
    '/assets/img/cards/p10.svg',
]

const Footer = () => {
    const dispatch = useDispatch();
    // const subscribe_email = useSelector((state) => state.home.Subscribe);
    // const subscribemsg = subscribe_email?.msg;
    const [errors_subsemail, setErrors_subsemail] = useState({});
    const handleSubmitSubsemail = (e) => {
        e.preventDefault();

        // Define errors object
        var errors = {};
        const emailInput = e.target.elements.email;
        const emailRegex =
            /^(|(([A-Za-z0-9]+_+)|([A-Za-z0-9]+\-+)|([A-Za-z0-9]+\.+)|([A-Za-z0-9]+\++))*[A-Za-z0-9]+@((\w+\-+)|(\w+\.))*\w{1,63}\.[a-zA-Z]{2,6})$/i;
        if (!emailInput.value || !emailRegex.test(emailInput.value)) {
            errors = {...errors, email: "Please enter a valid email!"};

            emailInput.focus();
        }
        if (Object.keys(errors).length === 0) {
            const formData = new FormData(e.target);
            dispatch(checkSubscribe(formData));
            setErrors_subsemail({});
        } else {
            setErrors_subsemail(errors);
        }
    };

    const handleScrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    const [year, setYear] = useState(new Date().getFullYear());

    useEffect(() => {
        const interval = setInterval(() => {
            setYear(new Date().getFullYear());
        }, 1000 * 60 * 60 * 24); // Update every day

        return () => {
            clearInterval(interval);
        };
    }, []);

    //console hello world
    return (
        <footer className="pt-5 mt-5 pb-5">
            <div className="ftr-head">
                <div className="container mx-auto row justify-content-center align-items-center">
                    <div className="navbarLogo col-md-7 col-6">
                        <img
                            width={159}
                            height={35}
                            src={images["logo.webp"]}
                            className="img-fluid"
                            title=""
                            alt=""
                        />
                    </div>
                    <div className="social-icons col-md-3 col-6">
                        <a href="https://www.facebook.com/profile.php?id=100063585218866">
                            <img width={24} height={24} src={images["facebook.svg"]} alt=""/>
                        </a>
                        <a href="https://www.instagram.com/mangtum_shop/">
                            <img
                                width={24}
                                height={24}
                                src={images["instagram.svg"]}
                                alt=""
                            />
                        </a>
                        <a href="https://twitter.com/mangtumllc">
                            <img width={24} height={24} src={images["twitter.svg"]} alt=""/>
                        </a>
                        <a href="https://www.pinterest.com/mangtum_shop/">
                            <img
                                width={24}
                                height={24}
                                src={images["pinterest.svg"]}
                                alt=""
                            />
                        </a>
                        {/* <a href="#link"><i className="fa fa-twitter-square" aria-hidden="true"></i></a> */}
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row justify-content-center hide-footer">
                    <div className="col-lg-7 col-md-12">
                        <div className="footer-row">
                            <div className="footer-card">
                                {/* <h4>Useful Links</h4> */}
                                <h4>Company</h4>
                                <ul>
                                    <li>
                                        <Link to="/terms-and-condition" onClick={handleScrollToTop}>
                                            Terms And Conditions
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/privacypolicy" onClick={handleScrollToTop}>
                                            Privacy Policy
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/return-policy" onClick={handleScrollToTop}>
                                            Return Policy
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/shipping-policy" onClick={handleScrollToTop}>
                                            Shipping Policy
                                        </Link>
                                    </li>
                                    {/* <li>
                    <Link to="/help-topics" onClick={handleScrollToTop}>
                      Help Topics
                    </Link>
                  </li> */}
                                    <li>
                                        <Link to="/blog" onClick={handleScrollToTop}>
                                            Blog
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                            <div className="footer-card">
                                <h4>Our Links</h4>
                                <ul>
                                    <li>
                                        <Link to="/contact" onClick={handleScrollToTop}>
                                            Contact Us
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/about" onClick={handleScrollToTop}>
                                            About Us
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/faq" onClick={handleScrollToTop}>
                                            FAQ
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/seller" onClick={handleScrollToTop}>
                                            Seller
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                            <div className="footer-card">
                                <h4>Enquiry</h4>
                                <ul>
                                    <li>
                                        <a href="tel: +1 980-228-8715">
                                            <i className="fa fa-phone text-color1"></i> +1
                                            980-228-8715 (Text & WhatsApp only)
                                        </a>
                                    </li>
                                    <li>
                                        <a href="mailto: support@mangtum.com">
                                            <i className="fa fa-envelope text-color1"></i>{" "}
                                            support@mangtum.com
                                        </a>
                                    </li>
                                    <li>
                                        <a href="address: Charlotte, North Carolina, USA">
                                            <i className="fa fa-location-arrow text-color1"></i>{" "}
                                            Charlotte, North Carolina, USA
                                        </a>
                                    </li>
                                    {/* <li><a href="#link">Customers</a></li> */}
                                    {/* <li><a href="#link">API</a></li> */}
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-5 col-md-12">
                        <div className="subscribe footer-card">
                            <h4>Get In Touch</h4>
                            <div className="form-subscribe">
                                <form onSubmit={handleSubmitSubsemail}>
                                    <div className="formflex">
                                        {/* <input type="text" name="subscribe_email" className="form-control" onChange={handelsubscribe} placeholder="Your Email"/> */}
                                        <input
                                            type="email"
                                            name="email"
                                            className="form-control subform"
                                            placeholder="Enter Email"
                                            style={{textAlign: "left"}}
                                        />
                                        <button className="subbtn" type="submit">
                                            Subscribe
                                        </button>
                                        {" "}
                                        <br/>
                                    </div>
                                    {errors_subsemail.email && (
                                        <p className="register_error">{errors_subsemail.email}</p>
                                    )}
                                </form>
                                <div>
                                    <h4 className='mb-2 mt-4'>Payment Methods</h4>
                                    <div className="payment-icons">
                                        {paymentIcons.map((paymentIcon) => (
                                            <figure key={paymentIcon}>
                                                <img src={window.location.origin + paymentIcon} alt=''/>
                                            </figure>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- copyright --> */}
                <div className=" copyright">
                    {/* If you have any questions, &nbsp;
          <a href="/contact"><strong   style={{ 'fontWeight':'500'}}>Contact us.</strong></a> 
          <br/> */}
                    &copy; {year} | Mangtum-LLC, All Rights Reserved.
                </div>
            </div>
            <button
                className="back-to-top d-flex align-items-center bg-warning justify-content-center bg-theme"
                onClick={handleScrollToTop}
            >
                <img
                    style={{width: "1em", height: "1em"}}
                    src={upArrow}
                    alt="back to top"
                />
            </button>
        </footer>
    );
};

export default Footer;
