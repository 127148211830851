
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import {Provider} from "react-redux";
import store from "./store";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import "./Pages/Home/styles/responsive.css";
import "react-toastify/dist/ReactToastify.css";
import {ToastContainer} from "react-toastify";
import AuthProvider from "./context/AuthContext";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import ErrorBoundary from "./utils/ErrorBoundary";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <AuthProvider>
        <Provider store={store}>
            <ToastContainer autoClose={1500} hideProgressBar={true}/>
            <ErrorBoundary>
                <App/>
            </ErrorBoundary>
        </Provider>
    </AuthProvider>
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
