import React from "react";
import './error-boundary.css'

class ErrorBoundary extends React.Component {
    state = {hasError: false};

    static getDerivedStateFromError(error) {
        return {hasError: true};
    }

    componentDidCatch(error, errorInfo) {
        console.log(error, errorInfo)
    }

    render() {
        if (this.state.hasError) {
            return (
                <div className='error-container'>
                    <div className='error-body'>
                        <h3>Something went wrong!</h3>
                        <p>Oops! There was an error.<br/>Our team has been notified,
                            and we are
                            working on a resolution for you!
                        </p>
                        <a href='/' onClick={() => {
                            this.setState({hasError: false})
                            window.location.href = '/'
                        }}>
                            Back to Home Page
                        </a>
                    </div>
                </div>
            );
        } else {
            return this.props.children;
        }
    }
}

export default ErrorBoundary;