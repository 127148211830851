import React from "react";

const YourOrderSummary = ({cart}) => {
    return (
        <>
            <div className="order-section " style={{padding: "30px 0"}}>
                <div className="px-30">
                    <h3 className="order-title">Your Order</h3>
                    <hr style={{borderColor: "#bdbdbd", margin: "25px 0"}}/>
                </div>

                <div className="flex-bill px-30">
                    <div className="flex-list">
                        <h1 className="order-list">Quantity</h1>
                        <div className="order-price">
                            {cart.cart_count ? cart.cart_count : 0}
                        </div>
                    </div>
                    <div className="flex-list">
                        <h1 className="order-list">Subtotal</h1>
                        <div className="order-price">
                            ${cart.subtotal ? cart.subtotal : 0}
                        </div>
                    </div>
                    <div className="flex-list">
                        <h1 className="order-list">Discount</h1>
                        <div className="order-price">
                            ${cart.discount ? cart.discount : 0}
                        </div>
                    </div>
                    <div className="flex-list">
                        <h1 className="order-list">Shipping & Processing</h1>
                        <div className="order-price">
                            ${cart.shipping_charge ? cart.shipping_charge : 0}
                        </div>
                    </div>
                    <div className="flex-list">
                        <h1 className="order-list">Express Charges</h1>
                        <div className="order-price">
                            ${cart.express_charge ? cart.express_charge : 0}
                        </div>
                    </div>
                    <div className="flex-list">
                        <h1 className="order-list">Gift Charges</h1>
                        <div className="order-price">
                            ${cart.gift_charge ? cart.gift_charge : 0}
                        </div>
                    </div>
                    <div className="flex-list">
                        <h1 className="order-list">Tax</h1>
                        <div className="order-price">
                            ${cart.tax_amount ? cart.tax_amount : 0}
                        </div>
                    </div>

                    {/*<div className="flex-list">*/}
                    {/*    <h1 className="order-list">Delivery Charges</h1>*/}
                    {/*    <div*/}
                    {/*        className="order-price">${cart.delivery_charge ? cart.delivery_charge : 0}</div>*/}
                    {/*</div>*/}
                    <hr style={{borderColor: "#bdbdbd", margin: "25px 0"}}/>
                    <div className="flex-list mb-0">
                        <h1 className="total">Total</h1>
                        <h1 className="total">${cart.total ? cart.total : 0}</h1>
                    </div>
                </div>
            </div>
        </>
    );
};

export default YourOrderSummary;
