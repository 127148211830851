import {useEffect, useRef} from "react";
import Slider from "react-slick";
import {Link, useLocation} from "react-router-dom";

export function LazyImage({img, smallImage}) {
    const ref = useRef(null);

    useEffect(() => {
        function loaded() {
            ref.current.parentNode.classList.add("loaded")
        }

        if (ref.current.complete) {
            loaded()
        } else {
            ref.current.addEventListener("load", loaded)
        }
    }, [])

    return (
        <picture className="w-100 position-relative blurred-img d-block"
                 style={{minHeight: "300px"}}>
            <source media="(max-width: 992px)"
                    srcSet={`${smallImage} 2x,
                        ${smallImage} 3x,
                         ${smallImage}`}/>
            <img
                ref={ref}
                alt=''

                sizes="(max-width: 479px) 100vw,
                        (max-width: 639px) 100vw,
                        (max-width: 899px) 100vw,
                        (max-width: 1199px) 100vw,
                        100vw"

                srcSet={`
                        ${img} 480w,
                        ${img} 640w,
                        ${img} 900w,
                        ${img} 1200w,
                        ${img} 1400w,
                        ${img} 2800w
                `}
                style={{minHeight: "300px"}}
                loading='lazy'
                src={img}
                className="img-fluid w-100 object-fit-cover"
            />
        </picture>
    )
}

const BannerCarousel = ({images}) => {

    const sliderRef = useRef(null);

    const next = () => {
        sliderRef.current.slickNext();
    };

    const previous = () => {
        sliderRef.current.slickPrev();
    };

    const settings = {
        infinite: true,
        speed: 1500,
        lazyLoad: true,
        autoplay: true,
        autoplaySpeed: 3000,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
    };

    const webImages = images?.filter(image => image.is_mobile === 0);
    const mobileImage = images?.filter(image => image.is_mobile === 1);

    return (
        <>
            <Slider ref={sliderRef} {...settings}>
                {webImages?.map((img, index) => {
                    return <LazyImage img={img.image} key={index} smallImage={mobileImage[index].image}/>
                })}
            </Slider>

            {images?.length > 1 && (
                <div
                    className="position-absolute w-100 mx-auto d-flex align-items-center justify-content-between"
                    style={{top: "45%", zIndex: 55}}
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="2"
                        stroke="currentColor"
                        className="text-theme pointer"
                        style={{width: "50px", backgroundColor: "transparent"}}
                        onClick={previous}
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M15.75 19.5L8.25 12l7.5-7.5"
                        />
                    </svg>

                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="2"
                        stroke="currentColor"
                        className="text-theme pointer"
                        style={{width: "50px", backgroundColor: "transparent"}}
                        onClick={next}
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M8.25 4.5l7.5 7.5-7.5 7.5"
                        />
                    </svg>
                </div>
            )}
        </>
    );
};

export default BannerCarousel;

