import React from "react";
import {Link} from "react-router-dom";
import {images} from "../utils/images";
import "spiketip-tooltip/spiketip.min.css";

const ProductCard = ({index, product, addToWishList, isGrid}) => {
    return (
        <div
            key={index}
            className={`${
                isGrid ? "col-12" : "col-lg-3 col-md-6 col-sm-6 col-6"
            }`}
        >
            <Link to={`/shop/${product.pro_slug}-${product.id}`} keys={product.id}>
                <div
                    className={isGrid ? "list-item" : "item  me-2 product-card mb-3"}
                >
                    <div className="mt-0">
                        <div
                            className={`position-relative ${isGrid ? "row-image-size" : "image-container"}`}
                            style={{
                                width: `${!isGrid ? "100%" : "200px"}`,
                            }}
                        >
                            <div className="wishlist-icon">
                                <i
                                    className={
                                        product?.wishlist_status
                                            ? "fa fa-heart hearticoncolor selected"
                                            : "fa fa-heart"
                                    }
                                    aria-hidden="true"
                                    wish-class
                                    id={`heartIcon-${index}`}
                                    style={{cursor: "pointer"}}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        addToWishList(product.id, index);
                                    }}
                                ></i>
                            </div>
                            <img
                                src={product.main_image}
                                className={`img-size`}
                                title=""
                                alt=""
                                style={{
                                    maxHeight: `${!isGrid && "100%"}`,
                                }}
                                // onError={(e) => (e.target.src = images["defaultImg.png"])}
                            />
                        </div>
                        {/* <!-- product-cont  --> */}
                        <div
                            style={{
                                width: `${isGrid ? "90%" : "auto"}`,
                            }}
                            className={` ${
                                isGrid ? "p-0 product-content-row" : "py-3 product-content"
                            }`}
                        >
                            <div className={`category_title`}>
                                {product.pro_name?.length > 22 ? (
                                    <p
                                        spiketip-title={product.pro_name?.slice(0, 120)}
                                        spiketip-pos="bottom-left"
                                        spiketip-color="black"
                                    >
                                        {product.pro_name?.slice(0, 22)}
                                        <small className="" style={{fontSize: "12px"}}>
                                            ...
                                        </small>
                                    </p>
                                ) : (
                                    product?.pro_name
                                )}
                            </div>
                            <span className='categoryCartDes' style={{fontWeight: '600'}}>
                                    {product.short_desc?.length > 25
                                        ? product.short_desc?.slice(0, 25) + "..."
                                        : product.short_desc}
                            </span>
                            <div className="price">
                                <div className="grey-text">

                                    {!product?.sale_price &&
                                        <span className={`text-warning ${isGrid ? "m-0" : ""}`}>
                                            ${product.regular_price}
                                          </span>
                                    }

                                    {product?.sale_price &&
                                        <div className='d-flex justify-content-center align-items-center gap-2'>
                                            <p className="text-warning">
                                                ${product.sale_price}
                                            </p>
                                            <p
                                                className="strike-through">
                                                ${product.regular_price}
                                            </p>
                                        </div>}
                                </div>
                            </div>
                            {isGrid && (
                                <button className="add-cart d-flex">
                                    <i className="fa fa-shopping-cart"></i>
                                    <span>Add to Cart </span>
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </Link>
        </div>
    );
};

export default ProductCard;
