import '../styles/video.css';
import {Link} from "react-router-dom";
import {useEffect, useRef} from "react";

export default function Video({contentHtml}) {

    const ref = useRef(null);

    useEffect(() => {
        if(ref.current){
            ref.current.play();
        }
    }, [ref]);

    return (
        <div className='video-container'>
            <div
                dangerouslySetInnerHTML={{__html: contentHtml}} className='video-right'/>
            <div className='video-middle'>
                <video
                    ref={ref}
                    width="320"
                    height="240"
                    loop
                    muted
                    controls
                    autoPlay="autoplay"
                    playsInline
                    type="video/mp4"
                    src="/assets/video/video2.mp4"
                />
            </div>
            <div className='video-left'>
                <img
                    src='/assets/img/marketplace2.png'
                    alt=''/>
            </div>
        </div>
    )
}