import Accordion from "react-bootstrap/Accordion";

const items = [
  {
    id: 1,
    question:
      "Illo magnam at dolore ad enim fugiat ut maxime facilis autem, nulla cumque",
    answer:
      "Illo magnam at dolore ad enim fugiat ut maxime facilis autem, nulla cumque quis commodi eos nisi unde soluta, ipsa eius aspernatur sint atque! illo magnamat dolore and fugiat ut maximum facilis autem maximum facilisautem, cumque",
  },
  {
    id: 2,
    question:
      "Illo magnam at dolore ad enim fugiat ut maxime facilis autem, nulla cumque",
    answer:
      "Illo magnam at dolore ad enim fugiat ut maxime facilis autem, nulla cumque quis commodi eos nisi unde soluta, ipsa eius aspernatur sint atque! illo magnamat dolore and fugiat ut maximum facilis autem maximum facilisautem, cumque",
  },
  {
    id: 3,
    question:
      "Illo magnam at dolore ad enim fugiat ut maxime facilis autem, nulla cumque",
    answer:
      "Illo magnam at dolore ad enim fugiat ut maxime facilis autem, nulla cumque quis commodi eos nisi unde soluta, ipsa eius aspernatur sint atque! illo magnamat dolore and fugiat ut maximum facilis autem maximum facilisautem, cumque",
  },
  {
    id: 4,
    question:
      "Illo magnam at dolore ad enim fugiat ut maxime facilis autem, nulla cumque",
    answer:
      "Illo magnam at dolore ad enim fugiat ut maxime facilis autem, nulla cumque quis commodi eos nisi unde soluta, ipsa eius aspernatur sint atque! illo magnamat dolore and fugiat ut maximum facilis autem maximum facilisautem, cumque",
  },
  {
    id: 5,
    question:
      "Illo magnam at dolore ad enim fugiat ut maxime facilis autem, nulla cumque",
    answer:
      "Illo magnam at dolore ad enim fugiat ut maxime facilis autem, nulla cumque quis commodi eos nisi unde soluta, ipsa eius aspernatur sint atque! illo magnamat dolore and fugiat ut maximum facilis autem maximum facilisautem, cumque",
  },
];

function BlogAccording({ faqs }) {
  return (
    <Accordion
      defaultActiveKey={0}
      // alwaysOpen
      style={{
        maxWidth: "1000px",
        margin: "0 auto",
        backgroundColor: "transparent",
      }}
    >
      {faqs?.map((item, index) => (
        <Accordion.Item
          className="according-item"
          key={index}
          eventKey={index + 1}
        >
          <Accordion.Header>
            <span>Q</span> <p>{item?.question}</p>
          </Accordion.Header>
          <Accordion.Body className="d-flex align-items-start">
            <span>A</span>{" "}
            <small style={{ marginTop: "10px" }}>
              <p dangerouslySetInnerHTML={{ __html: item?.answer }}></p>
            </small>
          </Accordion.Body>
        </Accordion.Item>
      ))}
    </Accordion>
  );
}

export default BlogAccording;
