import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {mgtApi} from "./axios";
import {convertPayloadToQueryString} from "../utils/helper";
import {getHeaderData, homeActions} from "./home";
import {pagesActions} from "./pages";

export const getFiterResponse = createAsyncThunk(
    "product/getFiterResponse",
    async ({attr, prices, category, keyword, thunkAPI}) => {
        const str = await convertPayloadToQueryString(attr);
        const newKeyword = keyword || category;
        try {
            const {data} = await mgtApi.post(
                `/productsearch?keyword=${newKeyword}&price_between=${prices[0]},${prices[1]}&${str}`,
                {
                    page: 1,
                    keyword: newKeyword,
                }
            );
            return [data, {str, prices, category}];
        } catch (error) {
            // console.log(error);
        }
    }
);

//show the loader
const intialProductState = {
    search_item: null,
    search_total: 0,
    data: [],
    search_page: 1,
    singleProduct: null,
    productByCategory: [],
    plength: [],
    prodNotFound: false,
    galleryimages: [],
    cate_id: {},
    filterList: [],
    productVariations: [],
    variation_status: false,
    beadFilter: [],
    shapeFilter: [],
    styleFilter: [],
    loaderStatus: true,
    related_prod: [],
    vendorInfo: [],
    wishList: [],
    autosuggestProduct: [],
    filterEnabled: false,
    beadCheckboxState: [],
    userCartProductCount: 1,
    statuskey: null,
    message: null,
    filter_array_products: [],
    searchParams: {},
    filterArray: [],
    filterQuery: "",
    products: [],
    storeProducts: [],
    fetchPermit: false,
    priceRange: [0, 0],
    minMaxInputPrice: [0, 0],
    keyword: "",
    total_page: 1,
    store_total_page: 0,
    categoryId: "",
    subCategoryId: "",
    sub_cate_meta: null,
    cate_meta: null,
    blogs: [],
    singleBlog: null,
};

const productSlice = createSlice({
    name: "product",
    initialState: intialProductState,
    reducers: {
        productlistsbySubcatSuccess(state, action) {
            state.data = action.payload;
            state.search_total = action.payload.total_pages;
            state.filterEnabled = false;
            state.beadCheckboxState = [];
        },

        singleProductDetailSuccess(state, action) {
            state.singleProduct = action.payload;
            if (state.singleProduct?.product?.gallery?.length > 0) {
                state.galleryimages = state.singleProduct?.product?.gallery; //Object.entries(JSON.parse(state.singleProduct.product.gallery));
            } else {
                state.galleryimages = [state.singleProduct?.product?.main_image];
            }
            state.userCartProductCount = 1;
        },
        productByCategorySuccess(state, action) {
            state.productByCategory = action.payload;
            state.search_total = action.payload.total_pages;
            state.filterEnabled = false;
            state.beadCheckboxState = [];
        },
        productByCategoryFailure(state, action) {
            state.productByCategory = action.payload;
        },
        productByCategorycount(state, action) {
            state.plength = action.payload;
        },
        category_id(state, action) {
            state.cate_id = action.payload;
        },
        filterListsSuccess(state, action) {
            state.filterList = action.payload;
        },
        filterListsFailure(state, action) {
            state.filterList = action.payload;
        },
        product_variationsList_success(state, action) {
            state.productVariations = action.payload;
            state.variation_status = true;
        },
        product_variationsList_failure(state, action) {
            state.productVariations = action.payload;
            state.variation_status = false;
            state.loaderStatus = false;
        },
        bead_filter(state, action) {
            state.beadFilter = action.payload;
        },
        filterProducts(state, action) {
            state.filter_array_products = action.payload;
            state.search_total = action.payload.total_pages;
            state.filterEnabled = true;
        },
        updatePageNumber(state, action) {
            state.search_page = action.payload.page;
            state.slug = action.payload.slug;
            state.user_id = action.payload.user_id;
        },
        updateLoaderStatus(state, action) {
            state.loaderStatus = action.payload;
        },
        relatedProducts(state, action) {
            state.related_prod = action.payload;
        },
        vendorInfoSuccess(state, action) {
            state.vendorInfo = action.payload;
            state.vendorid = action.payload.vendorid;
        },
        wishAddSuccess(state, action) {
            state.statuskey = "success";
            state.message = "Added to Wishlit";
            state.wishlist_status = action.payload;
        },
        wishRemoveSuccess(state, action) {
            state.statuskey = "success";
            state.message = "Added to Wishlit";
            state.wishlist_status = action.payload;
        },
        fetchWishlist(state, action) {
            state.wishList = action.payload;
        },
        fetch_suggestList_product(state, action) {
            state.autosuggestProduct = action.payload;
        },
        updateBeadCheckboxFilterSuccess(state, action) {
            state.beadCheckboxState = action.payload;
        },
        userCartProductCount(state, action) {
            state.userCartProductCount = action.payload;
        },
        wishAddFailure(state, action) {
            state.status = "error";
            state.message = "Failed to add wishlist";
        },
        setFilterUniqueArray(state, action) {
            const storeArray = JSON.parse(JSON.stringify(state.filterArray));
            const isExist = storeArray?.find(
                (item) => item?.attribute_value === action.payload.attribute_value
            );
            if (isExist) {
                state.filterArray = storeArray?.filter(
                    (item) => item.attribute_value !== isExist.attribute_value
                );
            } else {
                state.filterArray.push(action.payload);
            }

            const newArray = JSON.parse(JSON.stringify(state.filterArray));
            const queryString = newArray
                .map(
                    (item) =>
                        `attr_id[]=${item.id}&attribute_value[]=${item.attribute_value}`
                )
                .join("&");
            state.filterQuery = queryString;
        },
        setFilterArray(state, action) {
            state.filterArray = action.payload;
        },
        setFilterQuery(state, action) {
            state.filterQuery = action.payload;
        },
        setProducts(state, action) {
            const storeArray = JSON.parse(JSON.stringify(state.filterArray));
            if (action.payload.length > 0) {
                state.products = [...state.products, ...action.payload];
                if (storeArray.length < 1) {
                    state.storeProducts = [...state.storeProducts, ...action.payload];
                }
            }
        },
        setAscProducts(state, action) {
            if (action.payload.length > 0) {
                let updatedProducts = [...state.products, ...action.payload]
                updatedProducts.sort((a, b) => parseFloat(a.sell_price) - parseFloat(b.sell_price))
                state.products = updatedProducts;
            }
        },
        setDscProducts(state, action) {
            if (action.payload.length > 0) {
                let updatedProducts = [...state.products, ...action.payload]
                updatedProducts.sort((a, b) => parseFloat(b.sell_price) - parseFloat(a.sell_price))
                state.products = updatedProducts;
            }
        },
        setClearProducts(state, action) {
            const storeProducts = JSON.parse(JSON.stringify(state.storeProducts));
            state.products = action.payload;
            if (storeProducts.length < 1) {
                state.storeProducts = action.payload;
            }
        },
        setStoreProducts(state, action) {
            state.storeProducts = action.payload;
        },
        setStoreProductsToMainProducts(state, action) {
            const storeProducts = JSON.parse(JSON.stringify(state.storeProducts));
            state.products = storeProducts;
        },
        setFetchPermit(state, action) {
            state.fetchPermit = action.payload;
        },
        setSearchParams(state, action) {
            state.searchParams = action.payload;
        },
        setPriceRange(state, action) {
            state.priceRange = action.payload;
        },
        setMinMaxInputPrice(state, action) {
            state.minMaxInputPrice = action.payload;
        },
        setKeyword(state, action) {
            state.keyword = action.payload;
        },
        setTotal_page(state, action) {
            const storeTotalPage = JSON.parse(JSON.stringify(state.store_total_page));
            state.total_page = action.payload;
            if (storeTotalPage < 1) {
                state.store_total_page = action.payload;
            }
        },
        setStoreTotalPage(state, action) {
            state.store_total_page = 0;
        },
        setCategoryId(state, action) {
            state.categoryId = action.payload;
        },
        setSubCategoryId(state, action) {
            state.subCategoryId = action.payload;
        },
        setSub_cate_meta(state, action) {
            state.sub_cate_meta = action.payload;
        },
        setCate_meta(state, action) {
            state.cate_meta = action.payload;
        },
        setBlogs(state, action) {
            state.blogs = action.payload;
        },
        setSingleBlog(state, action) {
            state.singleBlog = action.payload;
        }
    },
});
export const productActions = productSlice.actions;

export const getproductList = (sub_cat_slug) => {
    let userDetails = localStorage.getItem("userDetails");
    let data = JSON.parse(userDetails);
    let user_id = data?.ID;
    let page = 1;
    return async (dispatch) => {
        dispatch(pagesActions.setStatus(null));
        dispatch(productActions.setFilterQuery(""));
        dispatch(productActions.setKeyword(""));
        dispatch(productActions.setFilterArray([]));
        dispatch(productActions.setSearchParams({}));
        dispatch(
            productActions.updatePageNumber(
                {slug: sub_cat_slug, user_id: user_id, page: page},
                {loaderStatus: false}
            )
        );
        dispatch(productActions.updateLoaderStatus(true));
        try {
            await mgtApi
                .post("/product-by-subcategory-web", {
                    slug: sub_cat_slug,
                    user_id: user_id,
                    page: page,
                })
                .then((res) => {
                    if (
                        res?.data?.message === "Category not found" &&
                        res?.data?.status === "error"
                    ) {
                        dispatch(pagesActions.setStatus(404));
                        dispatch(productActions.updateLoaderStatus(true));
                        return;
                    }
                    if (res.data.status === "success") {
                        const {data, ...other} = res?.data;
                        const currentPath = window.location?.pathname;
                        if (other?.url && sub_cat_slug && currentPath) {
                            const urls = other?.url?.split("/");
                            const currectUrl = urls[urls?.length - 2];
                            if (!other?.url.includes(currentPath)) {
                                if (currectUrl) {
                                    window.location.replace(`/${currectUrl}/${sub_cat_slug}`);
                                }
                            }
                        }
                        dispatch(productActions.setSub_cate_meta(other));
                        dispatch(productActions.setStoreProducts([]));
                        dispatch(productActions.setStoreTotalPage(0));
                        dispatch(productActions.setTotal_page(res?.data?.total_pages));
                        dispatch(
                            productActions.setClearProducts(res?.data?.data?.products)
                        );
                        dispatch(productActions.setFetchPermit(true));
                        dispatch(productActions.productlistsbySubcatSuccess(res.data));
                    }
                    dispatch(productActions.updateLoaderStatus(false));
                });
        } catch (e) {
            dispatch(pagesActions.setStatus(e?.response?.status));
            dispatch(productActions.updateLoaderStatus(true));

        }
    };
};

export const getSingleProductDetail = (payload) => {
    return async (dispatch) => {
        dispatch(pagesActions.setStatus(null));
        dispatch(productActions.updateLoaderStatus(true));
        try {
            const res = await mgtApi.get("/single-products/" + payload);
            // console.log(res);
            if (res.data.status === "success") {
                dispatch(productActions.singleProductDetailSuccess(res.data.data));
            } else {
                dispatch(pagesActions.setStatus(404));
                dispatch(productActions.updateLoaderStatus(false));
            }
            dispatch(productActions.updateLoaderStatus(false));
        } catch (e) {
            dispatch(pagesActions.setStatus(404));
            dispatch(productActions.updateLoaderStatus(false));
        }
    };
};

export const getFilterProducts = (
    keyword,
    minPrice,
    maxPrice,
    categoryId,
    subCategoryId,
    filterQuery
) => {
    return async (dispatch) => {
        dispatch(productActions.updateLoaderStatus(true));
        try {
            const res = await mgtApi.post(
                `/productsearch?${
                    keyword && `keyword=${keyword}&`
                }price_between=${minPrice},${maxPrice}&page=1${
                    categoryId ? `&category_id=${categoryId}` : ''
                }${subCategoryId ? `&sub_category_id=${subCategoryId}` : ''}${
                    filterQuery && `&${filterQuery}`
                }`
            );

            dispatch(productActions.setClearProducts(res.data?.products));
            dispatch(productActions.setTotal_page(res.data.total_page));
            dispatch(productActions.updateLoaderStatus(false));
        } catch (e) {
        }
    };
};

export const getProductByCategory = (payload) => {
    const slug = payload;
    let userDetails = localStorage.getItem("userDetails");
    let data = JSON.parse(userDetails);
    let user_id = data?.ID;
    let page = 1;
    return async (dispatch) => {
        dispatch(pagesActions.setStatus(null));
        dispatch(productActions.setFilterQuery(""));
        dispatch(productActions.setKeyword(""));
        dispatch(productActions.setFilterArray([]));
        dispatch(productActions.setSearchParams({}));
        dispatch(
            productActions.updatePageNumber({
                slug: slug,
                user_id: user_id,
                page: page,
            })
        );
        dispatch(productActions.updateLoaderStatus(true));
        try {
            await mgtApi
                .post(
                    "/product-by-category-web",
                    {slug: slug, user_id: user_id, page: page},
                    {loaderStatus: false}
                )
                .then(async (res) => {
                    dispatch(pagesActions.setStatus(parseInt(res?.status)));
                    if (
                        res.data.message === "Category not found" &&
                        res.data.status === "error"
                    ) {
                        dispatch(pagesActions.setStatus(404));
                        dispatch(productActions.updateLoaderStatus(true));
                        return;
                    }
                    if (res.data.status === "success") {
                        const {data, ...other} = res?.data;
                        dispatch(productActions.setCate_meta(other));
                        dispatch(productActions.setStoreProducts([]));
                        dispatch(productActions.setStoreTotalPage(0));
                        dispatch(productActions.setTotal_page(res?.data?.total_pages));
                        dispatch(productActions.setFetchPermit(true));
                        dispatch(productActions.setClearProducts(res.data?.data?.products));
                        dispatch(productActions.productByCategorySuccess(res.data, page));
                        let catId = res.data.data.product["0"].cate_id;
                        dispatch(productActions.productByCategorycount(res.data.msg));
                        dispatch(productActions.category_id(catId));
                        dispatch(productActions.updateLoaderStatus(false));
                    }
                    if (res.data.status === "fail") {
                        dispatch(productActions.productByCategoryFailure(res.data.msg));
                        dispatch(productActions.updateLoaderStatus(false));
                    }
                    dispatch(productActions.updateLoaderStatus(false));
                });
        } catch (e) {
            dispatch(pagesActions.setStatus(e?.response?.status));
            dispatch(productActions.updateLoaderStatus(false));
        }
    };
};

export const getProductBySale = () => {
    let userDetails = localStorage.getItem("userDetails");
    let data = JSON.parse(userDetails);
    let user_id = data?.ID;
    let page = 1;
    return async (dispatch) => {
        dispatch(productActions.setFilterQuery(""));
        dispatch(productActions.setFilterArray([]));
        dispatch(homeActions.updatePageNumber({page: page}));
        dispatch(homeActions.updateLoaderStatus(true));
        dispatch(productActions.setCategoryId(""));
        dispatch(productActions.setSubCategoryId(""));

        try {
            await mgtApi
                .post(`/sale-product-list`, {page, user_id})
                .then((response) => {
                    if (response.data.status === "success") {
                        dispatch(productActions.setStoreProducts([]));
                        dispatch(productActions.setStoreTotalPage(0));
                        dispatch(
                            productActions.setPriceRange([
                                response?.data?.min_max_price?.min_price,
                                response?.data?.min_max_price?.max_price,
                            ])
                        );
                        dispatch(
                            productActions.setMinMaxInputPrice([
                                response?.data?.min_max_price?.min_price,
                                response?.data?.min_max_price?.max_price,
                            ])
                        );
                        dispatch(productActions.setTotal_page(response?.data?.total_page));
                        dispatch(productActions.setClearProducts(response?.data?.products));
                        dispatch(productActions.updateLoaderStatus(false));
                        dispatch(
                            homeActions.searchSuccess(response.data, page, {
                                loaderStatus: false,
                            })
                        );
                    } else {
                        dispatch(homeActions.searchFailure([]));
                    }
                    dispatch(productActions.updateLoaderStatus(false));
                    dispatch(homeActions.updateLoaderStatus(false));
                });
        } catch (e) {

        }
    };
};


export const getFiltercode = (payload) => {
    return async (dispatch) => {
        dispatch(productActions.setCategoryId(payload.category_id));
        payload.subcategory_id && dispatch(productActions.setSubCategoryId(payload.subcategory_id));
        try {
            await mgtApi
                .post("/get-filter-options", payload)
                .then((res) => {
                    if (res.data.status === "ok") {
                        dispatch(
                            productActions.setPriceRange([
                                res?.data?.min_max_price?.min_price,
                                res?.data?.min_max_price?.max_price,
                            ])
                        );
                        dispatch(
                            productActions.setMinMaxInputPrice([
                                res?.data?.min_max_price?.min_price,
                                res?.data?.min_max_price?.max_price,
                            ])
                        );
                        dispatch(productActions.filterListsSuccess(res.data));
                        dispatch(productActions.bead_filter(res.data.filter["0"]));
                    }

                    if (res.data.status === "error") {
                        dispatch(productActions.filterListsFailure(res.data.msg));
                    }
                });
        } catch {
        }
    };
};
export const getRelatedProducts = (product_id, subcat_id, user_id) => {
    return async (dispatch) => {
        try {
            const res = await mgtApi.post("/related-products-web", {
                user_id: user_id,
                product_id: product_id,
                subcategory_id: subcat_id,
            });

            if (res.data.status === "ok") {
                dispatch(productActions.relatedProducts(res.data));
            }
        } catch (e) {
            // console.log("Error while getting RelatedProducts: ", e);
        }
    };
};

export function getVendorInfo(store_slug, page_Id = 1, query = "") {
    //  vendor_id,shipping_profile
    return async function (dispatch) {
        const userDetails = JSON.parse(localStorage.getItem("userDetails"));
        dispatch(productActions.updateLoaderStatus(true));
        try {
            const response = await mgtApi.get(`/vendor-info-store/${store_slug}`, {
                params: {
                    page: page_Id,
                    search_query: query,
                },
            });
            if (response.data.status === "ok") {
                dispatch(productActions.vendorInfoSuccess(response.data));
            }

            dispatch(productActions.updateLoaderStatus(false));
        } catch (error) {
            dispatch(productActions.updateLoaderStatus(false));
        }
    };
}

export function addWishlist(product_id) {
    //  ////consolelog('reducer',vendorid);
    let userDetails = localStorage.getItem("userDetails");
    let data = JSON.parse(userDetails);
    let user_id = data?.ID;
    return async function (dispatch) {
        try {
            const response = await mgtApi.post("/addwishlist", {
                product_id: product_id,
                user_id: user_id,
            });
            dispatch(getHeaderData({user_id: user_id}));
            dispatch(getWishlistProduct());
            if (response.data.wishlist_status === 1) {
                // console.log("added to wishlist: ", response.data);
                dispatch(productActions.wishAddSuccess(response.data.wishlist_status));
            } else {
                dispatch(
                    productActions.wishRemoveSuccess(response.data.wishlist_status)
                );
            }
        } catch (error) {
            dispatch(productActions.wishAddFailure("Error Occurred"));
        }
    };
}

export const getWishlistProduct = (payload) => {
    let currentUser = JSON.parse(localStorage.getItem("userDetails"));
    let user_id = currentUser?.ID;
    return async (dispatch) => {
        dispatch(loaderStop());
        try {
            const response = await mgtApi.post("/user-wishlist", {
                user_id,
            });
            if (response.data.status === 1) {
                dispatch(productActions.fetchWishlist(response.data));
                dispatch(productActions.wishAddSuccess(1));
                dispatch(loaderStop());
            } else {
                dispatch(loaderStop());
            }
        } catch (error) {
        }
    };
};

export const getProductCartCount = (user_id, product_id) => {
    return async (dispatch) => {
        // dispatch(loaderStart());
        try {
            const response = await mgtApi.post("/user-check-prod-qty", {
                user_id: user_id,
                product_id: product_id,
            });
            //consolelog("cart count response", response.data);
            if (response.data.status === "ok") {
                dispatch(
                    productActions.userCartProductCount(response.data.avail_product_qty)
                );
                // dispatch(productActions.wishAddSuccess(1));
                // dispatch(loaderStop());
            } else {
                // dispatch(loaderStop());
            }
        } catch (error) {
            ////consoleerror(error);
        }
    };
};


export const loaderStop = () => {
    return async (dispatch) => {
        dispatch(productActions.updateLoaderStatus(false));
    };
};


export const getBlogs = (payload) => {
    let lastQuery = payload ? `/${payload}` : "";
    return async (dispatch) => {
        try {
            dispatch(productActions.updateLoaderStatus(true));
            await mgtApi.get(`/blogs${lastQuery}`).then((res) => {
                if (res?.data?.status === "success") {
                    dispatch(productActions.setBlogs(res?.data?.data));
                    dispatch(productActions.updateLoaderStatus(false));
                }
                dispatch(productActions.updateLoaderStatus(false));
            });
        } catch (e) {
            // console.log(e);
            dispatch(productActions.updateLoaderStatus(false));
        }
    };
};

export const getSingleBlog = (payload) => {
    return async (dispatch) => {
        try {
            dispatch(productActions.updateLoaderStatus(true));
            await mgtApi.get(`/blog/${payload}`).then((res) => {
                if (res?.data?.status === "success") {
                    dispatch(productActions.setSingleBlog(res?.data?.data));
                    dispatch(productActions.updateLoaderStatus(false));
                }
                dispatch(productActions.updateLoaderStatus(false));
            });
        } catch (e) {
            // console.log(e);
            dispatch(productActions.updateLoaderStatus(false));
        }
    };
};


export default productSlice.reducer;
