import Modal from 'react-bootstrap/Modal';

export default function GeneralModal({show, setShow, title, message}) {
    return (
        <Modal centered={true} show={show} onHide={setShow}>
            <Modal.Header className='border-bottom-0'>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{message}</Modal.Body>
            <Modal.Footer className='border-top-0'>
                <button className='shop-now py-2' onClick={() => setShow(false)}>
                    Ok
                </button>
            </Modal.Footer>
        </Modal>
    );
}
