import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, Navigate, useLocation } from "react-router-dom";

const ErrorPage = () => {
  const { status } = useSelector((state) => state.pages);
  const location = useLocation();

  useEffect(() => {
    const path = location?.pathname;
    if (path?.includes("/shop")) {
      if (path === "/shop" || path === "/shop/" || path === "/shop/shop") {
        return;
      }
      const endsWithSlash = location?.pathname?.endsWith("/");
      let newArray = location?.pathname;
      if (endsWithSlash) {
        newArray = location?.pathname?.slice(0, -1);
      }
      const segment = newArray?.split("/");
      const urlAdd = "/shop/" + segment[segment?.length - 1];
      if (urlAdd) {
        return <Navigate to={urlAdd} replace={true} />;
      }
    }
    return () => {};
  }, [location]);

  useEffect(() => {
    if (status !== 404) {
      fetch(
        `${process.env.REACT_APP_FILE_ACCESS_URL}/api/v1.0${location?.pathname}`
      );
    }
  }, []);

  return (
    <>
      <div className="error-page-container pt-4 font-theme mx-auto d-flex justify-content-center">
        <div className="text-center w-100 pt-sm-5">
          <h1 className="display-1 fw-bold">404</h1>
          <p className="fs-3 fw-semibold">
            <span>Oops!</span> Page not found.
          </p>
          <p className="lead">The page you’re looking for doesn’t exist.</p>
          <Link to="/" className="btn bg-theme text-white mt-4">
            Go Home
          </Link>
        </div>
      </div>
    </>
  );
};

export default ErrorPage;
