import React, {useState} from "react";
import {FaRegEyeSlash} from "react-icons/fa";
import {BsEye} from "react-icons/bs";

export default function Password({label, onChange, name="password"}){
    const [showPassword, setShowPassword] = useState(false);
    return(
        <div>
            <label htmlFor="password" className="login-label">
                {label}<span style={{color: "red"}}>*</span>
            </label>
            <div className="position-relative">
                <input
                    type={showPassword ? "" : "password"}
                    name={name}
                    onChange={onChange}
                    className="login-input"
                    placeholder={label}
                />
                <button className='btn-default' type="button" onClick={()=>setShowPassword(!showPassword)}>
                    {showPassword ? <BsEye  /> : <FaRegEyeSlash />}
                </button>
            </div>
        </div>
    )
}