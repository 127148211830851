import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {addWishlist, getFiltercode, getproductList, productActions,} from "../store/product";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {Helmet} from "react-helmet";
import Filters from "../components/General/Filters";
import {images} from "../utils/images";
import {mgtApi} from "../store/axios";
import Dropdown from "../components/General/DropDown";
import ThemeBreadcrumb from "../components/common/ThemeBreadcrumb";
import ProductContainer from "./ProductContainer";

const ProductListBySubCat = () => {
    const {pathname} = useLocation();
    const [showFullDescription, setShowFullDescription] = useState(false);
    const siteUrl = window.location.href;
    useEffect(() => {
        window.scrollTo({top: 0});
    }, [pathname]);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const {category, subCategory} = useParams();

    const filterUrl = window.location.pathname.split("product-category/").pop();
    const catAndSubCatSlugs = filterUrl.split("/");
    const {headerCategories} = useSelector((state) => state.home);
    const {
        products,
        fetchPermit,
        priceRange,
        keyword,
        filterQuery,
        filterList,
        total_page,
        categoryId,
        subCategoryId,
        sub_cate_meta,
    } = useSelector((state) => state.prodLi);
    const [subCate, setSubCate] = useState(null);

    const categorySlug = catAndSubCatSlugs[0];
    const [searchState, setSearchState] = useState(false);

    // const stringWithoutHyphens = subCategory.replace(/-/g, " ");
    // const stringWithoutHyphenstwo = category.replace(/-/g, " ");

    // useEffect(() => {
    //   if (sub_cate_meta) {
    //     const urls = sub_cate_meta?.url?.split("/");
    //     const currectUrl = urls[urls?.length - 2];
    //     if (!urls?.includes(category)) {
    //       if (currectUrl) {
    //         window.location.replace(`/${currectUrl}/${subCategory}`);
    //       }
    //     }
    //   }
    // }, [sub_cate_meta, category, subCategory]);

    useEffect(() => {
        const cateItem = headerCategories.filter(
            (item) => item?.categories?.slug === category
        );
        const subCategoryId = cateItem[0]?.categories.sub_categories.filter(item => item.sub_cate_slug === subCategory)[0].subcat_id;


        if (cateItem?.length > 0) {
            dispatch(getFiltercode({category_id: cateItem[0]?.categories?.cat_id, subcategory_id: subCategoryId}));
            setSubCate(subCategory[0]);
        }
    }, [category, subCategory, headerCategories]);

    const refetch = () => {
        dispatch(getproductList(subCategory));
    };

    useEffect(() => {
        if (sub_cate_meta?.description) {
            setShowFullDescription(sub_cate_meta.description.length < 500)
        }
    }, [sub_cate_meta]);

    useEffect(() => {
        setSearchState(false);
        setPage(2);
        setLoadMore(true);
        if (!fetchPermit) {
            dispatch(getproductList(subCategory));
        }
    }, [category, subCategory]);
    const state = useSelector((state) => state.prodLi);
    useEffect(() => {
        if (state.prodNotFound) {
            navigate("/page-not-found");
        }
    }, [categorySlug]);

    var userDetails = localStorage.getItem("userDetails");
    var data = JSON.parse(userDetails);
    var user_id = data?.ID;

    const addToWishList = (product_id, index) => {
        if (user_id) {
            dispatch(addWishlist(product_id));
            var heartIcon = document.getElementById("heartIcon-" + index);
            heartIcon.classList.toggle("selected");
            heartIcon.classList.toggle("hearticoncolor");
        } else {
            navigate("/login");
        }
    };

    // -------------------------------------------

    const handleOptionSelect = (option) => {
        setSelectedOption(option);
    };

    useEffect(() => {
        setPage(2);
        setLoadMore(true);
    }, [searchState]);

    const [smallScreen, setSmallScreen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);
    const [page, setPage] = useState(2);
    const [loadMore, setLoadMore] = useState(true);
    const [listStyle, setListStyle] = useState(false);

    async function loadMoreProducts() {
        dispatch(productActions.setFetchPermit(true));
        if (page > 1 && loadMore && fetchPermit) {
            if (page > total_page) {
                setLoadMore(false);
            } else {
                let userDetails = localStorage.getItem("userDetails");
                let userData = JSON.parse(userDetails);
                let user_id = userData?.ID;
                let res = null;
                if (searchState) {
                    const res = await mgtApi.post(
                        `/productsearch?price_between=${priceRange[0]},${
                            priceRange[1]
                        }&page=${page}${categoryId ? `&category_id=${categoryId}` : ''}${subCategoryId ? `&sub_category_id=${subCategoryId}` : ''}${filterQuery ? `&${filterQuery}` : ''}`
                    );
                    setLoadMore(res.data?.products.length > 0 ? true : false);
                    dispatch(productActions.setProducts(res.data?.products));
                } else {
                    res = await mgtApi.post("product-by-subcategory-web", {
                        page,
                        slug: subCategory,
                        user_id,
                    });
                    console.log("sub cate: ", res.data);
                    setLoadMore(res.data?.data.products?.length > 0 ? true : false);
                    dispatch(productActions.setProducts(res.data?.data?.products));
                }
                setPage(page + 1);
            }
        }
    }

    function removeTags(txt) {
        if (txt) {
            var rex = /(<([^>]+)>)/gi;
            return txt?.replace(rex, "");
        }
    }

    // console.log(sub_cate_meta);
    return (
        <>
            <Helmet>
                {sub_cate_meta?.schema && (
                    <script type="application/ld+json">{`${sub_cate_meta?.schema}`}</script>
                )}
                <meta charSet="utf-8"/>
                <link rel="canonical" href={siteUrl}/>
                <title>{sub_cate_meta?.meta_title}</title>
                <meta name="description" content={sub_cate_meta?.meta_description}/>
                <meta name="keywords" content={sub_cate_meta?.meta_keywords}/>

                <meta name="twitter:card" content="summary_large_image"/>
                <meta name="twitter:url" content={siteUrl}/>
                <meta name="twitter:title" content={sub_cate_meta?.meta_title}/>
                <meta
                    name="twitter:description"
                    content={removeTags(sub_cate_meta?.meta_description)}
                />
                <meta name="twitter:image" content={sub_cate_meta?.og_image}/>

                <meta property="og:type" content="website"/>
                <meta property="og:url" content={siteUrl}/>
                <meta property="og:title" content={sub_cate_meta?.meta_title}/>
                <meta
                    property="og:description"
                    content={removeTags(sub_cate_meta?.meta_description)}
                />
                <meta property="og:image" content={sub_cate_meta?.og_image}/>
            </Helmet>
            <main className="">
                <ThemeBreadcrumb
                    title={`${sub_cate_meta?.title}`}
                    current_route={sub_cate_meta?.title}
                />
                <section className="single-wrapper">
                    <div className="container">
                        {sub_cate_meta?.description && <>
                            <div className={`cate-description mt-2 ${showFullDescription ? '' : 'line-clamp'}`}
                                 dangerouslySetInnerHTML={{__html: sub_cate_meta.description}}></div>
                            {removeTags(sub_cate_meta.description).length > 500 &&
                                <button className='signbtn mt-3' style={{padding: '7px 20px'}}
                                        onClick={() => setShowFullDescription(!showFullDescription)}>{showFullDescription ? 'Read Less' : 'Read More'}</button>}
                        </>}
                        <div className="filter-head row">
                            {products.length > 0 ? (
                                <div className="col-lg-5 col-12">
                                    <div className="counter grey-text">
                                        Showing all {products.length} results
                                    </div>
                                </div>
                            ) : (
                                <span className="col-lg-5 col-12"></span>
                            )}
                            {products?.length !== 0 &&
                                <div className="col-lg-7 col-12 mt-4 mt-md-0">
                                    <div className="d-flex flex-wrap justify-content-end gap-4">
                                        <div className="view-style">
                                            <h3>Views : </h3>
                                            {listStyle ? (
                                                <button onClick={(e) => setListStyle(false)}>
                                                    <img src={images["grid.svg"]} alt=""/>
                                                </button>
                                            ) : (
                                                <button>
                                                    <img
                                                        src={images["list.svg"]}
                                                        alt=""
                                                        onClick={(e) => setListStyle(true)}
                                                    />
                                                </button>
                                            )}
                                        </div>
                                        <Dropdown className="d-none d-md-block"/>
                                        <button
                                            className="filter-btn"
                                            onClick={(e) => setSmallScreen(true)}
                                        >
                                            Filters
                                        </button>
                                    </div>
                                </div>}
                        </div>
                        {/* <!-- product  --> */}
                        <div className="row">
                            {products?.length !== 0 &&
                                <div
                                    className={`col-lg-3 col-md-4 ${
                                        smallScreen ? "resp-show" : "resp-hide"
                                    }`}
                                >
                                    <Filters
                                        options={filterList}
                                        setSmallScreen={setSmallScreen}
                                        setSearchState={setSearchState}
                                        setPage={setPage}
                                        setLoadMore={setLoadMore}
                                        refetch={refetch}
                                    />
                                </div>}
                            <ProductContainer
                                products={products}
                                loadMore={loadMore}
                                loadProducts={loadMoreProducts}
                                addToWishList={addToWishList}
                                isGrid={listStyle}
                                isLoading={state.loaderStatus}
                            />
                        </div>
                    </div>
                </section>
            </main>
        </>
    );
};

export default ProductListBySubCat;
