import React from "react";
import { Modal } from "react-bootstrap";
import ImageGallery from "react-image-gallery";

const ProductGallery = ({ index, close, images }) => {
  const renderItem = (item) => {
    return (
      <div
        className="d-flex justify-content-center align-items-center mx-auto"
        style={{ maxHeight: "80vh", maxWidth: "1200px", objectFit: "contain" }}
      >
        <img
          src={item?.original}
          style={{
            maxHeight: "100%",
            width: "100%",
            objectFit: "contain",
          }}
          alt=""
        />
      </div>
    );
  };

  return (
    <>
      <Modal
        show={index >= 0}
        onHide={() => close(-1)}
        fullscreen={true}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        style={{ backgroundColor: "black" }}
        className="py-0 m-0"
      >
        <Modal.Body className="py-0 m-0 bg-black">
          <div
            className="zoom_product arrow-parentInModal mx-auto d-flex justify-content-center align-items-center"
            style={{ maxWidth: "1800px", height: "100%", paddingTop: "20px" }}
          >
            <ImageGallery
              items={images}
              showFullscreenButton={false}
              showPlayButton={false}
              showNav={true}
              startIndex={index}
              renderItem={renderItem}
              thumbnailPosition="bottom"
              renderLeftNav={(onClick, disabled) => (
                <button
                  className={`arrow-l-btn image-gallery-left-nav p-0 ${
                    disabled ? "disabled" : ""
                  }`}
                  onClick={onClick}
                  disabled={disabled}
                >
                  {/* You can put your custom arrow icon here */}
                  <svg
                    width="40px"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="4"
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      stroke-linecap="none"
                      stroke-linejoin="none"
                      d="M15.75 19.5L8.25 12l7.5-7.5"
                    />
                  </svg>
                </button>
              )}
              renderRightNav={(onClick, disabled) => (
                <button
                  className={`arrow-r-btn image-gallery-right-nav p-0
                             ${disabled ? "disabled" : ""}`}
                  onClick={onClick}
                  disabled={disabled}
                >
                  {/* You can put your custom arrow icon here */}
                  <svg
                    width="40px"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="4"
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      stroke-linecap="none"
                      stroke-linejoin="none"
                      d="M8.25 4.5l7.5 7.5-7.5 7.5"
                    />
                  </svg>
                </button>
              )}
            />
          </div>
        </Modal.Body>

        <svg
          onClick={() => close(-1)}
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="4"
          stroke="currentColor"
          className="position-absolute text-white pointer"
          style={{ width: "40px", top: "20px", right: "20px" }}
        >
          <path
            stroke-linecap="none"
            stroke-linejoin="none"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </Modal>
    </>
  );
};

export default ProductGallery;
