import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import '../styles/bottomPopup.css';
import {FaXmark} from "react-icons/fa6";
import {mgtApi} from "../../../store/axios";

export default function BottomPopup({products}) {
    const [popupProduct, setPopupProduct] = useState(null);
    const [showPopup, setShowPopup] = useState(false);
    const [state, setState] = useState(null);

    useEffect(() => {
        mgtApi.post("/states-list", {country_id: 9})
            .then(res => {
                // Start the first popup after 10 seconds
                setTimeout(() => {
                    showRandomPopup(res.data.data);
                }, 10000);
            });
    }, []);

    useEffect(() => {
        let timeoutId = setTimeout(() => {
            setShowPopup(false);
        }, 6000);
        return () => clearTimeout(timeoutId);
    }, [popupProduct]);

    const showRandomPopup = (statesList) => {
        const randomProduct = products[Math.floor(Math.random() * products.length)];
        setShowPopup(true);
        setState(statesList[Math.floor(Math.random() * statesList.length)]);
        setPopupProduct(randomProduct);

        // Start intervals for subsequent popups every 45 seconds
        const intervalId = setInterval(() => {
            const randomProduct = products[Math.floor(Math.random() * products.length)];
            setShowPopup(true);
            setState(statesList[Math.floor(Math.random() * statesList.length)]);
            setPopupProduct(randomProduct);
        }, 45000);

        return () => clearInterval(intervalId);
    };

    if (!popupProduct) return null;

    const {main_image, pro_name, id, pro_slug} = popupProduct;

    return (
        <div className={`d-flex popup-card flex-row ${showPopup ? 'active' : ''}`}>
            <img src={main_image} alt=''/>
            <div className='p-2 d-flex justify-content-between flex-column ls_content-resp'>
                <p>Someone from <strong>{state.state_name}</strong> just purchased </p>
                <Link to={`/shop/${pro_slug}-${id}`}>{pro_name.length > 18
                    ? pro_name.slice(0, 18) + "..."
                    : pro_name}</Link>
            </div>
            <button onClick={() => setShowPopup(false)}>
                <FaXmark className='gen-icon'/>
            </button>
        </div>
    );
}