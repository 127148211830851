import {mgtApi} from "../store/axios";
import {updateAddressFor} from "../store/home";
import {useDispatch} from "react-redux";

// Helper function fetches the publishable key
// form the server.
export const handleFetchPublishableKey = async () => {
    try {
        const response = await mgtApi.get("/bouncy-bubble");
        if (!response.status === "200") {
            throw new Error("Error fetching bouncy key.");
        }

        const {bouncy_key: publishableKey} = response?.data?.data;

        return publishableKey;
    } catch (error) {
        console.error(error);
        return {error: error.message};
    }
};

// Helper function handles creating a payment intent on the server
export const handleCreatePaymentIntent = async (checkout_amount) => {
    try {
        const response = await mgtApi.post("/stripe-payment-intent", {
            checkout_amount: checkout_amount,
        });
        const {client_secret, slogan_address} = response?.data;

        return {slogan_address, client_secret};
    } catch (error) {
        console.error(error);
        return {error: error.message};
    }
};

// Helper function handles updating a payment intent and properties, Such as the checkout amount to
// to charge.
export const handleUpdatePaymentIntent = async (
    payment_intent_id,
    checkout_amount
) => {
    try {
        const response = await mgtApi.post("/stripe-update-payment-intent", {
            payment_intent_id,
            checkout_amount,
        });

        const {payment_intent_status} = response?.data;
        return payment_intent_status;
    } catch (error) {
        console.error(error);
        return {error: error.message};
    }
};

// Helper function that handles passing shipping address
export const handleFinalizeOrder = async (
    payment_intent_id,
    shipping_address,
    location,
    guestAddress
) => {
    try {
        let response;
        if (location !== '/guest-checkout') {
            await mgtApi.post("/stripe-transaction", {
                stripe_payment_intent: payment_intent_id,
                shipping_address,
            });
            mgtApi.post("/update-address-type", {address_for:"shipping", id:Number(localStorage.getItem('defaultAddress'))})
            localStorage.removeItem('defaultAddress')
        } else {
            await mgtApi.post("/guest-stripe-transaction", guestAddress);
        }
        return response;
    } catch (error) {
        console.error(error);
        return {error: error.message};
    }
};
