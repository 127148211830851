import React from "react";
import { Link } from "react-router-dom";
import author from "../../assets/brand/mangtum.png";
import moment from "moment";

const BlogCard = ({ blog }) => {
  return (
    <div className="col-md-6 col-lg-4 mt-5 blog_card">
      <Link to={`/blog/${blog?.slug}`}>
        <div
          className="position-relative blog_card_image_container"
          style={{ maxHeight: "281px" }}
        >
          <img
            className="w-100"
            style={{ height: "281px", objectFit: "cover" }}
            src={blog?.coverimage}
            alt=""
          />

          <Link
            to={`/blog?category=${blog?.cate_slug}`}
            className="blog_image_overlay_container"
          >
            <span className="blog_image_overlay_text font-theme">
              {blog?.cate_name}
            </span>
          </Link>
        </div>
      </Link>
      <div className="d-flex flex-column gap-2 mt-3">
        <Link to={`/blog/${blog?.slug}`}>
          <h2 className="blog_card_title">{blog?.title}</h2>
        </Link>
        <p
          className="blog_card_content treeLine"
          dangerouslySetInnerHTML={{
            __html:
              blog?.content?.length > 250
                ? blog?.content.slice(0, 250) + "..."
                : blog?.content,
          }}
        ></p>
        <div
          className="d-flex align-items-center gap-2"
          style={{ marginTop: "15px" }}
        >
          <img
            className="rounded-circle"
            style={{ width: "30px", height: "30px" }}
            src={author}
            alt=""
          />
          <p className="blog_author_text">
            Mangtum - {moment(blog?.created_at).format("MMM DD, YYYY")}
          </p>
        </div>
      </div>
    </div>
  );
};

export default BlogCard;
