import React, {useEffect, useState} from "react";
import {Link, useParams} from "react-router-dom";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import {images} from "../../utils/images";
import {getFilterProducts, productActions} from "../../store/product";
import {useDispatch, useSelector} from "react-redux";
import {nameToSlug, slugToName} from "../../utils/helper";
import FilterOption from "./FilterOption";
import Dropdown from "./DropDown";
import {FaCheck} from "react-icons/fa";

const Filters = ({
                     options,
                     setSmallScreen,
                     setSearchState,
                     setPage,
                     setLoadMore,
                     refetch,
                 }) => {
    const dispatch = useDispatch();
    const {category} = useParams();
    const {
        filters = [],
        categoryWithSubCategory = {},
        parent_categories = [],
    } = options;
    const {
        filterQuery,
        categoryId,
        subCategoryId,
        keyword,
        minMaxInputPrice,
        priceRange,
        filterArray,
        storeProducts,
        store_total_page,
    } = useSelector((state) => state.prodLi);

    const {subCategory} = useParams();

    const [hasMounted, setHasMounted] = useState(false);
    const handleClose = () => {
        const screenWidth = window.innerWidth;
        if (screenWidth <= 768) {
            setSmallScreen(false);
        }
    };

    const handleSliderChange = (newValues) => {
        const integerValues = [Math.ceil(newValues[0]), Math.ceil(newValues[1])];
        dispatch(productActions.setPriceRange(integerValues));
    };

    const handleMinInputChange = (e) => {
        dispatch(productActions.setPriceRange([parseFloat(e.target.value), priceRange[1]]));
    };

    const handleMaxInputChange = (e) => {
        dispatch(productActions.setPriceRange([priceRange[0], parseFloat(e.target.value)]));
    };

    const priceSet = (e) => {
        window.scrollTo(0, 0);
        const mutablePrices = [...priceRange]
        const arrangedPrices = mutablePrices.sort((a, b) => a - b);
        dispatch(productActions.setPriceRange(arrangedPrices));
        const requestBody = {}
        dispatch(getFilterProducts(keyword,
            ...arrangedPrices,
            categoryId,
            subCategory && subCategoryId,
            filterQuery));
        setPage(2);
        setSearchState(true);
        handleClose();
    };

    const [openReplyMenuIndex, setOpenReplyMenuIndex] = useState([]);
    const handleReplyClick = (index) => {
        if (openReplyMenuIndex.includes(index)) {
            setOpenReplyMenuIndex(
                openReplyMenuIndex.filter((item) => item !== index)
            );
        } else {
            setOpenReplyMenuIndex([...openReplyMenuIndex, index]);
        }
    };

    const handleClearFetch = () => {
        dispatch(productActions.setFetchPermit(false));
        dispatch(productActions.setFilterQuery(""));
        dispatch(productActions.setSearchParams({}));
    };

    const handleSliderChangeEnd = (newValues) => {
        const integerValues = [newValues[0], newValues[1]];
        window.scrollTo(0, 0);
        dispatch(
            getFilterProducts(
                keyword,
                ...integerValues,
                categoryId,
                subCategory && subCategoryId,
                filterQuery
            )
        );
        setPage(2);
        setSearchState(true);
        handleClose();
    };

    useEffect(() => {
        if (hasMounted) {
            window.scrollTo(0, 0);
            if (!filterQuery && filterArray < 1) {
                dispatch(productActions.setTotal_page(store_total_page));
                dispatch(productActions.setStoreProductsToMainProducts());
                setLoadMore(true);
                handleClose();
                return () => {
                };
            }
            dispatch(
                getFilterProducts(
                    keyword,
                    priceRange[0],
                    priceRange[1],
                    categoryId,
                    subCategoryId,
                    filterQuery
                )
            );
            setPage(2);
            setSearchState(true);
            handleClose();
        } else {
            setHasMounted(true);
        }
    }, [filterQuery]);

    useEffect(() => {
        const handleResize = () => {
            const screenWidth = window.innerWidth;
            if (screenWidth >= 768) {
                setSmallScreen(false);
            }
        };
        handleResize();
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const handleReset = () => {
        dispatch(productActions.setKeyword(""));
        dispatch(productActions.setMinMaxInputPrice(minMaxInputPrice));
        dispatch(productActions.setPriceRange(minMaxInputPrice));
        dispatch(productActions.setFilterQuery(""));
        dispatch(productActions.setFilterArray([]));
        refetch();
        handleClose();
    };

    return (
        <div className="side-filter">
            <p className="d-flex justify-content-end mb-2">
                <button className="svg-icon" onClick={(e) => setSmallScreen(false)}>
                    <img src={images["x.svg"]} alt=""/>
                </button>
            </p>
            <div>
                <Dropdown
                    className="d-md-none mb-2 mb-md-0"
                    setSmallScreen={setSmallScreen}
                />

                <div className="d-flex justify-content-between align-items-center">
                    <div className="filterCategoriesPrice mt-2 mb-2">Price</div>
                    <button onClick={() => handleReset()} className="filter_reset_btn">
                        Reset
                    </button>
                </div>
                <div className="price-inps">
                    <div>
                        <label className="filterSub_categoryPrice">From $</label>
                        <input
                            type="number"
                            value={priceRange[0]}
                            min='1'
                            onChange={handleMinInputChange}
                        />
                    </div>
                    <div>
                        <label className="filterSub_categoryPrice">To $</label>
                        <input
                            type="number"
                            value={priceRange[1]}
                            min='1'
                            onChange={handleMaxInputChange}
                        />
                    </div>
                    <button className='icon-button filter_reset_btn' type='button' onClick={(e) => priceSet(e)}>
                        <FaCheck/>
                    </button>
                </div>
                <div className={`slider-container`}>
                    <Slider
                        min={parseFloat(minMaxInputPrice[0])}
                        max={parseFloat(minMaxInputPrice[1])}
                        range
                        value={priceRange}
                        allowCross={false}
                        onChange={handleSliderChange}
                        onAfterChange={handleSliderChangeEnd}
                        dotStyle={{
                            backgroundColor: "#f6a92c",
                            borderColor: "#f6a92c",
                            width: 24,
                            height: 24,
                        }}
                    />
                </div>

                <div className="side-flex">
                    <div className="filterCategories">Categories</div>
                </div>
                {parent_categories?.length > 0 && (
                    <ul onClick={() => handleClearFetch()}>
                        <li>
                            <Link
                                to={`/${nameToSlug(categoryWithSubCategory?.cate_name)}`}
                                className="main-link"
                            >
                               <span
                                   className={`filterCategoriesPrice`}>
                                  {categoryWithSubCategory?.cate_name}
                                </span>
                            </Link>
                        </li>
                        <ul>
                            {categoryWithSubCategory?.subcategories?.map(
                                (subCategoryItem, idx) => {
                                    return (
                                        <li key={idx}>
                                            <Link
                                                to={`/${nameToSlug(
                                                    categoryWithSubCategory.cate_name
                                                )}/${nameToSlug(subCategoryItem.subcate_name)}`}
                                            >
                                                <span
                                                    className={`filterSub_category ${slugToName(subCategory)?.toLowerCase() === subCategoryItem.subcate_name?.toLowerCase() ? 'active-subCategory' : ''}`}>
                                                  {subCategoryItem.subcate_name}
                                                </span>
                                            </Link>
                                        </li>
                                    );
                                }
                            )}
                        </ul>

                        {parent_categories?.map((item, index) => {
                            if (categoryWithSubCategory?.cate_name === item.cate_name) {
                                return;
                            }
                            return (
                                <li key={index}>
                                    <Link to={`/${item.slug}`}>
                                        <span className="filterSub_category">{item.cate_name}</span>
                                    </Link>
                                </li>
                            );
                        })}
                    </ul>
                )}
            </div>

            {options?.filters?.length > 0 && (
                <div>
                    <div className="filterCategoriesPrice">Filters</div>
                    <ul className="nested-filter">
                        {filters?.map((item, index) => (
                            <li key={index} className="title-li">
                <span
                    className="att-title filterSub_category"
                    onClick={(e) => handleReplyClick(index)}
                >
                  {item.attr_name}
                </span>
                                <i
                                    className={`fa fa-chevron-down ${
                                        openReplyMenuIndex.includes(index) ? "active" : ""
                                    }`}
                                    style={{cursor: "pointer", fontSize: "10px"}}
                                    onClick={(e) => handleReplyClick(index)}
                                ></i>
                                {openReplyMenuIndex.includes(index) && (
                                    <ul>
                                        {item.options.length > 0 &&
                                            item.options?.map((optionItem, optionIndex) => (
                                                <FilterOption
                                                    item={item}
                                                    optionIndex={optionIndex}
                                                    optionItem={optionItem}
                                                />
                                            ))}
                                    </ul>
                                )}
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
};

export default Filters;
