import Swal from 'sweetalert2';

export const showConfirmation = (handleDelete, dialogText) => {
    Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#f6a92c',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
        if (result.isConfirmed) {
            handleDelete().then(() => Swal.fire({
                title: 'Deleted!',
                text: dialogText || 'Your file has been deleted.',
                icon: 'success',
                confirmButtonColor: '#3085d6',
            }));
        }
    });
};