import "../App.css";

import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {addWishlist, productActions} from "../store/product";
import SiteLoader from "../SiteLoader";
import {images} from "../utils/images";
import InfiniteScroll from "react-infinite-scroller";
import {Circles} from "react-loader-spinner";
import {mgtApi} from "../store/axios";
import Filters from "../components/General/Filters";
import {useLocation, useNavigate, useSearchParams} from "react-router-dom";
import {getSearch_products} from "../store/home";
import {Link} from "react-router-dom";
import {fixChar} from "../utils/helper";
import Dropdown from "../components/General/DropDown";
import ThemeBreadcrumb from "../components/common/ThemeBreadcrumb";
import ProductContainer from "./ProductContainer";

const ProductSearch = () => {
    let [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const {
        products,
        fetchPermit,
        priceRange,
        keyword,
        filterQuery,
        total_page,
        loaderStatus,
    } = useSelector((state) => state.prodLi);
    const user = localStorage.getItem("userDetails");
    const {filters, search_array} = useSelector((state) => state.home);

    const dispatch = useDispatch();
    const [listStyle, setListStyle] = useState(false);
    const st = useSelector((state) => state.home);

    const addToWishList = (product_id, index) => {
        if (user) {
            dispatch(addWishlist(product_id));

            var heartIcon = document.getElementById("heartIcon-" + index);
            heartIcon.classList.toggle("selected");
            heartIcon.classList.toggle("hearticoncolor");
        } else {
            navigate("/login");
        }
    };
    // -------------------------------------------------------

    const [searchState, setSearchState] = useState(false);

    const [page, setPage] = useState(2);
    const [loadMore, setLoadMore] = useState(true);

    useEffect(() => {
        setPage(2);
        setLoadMore(true);
    }, [searchState]);

    const handleOptionSelect = (option) => {
        setSelectedOption(option);
    };

    const refetch = () => {
        dispatch(getSearch_products(searchParams.get("search"), 1));
    };

    useEffect(() => {
        setSearchState(false);
        setPage(2);
        setLoadMore(true);
        if (!fetchPermit) {
            dispatch(getSearch_products(searchParams.get("search"), 1));
        }
    }, []);

    const [selectedOption, setSelectedOption] = useState(null);
    const [smallScreen, setSmallScreen] = useState(false);

    useEffect(() => {
        setPage(2);
        setLoadMore(true);
    }, [search_array]);

    async function loadMoreProducts() {
        dispatch(productActions.setFetchPermit(true));
        if (page > 1 && loadMore && fetchPermit) {
            if (page > total_page) {
                setLoadMore(false);
            } else {
                const res = await mgtApi.post(
                    `/productsearch?${keyword && `keyword=${keyword}`}&price_between=${
                        priceRange[0]
                    },${priceRange[1]}&page=${page}${filterQuery && `&${filterQuery}`}`
                );
                console.log("search initially");
                setLoadMore(res.data?.products.length > 0 ? true : false);
                dispatch(productActions.setProducts(res.data?.products));
                setPage(page + 1);
            }
        }
    }

    return (
        <>
            {st.loaderStatus ? (
                <SiteLoader status={st.loaderStatus}/>
            ) : (
                <>
                    <div>
                        <main>
                            <ThemeBreadcrumb
                                title={`Search Product`}
                                current_route="Search Product"
                            />

                            <div>
                                <section className="single-wrapper">
                                    <div className="container set-margin-btm">
                                        <div className="filter-head row">
                                            {products.length > 0 ? (
                                                <div className="col-lg-5 col-12">
                                                    <div className="counter grey-text">
                                                        Showing all {products.length} results{" "}
                                                    </div>
                                                </div>
                                            ) : (
                                                <span className="col-lg-5 col-12"></span>
                                            )}
                                            {!!products.length && <div className="col-lg-7 col-12 mt-4 mt-md-0">
                                                <div className="d-flex flex-wrap justify-content-end gap-4">
                                                    <div className="view-style">
                                                        <h3>Views : </h3>
                                                        {listStyle ? (
                                                            <button onClick={(e) => setListStyle(false)}>
                                                                <img src={images["grid.svg"]} alt=""/>
                                                            </button>
                                                        ) : (
                                                            <button>
                                                                <img
                                                                    src={images["list.svg"]}
                                                                    alt=""
                                                                    onClick={(e) => setListStyle(true)}
                                                                />
                                                            </button>
                                                        )}
                                                    </div>
                                                    <Dropdown className="d-none d-md-block"/>
                                                    <button
                                                        className="filter-btn"
                                                        onClick={(e) => setSmallScreen(true)}
                                                    >
                                                        Filters
                                                    </button>
                                                </div>
                                            </div>}
                                        </div>
                                        <div className="row">
                                            {!!products.length && <div
                                                className={`col-lg-3 col-md-4 ${
                                                    smallScreen ? "resp-show" : "resp-hide"
                                                }`}
                                            >
                                                <Filters
                                                    options={filters}
                                                    setSmallScreen={setSmallScreen}
                                                    setSearchState={setSearchState}
                                                    setPage={setPage}
                                                    setLoadMore={setLoadMore}
                                                    refetch={refetch}
                                                />
                                            </div>}
                                            <ProductContainer
                                                products={products}
                                                loadMore={loadMore}
                                                loadProducts={loadMoreProducts}
                                                addToWishList={addToWishList}
                                                isGrid={listStyle}
                                                isLoading={loaderStatus}
                                            />
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </main>
                    </div>
                </>
            )}
        </>
    );
};

export default ProductSearch;
