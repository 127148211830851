import React, {useEffect} from "react";
import "./seller.css";
import {Helmet} from "react-helmet";
import {useDispatch, useSelector} from "react-redux";
import {get_meta} from "../../store/home";
import {getStoreData} from "../../store/pages";
import SiteLoader from "../../SiteLoader";

const SellerPage = () => {
    const {sellerStoreData} = useSelector((state) => state.pages);
    const {loaderStatus} = useSelector((state) => state.prodLi);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getStoreData());
    }, []);

    // META TAGS START
    useEffect(() => {
        dispatch(get_meta("open-a-store"));
    }, ["open-a-store"]);
    const state = useSelector((state) => state.home);
    const metaTags = state.allmeta;
    const siteUrl = window.location.href;
    // META TAGS END

    return (
        <>
            {loaderStatus ? (
                <SiteLoader status={loaderStatus}/>
            ) : (
                <>
                    <Helmet>
                        <meta charSet="utf-8"/>
                        <title>{metaTags?.meta_data?.meta_title}</title>
                        <meta
                            name="description"
                            content={metaTags?.meta_data?.meta_description}
                        />
                        <meta
                            name="keywords"
                            content={metaTags?.meta_data?.meta_keywords}
                        />
                        <meta name="twitter:card" content="summary_large_image"/>
                        <meta name="twitter:url" content={siteUrl}/>

                        <meta
                            name="twitter:title"
                            content={metaTags?.meta_data?.og_title}
                        />
                        <meta
                            name="twitter:description"
                            content={metaTags?.meta_data?.og_description}
                        />
                        <meta
                            name="twitter:image"
                            content={metaTags?.meta_data?.og_image}
                        />

                        <meta property="og:type" content={metaTags?.meta_data?.og_type}/>
                        <meta property="og:url" content={siteUrl}/>
                        <meta property="og:title" content={metaTags?.meta_data?.og_title}/>
                        <meta
                            property="og:description"
                            content={metaTags?.meta_data?.og_description}
                        />
                        <meta property="og:image" content={metaTags?.meta_data?.og_image}/>
                    </Helmet>
                    <div
                        className="seller_banner_container w-100 mt-4"
                        style={{
                            backgroundImage: `url(${process.env.REACT_APP_FILE_ACCESS_URL}/public/images/${sellerStoreData?.image})`,
                        }}
                    >
                        <div className="seller_banner_overlay">
                            <div
                                className="d-flex flex-column gap-3 justify-content-center align-items-center mx-auto"
                                style={{maxWidth: "500px"}}
                            >
                                <p className="seller_banner_sub_title">
                                    {sellerStoreData?.store_title}
                                </p>
                                <h1 className="seller_banner_title">
                                    {sellerStoreData?.heading}
                                </h1>
                                <p className="seller_banner_content">
                                    {sellerStoreData?.store_description}
                                </p>
                                <a href={sellerStoreData?.btn_url}>
                                    <button className="btn bg-theme text-white">
                                        {sellerStoreData?.btn_text}
                                    </button>
                                </a>
                            </div>
                        </div>
                    </div>
                    <main className="container single-product-section">
                        <section>
                            <div
                                className="overflow-hidden"
                                dangerouslySetInnerHTML={{__html: sellerStoreData?.content}}
                            />
                        </section>

                        <section className="mt-5">
                            <div className="d-flex flex-column justify-content-center align-items-center gap-1 pt-4">
                                <h2 className="why_sell_on_mangtum">
                                    {sellerStoreData?.title}
                                </h2>
                                <p className="why-choose-us-text">
                                    {sellerStoreData?.selling_heading}
                                </p>
                            </div>

                            <div className="row mt-5 mx-auto" style={{maxWidth: "1200px"}}>
                                <div className="col-md-6 mt-5 d-flex align-items-center gap-4 how_to_sell_card">
                                    <div>
                                        <img
                                            src={sellerStoreData?.step1_image}
                                            loading="lazy"
                                            alt=""
                                        />
                                    </div>
                                    <div className="" style={{maxWidth: "400px"}}>
                                        {" "}
                                        <h2>{sellerStoreData?.step1_title}</h2>
                                        <p>{sellerStoreData?.step1_description}</p>
                                    </div>
                                </div>
                                <div className="col-md-6 mt-5 d-flex align-items-center gap-4 how_to_sell_card">
                                    <div>
                                        <img
                                            src={sellerStoreData?.step2_image}
                                            loading="lazy"
                                            alt=""
                                        />
                                    </div>
                                    <div className="" style={{maxWidth: "400px"}}>
                                        {" "}
                                        <h2>{sellerStoreData?.step2_title}</h2>
                                        <p>{sellerStoreData?.step2_description}</p>
                                    </div>
                                </div>
                                <div className="col-md-6 mt-5 d-flex align-items-center gap-4 how_to_sell_card">
                                    <div>
                                        <img
                                            src={sellerStoreData?.step3_image}
                                            loading="lazy"
                                            alt=""
                                        />
                                    </div>
                                    <div className="" style={{maxWidth: "400px"}}>
                                        {" "}
                                        <h2>{sellerStoreData?.step3_title}</h2>
                                        <p>{sellerStoreData?.step3_description}</p>
                                    </div>
                                </div>
                                <div className="col-md-6 mt-5 d-flex align-items-center gap-4 how_to_sell_card">
                                    <div>
                                        <img
                                            src={sellerStoreData?.step4_image}
                                            loading="lazy"
                                            alt=""
                                        />
                                    </div>
                                    <div className="" style={{maxWidth: "400px"}}>
                                        {" "}
                                        <h2>{sellerStoreData?.step4_title}</h2>
                                        <p>{sellerStoreData?.step4_description}</p>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </main>
                </>
            )}
        </>
    );
};

export default SellerPage;
